import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Patient from './Patient/index'
import Visit from './Visit/index'
import Loader from './Loader/index'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Patient,
    Visit,
    Loader
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug
})
