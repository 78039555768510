<template>
  <b-col cols="12" class="referral">
    <h6>
      {{ data.request.title }}
      {{ data.date | formatDate }}
    </h6>
    <span> {{ $t('visit.code') }}: {{ data.code }} </span>
    <div>
      <span class="font-weight-bold">{{ $t('visit.mainRecognition') }}:</span>
      <span>
        {{ data.request.main_recognitions.ICD10Item.code }}
        {{ data.request.main_recognitions.ICD10Item.text }}</span
      >
    </div>
    <div>
      <span class="font-weight-bold"
        >{{ $t('visit.additionalRecognition') }}:</span
      >
      <b-col
        cols="12"
        v-for="(item, i) in data.request.additional_recognitions.items"
        :key="`med${i}`"
      >
        <span>{{ item.code }} {{ item.text }}</span>
      </b-col>
    </div>
    <div>
      <span class="font-weight-bold"
        >{{ $t('visit.referralDescription') }}:</span
      >
      <b-col
        cols="12"
        v-for="(description, i) in data.request.paragraphs.items"
        :key="`description-${i}`"
      >
        <h6>{{ description.title }}</h6>
        <span>{{ description.content }}</span>
      </b-col>
    </div>
  </b-col>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.referral {
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
}
</style>
