<template>
  <div>
    <Single
      v-for="el in getAllCheckups"
      :key="el.id"
      category="checkUp"
      :class="`checkUp-${el.id}`"
      :data="el"
    />
  </div>
</template>
<script>
import Single from './Single'
import { mapGetters } from 'vuex'
export default {
  components: {
    Single
  },
  computed: {
    ...mapGetters('Patient', ['getAllCheckups'])
  }
}
</script>
