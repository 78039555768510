<template>
  <b-container>
    <b-row>
      <b-col cols="1">
        Data:
      </b-col>
      <b-col cols="3"><b-form-datepicker placeholder="Data od"></b-form-datepicker></b-col><b-col
        cols="3"><b-form-datepicker placeholder="Data do" /></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="ID zestawienia" label-for="id-zestawienia">
          <b-form-input id="id-zestawienia" placeholder="ID zestawienia" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Personel" label-for="personel">
          <b-form-input id="personel" placeholder="Personel" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Pacjent" label-for="pacjent">
          <b-form-input id="pacjent" placeholder="Pacjent" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Kod błędu" label-for="kod">
          <b-form-input id="kod" placeholder="Kod" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="ID poz. roz." label-for="id-poz-roz">
          <b-form-input id="id-poz-roz" placeholder="" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Nazwa błędu" label-for="nazwa bledu">
          <b-form-input id="nazwa-bledu" placeholder="Nazwa błędu" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Produkt kontraktowy">
          <b-form-select placeholder=""></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Produkt jednostkowy">
          <b-form-select placeholder=""></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h6>Świadczenia</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table :fields="fields" :items="items"></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'date', label: 'Data' },
        { key: 'kod_bledu', label: 'Kod błędu' },
        { key: 'nazwa_bledu', label: 'Nazwa błędu' }
      ],
      items: [
        { id: 1, date: '2020-01-01', kod_bledu: 'Kod1', nazwa_bledu: 'Nazwa1' },
        { id: 2, date: '2020-01-02', kod_bledu: 'Kod2', nazwa_bledu: 'Nazwa2' },
        { id: 3, date: '2020-01-03', kod_bledu: 'Kod3', nazwa_bledu: 'Nazwa3' }
      ]
    }
  }
}
</script>
