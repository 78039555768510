import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faBars,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCommentDots,
  faEdit,
  faPhoneSquareAlt,
  faSearchPlus,
  faUserCog,
  faCalendarPlus,
  faStethoscope,
  faHome,
  faUserPlus,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faClipboardList,
  faPrescriptionBottle,
  faInbox,
  faPlus,
  faTrash,
  faSave,
  faExchangeAlt,
  faAlignCenter,
  faHospital,
  faUserMd,
  faFilePrescription,
  faMicrophone,
  faWindowMinimize,
  faRedo
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faMicrophone)
library.add(faBars)
library.add(faEdit)
library.add(faUserCog)
library.add(faCommentDots)
library.add(faPhoneSquareAlt)
library.add(faSearchPlus)
library.add(faCheck)
library.add(faArrowRight)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faArrowLeft)
library.add(faAlignLeft)
library.add(faAlignRight)
library.add(faAlignJustify)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faCalendarPlus)
library.add(faStethoscope)
library.add(faHome)
library.add(faUserPlus)
library.add(faAngleDoubleLeft)
library.add(faAngleDoubleRight)
library.add(faClipboardList)
library.add(faPrescriptionBottle)
library.add(faInbox)
library.add(faSave)
library.add(faExchangeAlt)
library.add(faAlignCenter)
library.add(faHospital)
library.add(faPlus)
library.add(faTrash)
library.add(faUserMd)
library.add(faRedo)
library.add(faWindowMinimize)
library.add(faFilePrescription)

Vue.component('font-awesome-icon', FontAwesomeIcon)
