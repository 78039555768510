<template>
  <b-row align-v="center" style="height: 100%">
    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="8">
          <b-input
            placeholder="Wpisz nazwisko lub PESEL"
            :value="filters.search"
            v-debounce:1000ms="(val, e) => search(val)"
          ></b-input>
        </b-col>
        <b-col md="2">
          <b-button
            @click="openModal()"
            title="Dodaj pacjenta"
            variant="outline"
            ><font-awesome-icon icon="user-plus"
          /></b-button>
        </b-col>
      </b-row>
    </b-col>
    <modal
      modal-id="add-modal"
      @submit="savePatient($event)"
      @visit="savePatientAndStartVisit($event)"
      @visit-date="savePatientAndPlanVisit($event)"
    />
  </b-row>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import patientService from '@/services/patients'
import Modal from './Modal'
import moment from 'moment'
import visitService from '@/services/visits'

export default {
  components: {
    Modal
  },
  computed: {
    ...mapState('Patient', {
      filters: (state) => state.filters
    })
  },
  data() {
    return {
      patientId: null,
      patient: {
        sex: 'M',
        nfz: 1
      }
    }
  },
  methods: {
    ...mapActions('Patient', [
      'fetchPatients',
      'setFilters',
      'setPlannedPatientId'
    ]),
    search(value) {
      this.setFiltersState('search', value)
      this.fetchPatients()
    },
    setFiltersState(prop, value) {
      this.setFilters({ prop, value })
    },
    openModal() {
      this.$bvModal.show('add-modal')
    },
    async savePatient(patient) {
      try {
        const { data } = await patientService.savePatient(patient)
        this.$swal(
          'Dodanie użytkownika',
          'Dodano pomyślnie użytkownika',
          'success'
        )
        this.patient = {
          sex: 'M',
          nfz: 1
        }
        return data.patientId
      } catch (e) {
        this.$swal(
          'Dodanie użytkownika',
          'Błąd przy dodaniu użytkownika',
          'error'
        )
      }
    },
    async savePatientAndStartVisit(patient) {
      const patientId = await this.savePatient(patient)
      await this.startVisit(patientId)
    },
    async savePatientAndPlanVisit(patient) {
      const patientId = await this.savePatient(patient)
      this.setPlannedPatientId(patientId)
      this.$bvModal.show('calendar-modal')
    },
    async startVisit(patientId) {
      const start = moment().format('YYYY-MM-DD HH:mm')
      const end = moment()
        .add(30, 'minutes')
        .format('YYYY-MM-DD HH:mm')
      const { data } = await visitService.sendNextDayVisit({
        patientId,
        start,
        end
      })
      this.$router.push(`/app/pacjenci/${patientId}/wizyta/${data.visitId}`)
    }
  }
}
</script>
<style scoped>
.btn:hover {
  background: #089bab;
}
</style>
