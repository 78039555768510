<template>
  <div>
    <b-button
      @click="handleAddNewProcedure()"
      variant="primary"
      v-if="parsedProcedures.length === 0"
    >
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </b-button>
    <b-row
      v-else
      v-for="(procedure, i) in parsedProcedures"
      :key="'procedure' + i"
    >
      <b-col cols="9">
        <complete-select
          @input="handleInputProcedure"
          @select="handleSelectProcedure(i, $event)"
          :data="options"
          id="icd9"
          :init-value="`${procedure.code} ${procedure.name}`"
          placeholder="Please type ICD9 code or name"
        ></complete-select>
      </b-col>
      <b-col cols="3"
        ><b-button @click="handleAddNewProcedure()" variant="primary">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
        <b-button @click="handleRemoveProcedure(i)" variant="danger">
          <font-awesome-icon icon="trash"></font-awesome-icon> </b-button
      ></b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import lookups from '../../../services/lookups'
import CompleteSelect from '../../../components/CompleteSelect.vue'
import moment from 'moment'

export default {
  components: {
    CompleteSelect
  },
  computed: {
    ...mapState('Visit', {
      procedures: (state) => state.currentVisit.procedures
    }),
    parsedProcedures() {
      return this.procedures ? JSON.parse(this.procedures) : []
    },
    options() {
      return this.icd9.map(el => ({
        label: `${el.code} ${el.name}`,
        value: el,
        $isDisabled: el.code.includes('–')
      }))
    }
  },
  data() {
    return {
      input: '',
      icd9: []
    }
  },
  methods: {
    ...mapActions('Visit', ['setVisitProperty', 'updateVisit']),
    setProcedure(el) {
      this.setVisitProperty({
        key: 'procedures',
        value: JSON.stringify([...this.parsedProcedures, el])
      })
      this.updateVisit()
    },
    handleInputProcedure(value) {
      this.input = value
      this.getIcd9(value)
    },
    handleAddNewProcedure() {
      const currentProcedures = this.parsedProcedures
      this.setVisitProperty({
        key: 'procedures',
        value: JSON.stringify([
          ...currentProcedures,
          {
            code: '',
            name: '',
            date: ''
          }
        ])
      })
    },
    async getIcd9(search) {
      try {
        const { data } = await lookups.getLookup('icd9', search)
        this.icd9 = data.map((el) => ({
          name: el.nazwa,
          code: el.kod.toString()
        }))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
      }
    },
    handleRemoveProcedure(i) {
      this.setVisitProperty({
        key: 'procedures',
        value: JSON.stringify(
          this.parsedProcedures.filter((el, index) => index !== i)
        )
      })
      this.updateVisit()
    },
    handleSelectProcedure(index, payload) {
      this.setVisitProperty({
        key: 'procedures',
        value: JSON.stringify(
          this.parsedProcedures.map((el, i) => {
            if (index === i) {
              return {
                code: payload.code,
                text: payload.name,
                date: moment().toISOString()
              }
            }
            return el
          })
        )
      })
      this.updateVisit()
    }
  }
}
</script>
