<template>
  <div>
    <span v-if="data.code">
          {{ $t('visit.code') }}: {{ data.code }}
        </span><br />
    <span v-if="data.doctorName">
          {{ $t('visit.prescriptionDoctor') }}: {{ data.doctorName }}
        </span>
    <b-card
      v-for="el in data.medicines"
      :key="`medicine${el.id}`"
      style="margin-bottom: 5px;"
    >
      <b-row>
        <b-col cols="12">
          <span>{{ el.name }}</span>
        </b-col>
        <b-col cols="12">
          <span>{{ el.quantity }} op. po {{ el.package }}</span>
        </b-col>
        <b-col cols="12">
          <span>
            {{ $t('visit.dosing') }} {{ el.dose }},
            {{ $t('visit.refundation') }}: {{ el.refund }}
          </span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
.prescription-medicine {
  border-bottom: 1px solid #555;
  margin: 10px 0;
  padding: 10px 0;
}
</style>
