<template>
  <div class="m-3" style="white-space: pre-wrap;">{{ data }}</div>
</template>
<script>
export default {
  props: {
    data: {
      required: true
    }
  }
}
</script>
