<template>
  <b-container>
    <b-row align-v="center" align-h="center" style="height: 100vh">
      <b-col md="4" sm="12">
        <b-card>
          <b-form @submit.prevent="sendRequest">
            <b-form-group :label="$t('labels.email')" label-for="email">
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                :placeholder="$t('labels.email')"
                required
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" block>{{
              $t('forgotPassword.sendButton')
            }}</b-button>
          </b-form>
          <b-col style="text-align: center"
            ><b-link :to="`/ustaw-haslo?email=${email}`">{{
              $t('forgotPassword.haveCode')
            }}</b-link></b-col
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import userService from '../../services/user'
export default {
  data() {
    return {
      email: ''
    }
  },

  methods: {
    async sendRequest() {
      try {
        await userService.sendPasswordResetRequest(this.email)
        this.$swal(
          this.$t('success'),
          'Prośba o reset hasła wysłana. Sprawdź swoją skrzynkę mailową'
        )
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('forgotPassword.passwordResetRequestError'),
          'error'
        )
      }
    }
  }
}
</script>
