import axios from './index'

export default {
  async login(userData) {
    try {
      const { data } = await axios.post('/api/user/login', userData)
      if (data.token) {
        axios.defaults.headers.Authorization = data.token
        localStorage.setItem('token', data.token)
        localStorage.setItem('user', data.user)
        localStorage.setItem('admin', data.admin)
        localStorage.setItem('isSuperAdmin', data.isSuperAdmin)
        localStorage.setItem('tenantId', data.tenantId)
        return data.token
      } else {
        throw new Error('Błąd logowania')
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  logout() {
    localStorage.removeItem('token')
  },
  getToken() {
    return localStorage.getItem('token')
  },
  loggedIn() {
    return !!this.getToken()
  },
  init() {
    if (this.loggedIn()) {
      axios.defaults.headers.Authorization = this.getToken()
    }
  },
  sendCode(email, tenant) {
    return axios.post('/api/user/authorization-code', { email, tenant })
  },
  isSuperAdmin() {
    return localStorage.getItem('isSuperAdmin') === 'true'
  },
  isAdmin() {
    return localStorage.getItem('admin') === '1'
  },
  async refreshToken() {
    const tenantId = localStorage.getItem('tenantId')
    const { data } = await axios.post('/api/user/refresh-token', {
      tenantId
    })
    if (data.token) {
      axios.defaults.headers.Authorization = data.token
      localStorage.setItem('token', data.token)
      localStorage.setItem('user', data.user)
      localStorage.setItem('admin', data.admin)
      localStorage.setItem('isSuperAdmin', data.isSuperAdmin)
      localStorage.setItem('tenantId', data.tenantId)
    } else {
      throw new Error('Błąd logowania')
    }
  }
}
