<template>
  <div>
    <p>{{ $t('visit.checkUpDate') }}: {{ checkup.date | formatDate }}</p>
    <b-button
      v-for="(el, i) in texts"
      :key="`${el.descriptionType}-${i}`"
      :title="el.text"
      variant="light"
      size="small"
      @click="handleClickButton(el.text)"
      >{{ el.text.substr(0, 10)
      }}{{ el.text.length > 10 ? '...' : '' }}</b-button
    >
    <b-form-textarea
      :disabled="visit.status === 1"
      v-model="checkup.description"
      v-debounce:300ms="updateCheckup"
      rows="3"
      max-rows="100"
    ></b-form-textarea>
  </div>
</template>
<script>
import visits from '@/services/visits'
export default {
  props: {
    texts: {
      required: true,
      type: Array
    },
    checkup: {
      required: true,
      type: Object
    },
    visit: {
      required: true,
      type: Object
    }
  },
  methods: {
    updateCheckup() {
      visits.updateCheckupDescription(this.checkup.id, this.checkup.description)
    },
    handleClickButton(text) {
      this.checkup.description += text
      this.updateCheckup()
    }
  }
}
</script>
