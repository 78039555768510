<template>
  <b-container>
    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Podsumowanie">
            <b-card>
              <h6>Dane statystyczne</h6>
              <b-table :items="items" :fields="fields">
              </b-table>
            </b-card>
          </b-tab>
          <b-tab title="Pełen raport"></b-tab>
          <b-tab title="Błędy"></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { key: 'description', label: 'Opis' },
        { key: 'value', label: 'Wartość' }
      ],
      items: [
        { description: 'Liczba pacjentów nieskreślonych', value: 10 },
        { description: 'Liczba pacjentów skreślonych z powodu wykonania świadczeń w ostatnich 3/6 miesiącach', value: 10 },
        { description: 'Liczba pacjentów skreślonych wykonania świadczeń w miesiącu', value: 10 },
        { description: 'Średni przewidywany czas oczekiwania', value: 10 },
        { description: 'Średni rzeczywisty czas oczekiwania', value: 10 },
        { description: 'Średni rzeczywisty czas oczekiwania wszystkich pacjentów', value: 10 }
      ]
    }
  }
}
</script>
