import patientApi from '../../services/patients'
import usgApi from '../../services/usg'
import visitApi from '../../services/visits'
export default {
  async fetchPatients({ commit, state, dispatch }) {
    try {
      dispatch('Loader/showLoader', null, { root: true })
      const { data } = await patientApi.getPatients(state.filters)
      commit('setPatients', data)
    } catch (error) {
      throw new Error(error)
    } finally {
      dispatch('Loader/hideLoader', null, { root: true })
    }
  },
  async getPatient({ commit, dispatch }, { id, selectedVisitId }) {
    try {
      dispatch('Loader/showLoader', null, { root: true })
      commit('setPatient', null)
      const { data } = await patientApi.getPatient(id, {
        visitId: selectedVisitId
      })
      commit('setPatient', data.patient)
      commit('setVisits', data.visits)
      commit('setCheckUps', data.checkUps)
      commit('setPrescriptions', data.prescriptions)
      commit('setVisitCheckups', data.visitCheckups)
      commit('setServices', data.services)
      commit('setVisitServices', data.visitServices)
      commit('setHasCertPassword', data.hasCertPassword)
      commit('setReferrals', data.referrals)
      commit('setProcedures', data.procedures)
    } catch (e) {
      throw new Error(e)
    } finally {
      dispatch('Loader/hideLoader', null, { root: true })
    }
  },
  setPatientData({ commit }, data) {
    commit('setPatient', data)
  },
  resetPatients({ commit }) {
    commit('setPatients', [])
  },
  setVisitService({ commit }, payload) {
    commit('setVisitServices', payload)
  },
  async importUsgFiles({ dispatch, rootState }, { visitId, patientId }) {
    try {
      await usgApi.importUsg(visitId, rootState.Setting.usg)
      await dispatch('getPatient', { id: patientId, selectedVisitId: visitId })
    } catch (e) {
      throw new Error(e)
    }
  },
  setSelectedVisit({ commit }, payload) {
    commit('setSelectedVisit', payload)
  },
  setVisitVisibility({ commit }, payload) {
    commit('setVisitVisibility', payload)
    commit('setSelectedVisitByVisible')
  },
  setFilters({ commit }, payload) {
    commit('setFilters', payload)
  },
  async fetchVisitDates({ commit, dispatch }, payload) {
    try {
      dispatch('Loader/showLoader', null, { root: true })
      const { data } = await patientApi.getVisitDates(payload)
      commit('setVisitDates', data)
    } catch (e) {
      throw new Error(e)
    } finally {
      dispatch('Loader/hideLoader', null, { root: true })
    }
  },
  async setVisitDate({ commit, state, dispatch }, payload) {
    dispatch('Loader/showLoader', null, { root: true })
    commit('setVisitDate', payload.date)
    const dateSplitted = payload.date.split(',')
    const date = dateSplitted[0].trim()
    const hoursSplitted = dateSplitted[1].split('-')
    const hourStart = hoursSplitted[0].trim()
    const hoursEnd = hoursSplitted[1].trim()
    const start = `${date} ${hourStart}`
    const end = `${date} ${hoursEnd}`
    const patientId = payload.patientId || state.patient.id
    try {
      await visitApi.sendNextDayVisit({
        visitId: payload.visitId,
        patientId,
        start,
        end
      })
    } catch (e) {
    } finally {
      dispatch('Loader/hideLoader', null, { root: true })
    }
  },
  setCheckUpsChange({ commit }) {
    commit('setCheckUpsChange')
  },
  addVisitService({ commit }, payload) {
    commit('addVisitService', payload)
  },
  setPlannedPatientId({ commit }, payload) {
    commit('setPlannedPatientId', payload)
  }
}
