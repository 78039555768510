var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isEpidCheckup.isEpid)?_c('div',{staticClass:"mb-3"},[_c('simple-collapse',{attrs:{"title":`{{$t('visit.epidInterview')}}: ${
        _vm.isEpidCheckup.isPositive ? _vm.$t('visit.positive') : _vm.$t('visit.negative')
      }`,"is-open":_vm.collapse},on:{"toggle":function($event){return _vm.handleClickCollapse()}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.description)}})])],1):_c('div',{staticClass:"mb-3"},[_c('p',[_vm._v(" "+_vm._s(_vm.data.checkUpType)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.description)}})]),_c('div',[(_vm.filteredPhotoLinks.length > 0)?_c('b-carousel',{attrs:{"controls":"","indicators":"","interval":0,"lazy":""}},_vm._l((_vm.filteredPhotoLinks),function(photo){return _c('div',{key:photo.photoLink,on:{"click":function($event){return _vm.toggleImage(_vm.data.photoLinks)}}},[_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(
                  photo &&
                  photo.photoLink &&
                  photo.photoLink.search('null') === -1
                )?_c('b-img-lazy',{staticStyle:{"max-width":"100%","height":"300px"},attrs:{"src":photo.photoLink}}):_vm._e(),_c('div',{staticStyle:{"z-index":"2"}},_vm._l((photo.parameters),function(el){return _c('div',{key:`${el.photoId}${el.id}`},[_vm._v(" "+_vm._s(el.name)+": "+_vm._s(el.value)+" "+_vm._s(el.unit)+" ")])}),0)],1)]},proxy:true}],null,true)})],1)}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }