<template>
  <div class="loader" v-if="isOpen">
    <b-spinner style="width: 50px; height: 50px" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('Loader', {
      isOpen: (state) => state.isLoading
    })
  }
}
</script>
<style scoped>
.loader {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
