import axios from './index'

export default {
  getForms() {
    return axios.get('/api/auth/forms')
  },
  sendForm(data) {
    return axios.post('/api/auth/forms', data, {
      responseType: 'blob'
    })
  }
}
