<template>
  <b-container>
    <b-form @submit.prevent="">
      <b-form-group
        :label="$t('organization.firstName')"
        label-for="firstName"
      >
        <b-form-input
          id="firstName"
          v-model="doctor.firstName"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.lastName')"
        label-for="lastName"
      >
        <b-form-input
          id="lastName"
          v-model="doctor.lastName"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('labels.email')"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="doctor.email"
          type="email"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.statisticNumber')"
        label-for="externalId"
      >
        <b-form-input
          id="externalId"
          v-model="doctor.externalId"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.specialization')"
        label-for="specialization"
      >
        <b-form-input
          id="specialization"
          v-model="doctor.specialization"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.phoneNumber')"
        label-for="phoneNumber"
      >
        <b-form-input
          id="phoneNumber"
          v-model="doctor.phoneNumber"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.city')"
        label-for="city"
      >
        <b-form-input
          id="city"
          v-model="doctor.city"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.street')"
        label-for="street"
      >
        <b-form-input
          id="street"
          v-model="doctor.street"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.postalCode')"
        label-for="postalCode"
      >
        <b-form-input
          id="postalCode"
          v-model="doctor.postalCode"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.ewusLogin')"
        label-for="ewusLogin"
      >
        <b-form-input
          id="ewusLogin"
          v-model="doctor.ewusLogin"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.ewusPassword')"
        label-for="ewusPassword"
      >
        <b-form-input
          id="ewusPassword"
          v-model="doctor.ewusPassword"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('organization.isAdmin')"
        label-for="isAdmin"
      >
        <b-form-checkbox
          id="isAdmin"
          v-model="doctor.isAdmin"
          required
        ></b-form-checkbox>
      </b-form-group>
    </b-form>

    <div v-if="pageType === 'update'">
      <h4>{{ $t('organization.cert') }}</h4>
      <b-form-file
        v-model="file"
        :placeholder="$t('organization.saveCert')"
      />
      <b-button @click="sendCertificate()">{{
        $t('organization.save')
      }}</b-button>
    </div>
  </b-container>
</template>
<script>
import doctorService from '../../../services/doctors'
export default {
  data () {
    return {
      doctor: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        externalId: '',
        isAdmin: false,
        specialization: '',
        phoneNumber: '',
        city: '',
        street: '',
        postalCode: '',
        ewusLogin: '',
        ewusPassword: ''
      },
      file: null
    }
  },
  props: {
    pageType: {
      type: String,
      default: 'add'
    }
  },
  methods: {
    async setDoctor (doctor) {
      this.doctor = JSON.parse(JSON.stringify(doctor))
    },

    async getDoctor () {
      return this.doctor
    },

    async sendCertificate () {
      try {
        if (this.file) {
          await doctorService.sendCertificate(this.file, this.doctor.id)
          this.file = null
          this.$swal('Sukces', 'Przesłano poprawnie certyfikat')
        }
      } catch (error) {
        this.$swal('Błą∂', 'Błąd przy wysłaniu certyfikatu')
      }
    }
  }
}
</script>
