<template>
  <div class="visit-body">
    <Single
      v-for="(el, i) in data"
      :key="i"
      :id="`procedure-${i}`"
      category="procedure"
      :data="el"
    />
  </div>
</template>
<script>
import Single from './Single'
export default {
  props: {
    data: {
      type: Array
    }
  },
  components: {
    Single
  }
}
</script>
