<template>
  <div class="prescription">
    <b-modal
      id="recepty-cancel-modal"
      :title="$t('visit.prescriptionDetailsTitle')"
      size="md"
      @hide="$emit('close')"
    >
      <div class="">
        <b>{{ $t('visit.removePrescriptionConfirmation') }}</b> <br />
        {{ $t('visit.removePrescriptionInfo') }}
      </div>
      <template #modal-footer>
        <b-button
          @click="$bvModal.hide('recepty-cancel-modal')"
          class="btn-sm m-1"
          >{{ $t('close') }}</b-button
        >
        <b-button
          @click="handleCancelConfirmed(receptyData.id)"
          :disabled="loading ? '' : disabled"
          class="btn btn-danger btn-sm m-1"
          >{{ $t('visit.removePrescriptionSubmit') }}</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="recepty-info-modal"
      :title="receptyData.title"
      size="xl"
      @hide="$emit('close')"
    >
      <center>
        <b-spinner label="Spinning" v-if="loaded == false"></b-spinner>
      </center>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          id="frP1"
          class="embed-responsive-item"
          v-if="loaded"
          :src="iframe.srcdoc"
          type="application/pdf"
          frameborder="0"
        ></iframe>
      </div>
      <template #modal-footer>
        <b-button @click="$bvModal.hide('recepty-info-modal')">{{
          $t('close')
        }}</b-button>
      </template>
    </b-modal>
    <div v-for="el in prescriptions" :key="`prescription-${el.id}`">
      <div style="margin-top: 5px; margin-right: 6px">
        <b-button
          v-if="el.cancel_date == null"
          variant="danger"
          @click="handleCancel(el.id)"
          class="mr-1 float-right"
        >
          <font-awesome-icon icon="file-prescription" />
        </b-button>
        <b-button
          variant="primary"
          @click="handleViewDetails(el.id)"
          class="mr-1 float-right"
        >
          <font-awesome-icon icon="file-prescription" />
        </b-button>
        <b-button @click="handleCopyPrescription(el.id)">{{ $t('visit.copyPrescription') }}</b-button>
        <span v-if="el.cancel_date != null" class="ml-2 mt-3 float-left"
          ><i
            ><b>{{ $t('visit.removed') }}: </b> :
            {{ new Date(el.cancel_date * 1000).toLocaleString('pl-PL') }}</i
          ></span
        >
      </div>
      <div style="clear: both; margin: 0px"></div>
      <Single
        v-if="el.cancel_date != null"
        style="
          background: #eee;
          border: 1px dashed;
          border-style: dashed;
          border-color: #dc3545;
          color: #dc3545;
          margin-top: 4px;
        "
        :data="el"
        category="prescription"
        :class="`visit-${el.visitId}`"
      />
      <Single
        v-else
        :data="el"
        category="prescription"
        :class="`visit-${el.visitId}`"
      />
    </div>
  </div>
</template>
<script>
import prescriptionApi from '../../../../services/prescriptionApi'
import Single from './Single'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      loaded: false,
      loading: false,
      receptyData: {
        title: ''
      },
      iframe: {
        style: null,
        wrapperStyle: null,
        srcdoc: null
      }
    }
  },
  components: {
    Single
  },
  computed: {
    ...mapState('Patient', {
      prescriptions: (state) => state.prescriptions
    })
  },
  methods: {
    async handleDownload(prescriptionId) {
      // let response = await prescriptionApi.downloadPrescription(prescriptionId)
      //
      // let blob = new Blob([response.data], { type: 'application/pdf' })
      // this.iframe.srcdoc=window.URL.createObjectURL(blob)
      // let link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = `prescription_${prescriptionId}.pdf`
      // link.click()
      // link.remove()
    },
    async handleCancelConfirmed(prescriptionId) {
      try {
        this.loading = true
        this.receptyData.btnCaption = 'Usuń...'
        await prescriptionApi.cancelPrescription(prescriptionId)
        this.loading = false
        // alert('Recepta skutecznie usunięta')
        this.$bvModal.hide('recepty-cancel-modal')
        this.$swal(this.$t('success'), 'Recepta skutecznie usunięta', 'success')
      } catch (ex) {
        this.$bvModal.hide('recepty-cancel-modal')
        this.$swal(this.$t('error'), 'Nie udało się usunąć recepty', 'error')
      }
    },
    async handleCancel(prescriptionId) {
      this.loading = false
      this.$bvModal.show('recepty-cancel-modal')
      this.receptyData = {
        id: prescriptionId
      }
    },
    async handleViewDetails(prescriptionId) {
      this.$bvModal.show('recepty-info-modal')
      this.iframe.srcdoc = null
      this.loading = true
      this.loaded = false
      this.receptyData = {
        id: prescriptionId,
        title: 'Prescription Details - ' + prescriptionId
      }
      this.iframe.style = {
        position: 'absolute',
        width: '90%',
        height: '100%'
      }
      // let response = await prescriptionApi.getPrescriptionDetails(prescriptionId)
      // let html = response.data.prescriptionHtmlContent
      // this.iframe.srcdoc = html // 'data:text/html;charset=utf-8,' + encodeURI(html)
      let response = await prescriptionApi.downloadPrescription(prescriptionId)
      let blob = new Blob([response.data], { type: 'application/pdf' })
      this.iframe.srcdoc = window.URL.createObjectURL(blob)
      this.iframe.wrapperStyle = {
        overflow: 'hidden',
        height: '100%'
      }
      this.loaded = true
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(response.data)
      }, 2000)
      setTimeout(
        () => document.getElementById('frP1').contentWindow.print(),
        3000
      )
      this.loading = false
    },
    handleCopyPrescription(id) {
      this.$emit('copy', id)
    }
  }
}
</script>
