import axios from './index'

export default {
  getUserTenants(email) {
    return axios.get('/api/user/tenants', {
      params: {
        email
      }
    })
  },

  sendPasswordResetRequest(email) {
    return axios.post('/api/user/reset-password/key', { email })
  },

  setNewPassword(email, key, password) {
    return axios.post('/api/user/reset-password', { email, key, password })
  }
}
