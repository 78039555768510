<template>
  <b-modal id="forms-modal" hide-footer>
    <b-form @submit.prevent="handleSubmit()">
      <b-form-group :label="$t('visit.chooseForm')">
        <b-form-select
          v-model="form.formId"
          :options="options"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group v-for="el in fields" :key="`form${el.id}`" :label="el.name">
        <b-form-textarea
          v-model="form.fields[el.id]"
          required
          rows="3"
          max-rows="100"
        ></b-form-textarea>
      </b-form-group>
      <b-button type="submit">{{ $t('visit.generate') }}</b-button>
    </b-form>
  </b-modal>
</template>
<script>
export default {
  props: {
    forms: {
      type: Array,
      required: true
    }
  },
  computed: {
    fields() {
      const form = this.forms.find((el) => el.id === this.form.formId)
      if (form) {
        return form.fields
      }
      return []
    },
    options() {
      return this.forms.map((el) => ({
        text: el.name,
        value: el.id
      }))
    }
  },
  data() {
    return {
      form: {
        formId: 0,
        fields: {}
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('send-form', this.form)
    }
  },
  mounted() {
    this.form.patientId = this.$route.params.id
  }
}
</script>
