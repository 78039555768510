<template>
  <b-modal
    no-close-on-esc
    no-close-on-backdrop
    ref="speechModal"
    @hide="$emit('close')"
    id="speech-modal"
    :visible="open"
    size="xl"
    :class="[isMinimized == 1 ? 'captureMin' : 'captureMax']"
  >
    <template #modal-header>
      <b-row align-h="between" class="head-container">
        <b-col md="12" align-self="center">
          <h5>
            {{ $t('visit.captureSpeech') }} - Lekarz {{ captureTitle }} :
            {{ new Date().toLocaleString() }}
          </h5>
        </b-col>
      </b-row>
    </template>
    <div>
      <b-row>
        <b-col md="12">
          <p style="font-style: italic; text-align: center; margin: auto">
            {{ interimText }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-card
            ref="scrollableDiv"
            class="voiceCapture"
            style="max-height: 50vh; overflow-y: auto; padding: 0; margin: 0"
          >
            <div
              ref="voiceSource"
              contenteditable="true"
              v-if="showSource"
              class="words editable-content"
              v-html="sourceContent"
            ></div>
            <div
              ref="voiceTimeline"
              class="words"
              v-if="showTimeline"
              v-html="timelineContent"
            ></div>
          </b-card>
          <b-button
            variant="secondary"
            class="mt-2"
            size="sm"
            @click="clearAll"
            >{{ $t('visit.captureClear') }}</b-button
          >
          <b-button
            variant="primary"
            class="mt-2 ml-2 float-right"
            size="sm"
            :disabled="
              summaryStatus === 1 ||
              finalTranscript.length == 0
            "
            @click="handleSummarize"
            >{{ $t(summarizeLabel) }}
            {{ summaryStatus === 1 ? '...' : '' }}</b-button
          >
          <select
            v-model="promptId"
            @change="handlePromptChange"
            md="12"
            class="float-right mt-3 ml-2"
          >
            <option
              v-for="option in this.prompts"
              :key="option.sno"
              :value="option.sno"
            >
              {{ option.name }}
            </option>
          </select>
        </b-col>

        <b-col md="2" class="d-flex align-items-start justify-content-center">
          <b-row>
            <b-col md="12">
              <div
                class="mt-3 ml-3 pb-2 d-flex align-items-center justify-content-center"
              >
                <mic-capture-real-time
                  v-if="selectedMicrophone != ''"
                  :key="componentKey"
                  ref="speechCapt"
                  :lang="lang_"
                  @transcriptionText="onTranscriptionText"
                  :device-id="selectedMicrophone"
                ></mic-capture-real-time>
              </div>
              <div
                class="mt-2 d-flex align-items-center justify-content-center"
              >
                <select
                  :disabled="$refs?.speechCapt?.isRecording == true"
                  v-model="selectedMicrophone"
                  style="width: 50%; display: block"
                  class="m-2"
                >
                  <option
                    v-for="mic in microphones"
                    :key="mic.deviceId"
                    :value="mic.deviceId"
                  >
                    {{ mic.label }}
                  </option>
                </select>
                <select v-model="lang_">
                  <option :value="navAgentLang">{{ navAgentLang }}</option>
                  <option v-if="navAgentLang != 'pl-PL'" value="pl-PL">
                    Polski
                  </option>
                  <option v-if="navAgentLang != 'en-US'" value="en-US">
                    English(US)
                  </option>
                  <option value="fr-FR">French</option>
                </select>
              </div>

              <a v-if="audioUrl" :href="audioUrl" :download="audioFilename"
                >Download Audio {{ audioChunks.length }}</a
              >
              <p
                style="
                  font-style: italic;
                  text-align: center;
                  margin: auto;
                  color: red;
                "
              >
                <span v-if="$refs?.speechCapt?.totalAudioSize > 0"
                  >size :
                  {{
                    Math.ceil($refs?.speechCapt?.totalAudioSize / 1024).toFixed(
                      2
                    )
                  }}
                  KB |
                </span>
                {{ getProcessingStatus }}
              </p>
              <b-button
                v-if="$refs?.speechCapt?.isRecording"
                variant="secondary"
                @click="minimizeCapture"
                class="primary mt-4 p-1"
                style="border: 1px solid #eee; text-align: center"
              >
                {{ $t('visit.captureMinimize') }}&nbsp;
                <font-awesome-icon
                  style="float: right; color: rgb(245, 239, 239)"
                  icon="window-minimize"
                ></font-awesome-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="5">
          <b-card
            class="voiceCapture"
            ref="scrollableDivFormatted"
            style="max-height: 50vh; overflow-y: auto; padding: 0; margin: 0"
          >
            <div
              ref="voiceFormatted"
              contenteditable="true"
              class="words editable-content highlight-text"
            ></div>
          </b-card>
          <b-button variant="success" class="mt-2 ml-2" size="sm" v-if="2 > 3"
            >Copy Content</b-button
          ><b-button
            @click="handleSave"
            variant="warning"
            class="mt-2 ml-2 float-right"
            size="sm"
            >{{ $t('visit.captureSave') }}</b-button
          >
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col class="text-right">
          <b-button
            @click="
              $bvModal.hide('speech-modal')
              $refs?.speechCapt?.stopRecording()
            "
            >{{ $t('close') }}</b-button
          >
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import MicCaptureRealTime from '../../../components/MicCaptureRealTime.vue'
import aiServicesApi from '../../../services/aiServicesApi'
import { nanoid } from 'nanoid'

const uploadAudioChunk = async (chunkData, presignedUrl) => {
  try {
    const res = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'audio/wav'
        // 'Content-Range': `bytes ${chunkData.start}-${chunkData.end}/${chunkData.total}`
      },
      body: chunkData
    })

    if (res.ok) {
      // console.log('Chunk uploaded successfully.')
      return await res.json()
    } else {
      // console.error('Error uploading chunk:', res.statusText)
      return Promise.resolve({})
    }
  } catch (error) {
    // console.error('Error uploading chunk:', error)
  }
}

const port = process.env.VUE_APP_API_PORT || 3000
const baseURL =
  process.env.NODE_ENV === 'production' ? `` : `http://localhost:${port}`

export default {
  data() {
    return {
      recording: false,
      componentKey: 0,
      microphones: [],
      selectedMicrophone: null,
      audioChunks: [],
      audioUrl: null,
      mediaRecorder: null,
      finalTranscript: '',
      dataStream: {},
      isMinimized: 0,
      showModal: true,
      captureTitle: '',
      showSource: true,
      fullContent: [],
      showTimeline: false,
      transcription: [],
      timelines: [],
      sourceContent: '',
      timelineContent: '',
      interimText: '',
      prompts: [],
      lang_: window.navigator.language,
      promptId: 1,
      summaryStatus: 0,
      progressStatus: 0,
      audioFilename: ''
      // 0 - default, 1- submitted 3-processed
    }
  },
  components: { MicCaptureRealTime },
  async mounted() {
    this.captureTitle = await this.getCaptureTitle()
    this.prompts = await this.getSummaryPrompts()
    this.audioFilename =
      this.captureTitle +
      '_' +
      new Date().toLocaleString().replace(/\//g, '').replace(/:/g, '_')
    this.getMicrophones()
  },
  computed: {
    getProcessingStatus() {
      const statuses = [
        '',
        'preparing to upload audio',
        'audio uploaded',
        'processing audio',
        'audio Processed',
        'extracting summary',
        'summary completed'
      ]
      return this.progressStatus === 0
        ? ''
        : statuses[this.progressStatus] + '...'
    },
    navAgentLang() {
      return window.navigator.language
    },
    summarizeLabel() {
      const labelStatus = [
        'visit.captureSummarize',
        'visit.captureSummarizing',
        'visit.captureSummarize'
      ]
      return labelStatus[this.summaryStatus]
    }
  },
  methods: {
    formatTranscriptionInSeconds(jsonData) {
      const transcription =
        typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData

      const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000)
        const minutes = Math.floor(totalSeconds / 60)
        const seconds = totalSeconds % 60
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
          2,
          '0'
        )}`
      }

      const formattedLines = []

      for (const [time, text] of Object.entries(transcription)) {
        // Format the time and add it to the lines
        const formattedTime = formatTime(Number(time))
        formattedLines.push(`${formattedTime}: ${text}`)
      }
      return formattedLines.join('\n')
    },
    async getMicrophones() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.microphones = devices.filter(
          (device) => device.kind === 'audioinput'
        )
        if (this.microphones.length > 0) {
          this.selectedMicrophone = this.microphones[0].deviceId
        }
      } catch (error) {
        // console.error('Error enumerating devices:', error)
      }
    },
    async processChunks(presignedUrl) {
      const flattened = this.audioChunks.flat()
      const blobData = new Blob(flattened, { type: 'audio/wav' })
      const result = await uploadAudioChunk(blobData, presignedUrl)
      return Promise.resolve(result)
    },
    minimizeCapture() {
      // this.isMinimized
      this.$emit('minimized')
      const el = document.querySelector('#speech-modal___BV_modal_outer_')
      el.style = 'position: absolute; z-index: -1040;'
    },
    stripMarkdown(inputText) {
      let textWithoutHeaders = inputText.replace(/#{1,6}\s/g, '')
      let textWithoutBold = textWithoutHeaders.replace(
        /(\*\*|__)(.*?)\1/g,
        '$2'
      )
      let textWithoutItalic = textWithoutBold.replace(/(\*|_)(.*?)\1/g, '$2')
      let textWithoutStrikethrough = textWithoutItalic.replace(
        /~~(.*?)~~/g,
        '$1'
      )
      let textWithoutUnorderedList = textWithoutStrikethrough.replace(
        /^- (.*)/gm,
        '$1'
      )
      let textWithoutOrderedList = textWithoutUnorderedList.replace(
        /^\d+\.\s(.*)/gm,
        '$1'
      )
      let textWithoutLinks = textWithoutOrderedList.replace(
        /\[([^\]]+)\]\([^)]+\)/g,
        '$1'
      )
      return textWithoutLinks
    },
    cleanAndFormatText(inputText) {
      let formattedText = inputText.replace(/\n/g, '<br>')
      return this.stripMarkdown(formattedText)
      // let formattedText = inputText.replace(/###/g, '<b>')
      // formattedText = formattedText.replace(/####/g, '</b>')

      // return formattedText
    },

    async getPresignedUrl(payload, visitId) {
      const url = `${baseURL}/api/auth/aiservices/${visitId}/presigned-asset-url`
      const headers = {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
      const response = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(payload)
      })
      const result = await response.json()
      return result
    },
    async startStreaming(payload, visitId) {
      this.summaryStatus = 1
      this.progressStatus = 0
      const url = `${baseURL}/api/auth/aiservices/${visitId}/summarize-with-rt-stream`
      try {
        const headers = {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
        const response = await fetch(url, {
          headers,
          method: 'POST',
          body: JSON.stringify(payload)
        })
        this.updateSummaryProgress(3)
        // Using While Loop
        let summarizedText = ''
        const reader = response?.body?.getReader()
        const decoder = new TextDecoder()
        this.updateSummaryProgress(4)
        while (true) {
          const { done, value: chunk } = await reader.read()
          if (done) break
          const decodedValue = decoder.decode(chunk)

          summarizedText += decodedValue

          this.$refs.voiceFormatted.innerHTML =
            this.cleanAndFormatText(summarizedText)
          this.$refs.scrollableDivFormatted.scrollTop =
            this.$refs.voiceFormatted.scrollHeight
          this.updateSummaryProgress(5)
        }
        this.updateSummaryProgress(6)
        this.summaryStatus = 2
      } catch (error) {}
    },

    htmlToPlainText(htmlString) {
      const parser = new DOMParser()
      htmlString = htmlString.replace(/<br>/g, '\n')
      const doc = parser.parseFromString(htmlString, 'text/html')
      function extractText(node, resultArray) {
        let consecutiveBrCount = 0

        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.TEXT_NODE) {
            resultArray.push(child.textContent)
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            if (child.tagName.toLowerCase() === 'br') {
              // Track consecutive <br> tags
              consecutiveBrCount++
            } else {
              // If consecutive <br> tags, add appropriate newline characters
              if (consecutiveBrCount > 0) {
                resultArray.push('\n'.repeat(consecutiveBrCount))
                consecutiveBrCount = 0 // Reset the count
              }
              extractText(child, resultArray)
            }
          }
        })
      }
      const resultArray = []
      extractText(doc.body, resultArray)
      const plainText = resultArray.join('\n')
      return plainText
    },
    handleSave(_e) {
      this.$emit('save-summary', {
        source: this.$refs.voiceSource.innerHTML,
        content: this.$refs.voiceFormatted.innerHTML,
        contentPlain: this.$refs.voiceFormatted.innerText
      })
    },
    handlePromptChange(_e) {
      this.summaryStatus = 0
    },
    async updateSummaryProgress(stepNo) {
      this.progressStatus = stepNo
    },
    async handleSummarize() {
      try {
        this.progressStatus = 0
        const visitId = this.$route.params.visit
        const hashId = nanoid(4)
        const payload = {
          promptId: this.promptId,
          patientId: this.$route.params.id,
          hashId,
          fileInfo: {
            extension: 'wav',
            mimeType: 'audio/wav'
          },
          sourceText: this.sourceContent
        }
        this.summaryStatus = 1
        this.updateSummaryProgress(1)
        //        const signedUrl = await this.getPresignedUrl(payload, visitId)
        //      await this.processChunks(signedUrl.url)
        this.updateSummaryProgress(2)
        return this.startStreaming(payload, visitId)
      } catch (error) {}
    },
    clearAll() {
      this.timelineContent = ''
      this.sourceContent = ''
      this.summaryStatus = 0
      this.$refs.speechCapt.clearAll()
    },
    async getSummaryPrompts() {
      try {
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        const response = await aiServicesApi.getSummaryPrompts(
          visitId,
          patientId
        )
        return response.prompts
      } catch (e) {}
    },
    async getCaptureTitle() {
      try {
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        const response = await aiServicesApi.getSpeechCaption(
          visitId,
          patientId
        )
        return response.title
      } catch (e) {}
    },
    onTranscription(dataChunks) {
      this.audioChunks = dataChunks
    },

    onStopped() {
      this.finalTranscript = this.sourceContent
    },
    onTranscriptionText(transcriptText) {
      console.log(transcriptText)
      const formatted = this.formatTranscriptionInSeconds(transcriptText)
      this.sourceContent = this.cleanAndFormatText(formatted)
      this.finalTranscript = this.sourceContent
      // this.$refs.voiceSource.innerHTML = formatted
    }
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  }
}
</script>
<style>
/* Additional styling for editable content */
.editable-content {
  border: 0px solid #ddd;
  /* Add a border for visual separation */
  padding: 1px;
  min-height: 200px;
  margin: 0 !important;
  width: 100%;

  /* Set a minimum height for better visibility */
}

.words {
  max-width: 500px;
  margin: 1px auto;
  color: darkblue;
  background-color: white;
  border-radius: 2px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem 1rem 1.2rem;
  background: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(#d9eaf3),
      color-stop(4%, #fff)
    )
    0 4px;
  background-size: 100% 1.5rem;
  position: relative;
  line-height: 1.5rem;
  margin: 0 0 1rem;
  font-family: 'Georgia', serif;
}

p {
  margin: 0 0 1rem;
  font-family: 'Georgia', serif;
}

.words:before {
  content: '';
  position: absolute;
  width: 4px;
  top: 0;
  left: 1px;
  bottom: 0;
  border: 1px solid;
  border-color: transparent #efe4e4;
}

.voiceCapture .card-body {
  padding: 0.25rem !important;
  margin-left: 0.1rem;
}
</style>
