import axios from './index'

export default {
  getPatients(params) {
    return axios.get('/api/auth/patient', { params })
  },
  getPatient(id, params = {}) {
    return axios.get(`/api/auth/patient/${id}`, { params })
  },
  getVisitDates(date) {
    return axios.post('/api/auth/patient/visit-date', { date })
  },
  updatePatient(payload) {
    return axios.put(`/api/auth/patient/${payload.id}`, payload)
  },
  savePatient(payload) {
    return axios.post('/api/auth/patient', payload)
  },
  getTeryt(postCode) {
    return axios.get(`/api/auth/patient/postcode/${postCode}`)
  }
}
