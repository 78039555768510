import Vue from 'vue'
import moment from 'moment'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(value)
  }
})

Vue.filter('formatDateVisit', (value) => {
  const date = moment(value)
  return date.format('HH:mm')
})

Vue.filter('formatDateDay', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})
