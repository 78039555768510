import I18n from 'vue-i18n'
import Vue from 'vue'

import { polishTranslations } from '../lang/pl'
import { englishTranslations } from '../lang/eng'

Vue.use(I18n)

export default new I18n({
  locale: 'pl',
  messages: {
    pl: polishTranslations,
    en: englishTranslations
  }
})
