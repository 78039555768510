<template>
  <b-container>
    <b-row>
      <b-col>
        <h6>Faktury NFZ</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-table :fields="fields" :items="items">
      </b-table>
    </b-row>
    <b-row>
      <b-col><b-button>Wydrukuj FV</b-button><b-button>Wygeneruj plik</b-button></b-col>
      <b-col><b-button>Wczytaj plik szablonu</b-button><b-button>Wystaw FV do szablonu</b-button></b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { key: 'createOn', label: 'Data utworzenia' },
        { key: 'templateId', label: 'Numer szablonu' },
        { key: 'invoiceAmount', label: 'Kwota rozliczenia' },
        { key: 'invoceId', label: 'Numer faktury VAT' },
        { key: 'isEinvoice', label: 'Wygenerowano eFakturę' },
        { key: 'isNFZcorrection', label: 'korekta NFZ' }
      ],
      items: [
        {
          createOn: '2024-06-30',
          templateId: '1',
          invoiceAmount: '1000,00 zł',
          invoceId: '1',
          isEinvoice: 'Tak',
          isNFZcorrection: 'Nie'
        },
        {
          createOn: '2024-06-30',
          templateId: '2',
          invoiceAmount: '2000,00 zł',
          invoceId: '2',
          isEinvoice: 'Tak',
          isNFZcorrection: 'Nie'
        }
      ]
    }
  }
}
</script>
