<template>
  <ul class="iq-timeline" v-if="items" style="position: relative; left: 80px;">
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="{
        selected: item.selected
      }"
      @click="handleClick(item)"
    >
      <small class="mt-1 date" v-html="item.date"></small>
      <div
        class="timeline-dots"
        :class="`border-${item.selected ? 'success' : 'primary'}`"
      ></div>
      <h6 class="mb-1 title" v-html="item.title"></h6>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'TimeLine',
  props: {
    items: { type: Array, default: () => [] }
  },
  methods: {
    ...mapActions('Patient', ['setSelectedVisit']),
    handleClick(item) {
      if (item) {
        this.setSelectedVisit(item)
        const visitBody = document.getElementById(`visit-body`)
        const visit =
          document.querySelector(`#${item.type}-${item.id}`) ||
          document.querySelector(`.${item.type}-${item.id}`)
        if (visit) {
          visitBody.scrollTo({
            top: visit.offsetTop - 80,
            behavior: 'smooth'
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
li {
  width: 150px;
}
.date {
  position: relative;
  right: 100px;
  color: #fff;
}
.title {
  position: relative;
  bottom: 30px;
}
.selected {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.iq-timeline {
  margin: 0 0 0 5px;
  padding: 0;
  width: 100%;
  border-left: 3px solid #f2edff;
}

.iq-timeline li {
  margin-left: 15px;
  position: relative;
  padding: 15px 15px 0 5px;
  list-style-type: none;
}

.iq-timeline li .timeline-dots {
  position: absolute;
  top: 20px;
  left: -24px;
  border: 3px solid #089bab;
  border-radius: 90px;
  padding: 5px;
  background: #fff;
}

.iq-timeline li .timeline-dots-fill {
  position: absolute;
  top: 20px;
  left: -23px;
  background: #089bab;
  border-radius: 90px;
  padding: 7px;
}
</style>
