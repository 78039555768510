import axios from 'axios'
import auth from './auth'
import router from '../router/index'
const port = process.env.VUE_APP_API_PORT || 3000
// import constant from '../config/constant'

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? `` : `http://localhost:${port}`,
  headers: {
    common: {
      Authorization: localStorage.getItem('token')
    }
  }
})

api.defaults.withCredentials = true

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 403) {
      auth.logout()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default api
