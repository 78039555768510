import axios from './index'

export default {
  getAll() {
    return axios.get('/api/auth/doctors')
  },

  getSingle(id) {
    return axios.get(`/api/auth/doctors/${id}`)
  },

  create(data) {
    return axios.post(`/api/auth/doctors`, data)
  },

  update(data) {
    return axios.put(`/api/auth/doctors/${data.id}`, data)
  },

  sendCertificate(file, id) {
    const formData = new FormData()
    formData.append('certificate', file)
    return axios.post(`/api/auth/doctors/${id}/certificate`, formData)
  },

  getHours(id) {
    return axios.get(`/api/auth/doctors/${id}/hours`)
  },

  updateHours(id, data) {
    return axios.put(`/api/auth/doctors/${id}/hours`, data)
  }
}
