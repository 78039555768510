<template>
  <b-form>
    <b-form-group :label="$t('organization.specializations')" label-for="specializations" label-cols="2">
        <b-form-input id="specializations" v-model="descriptions.specializations" required></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('organization.specializationsEng')" label-for="specializationsEng" label-cols="2">
        <b-form-input id="specializationsEng" v-model="descriptions.specializationsEng" required></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('organization.aboutMe')" label-for="aboutMe" label-cols="2">
        <b-form-textarea id="aboutMe" v-model="descriptions.aboutMe" required></b-form-textarea>
      </b-form-group>
      <b-form-group :label="$t('organization.awards')" label-for="awards" label-cols="2">
        <b-form-input id="awards" v-model="descriptions.awards" required></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('organization.nfzDescription')" label-for="nfzDescription" label-cols="2">
        <b-form-input id="nfzDescription" v-model="descriptions.nfzDescription" required></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('organization.treatedDiseases')" label-for="treatedDiseases" label-cols="2">
        <b-form-textarea id="treatedDiseases" v-model="descriptions.treatedDiseases" required></b-form-textarea>
      </b-form-group>
      <b-form-group :label="$t('organization.experience')" label-for="experience" label-cols="2">
        <b-form-textarea id="experience" v-model="descriptions.experience" required></b-form-textarea>
      </b-form-group>
      <b-form-group :label="$t('organization.paymentMethods')" label-for="paymentMethods" label-cols="2">
        <b-form-input id="paymentMethods" v-model="descriptions.paymentMethods" required></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('organization.kindOfPatients')" label-for="kindOfPatients" label-cols="2">
        <b-form-input id="kindOfPatients" v-model="descriptions.kindOfPatients" required></b-form-input>
      </b-form-group>
  </b-form>
</template>
<script>
export default {
  data() {
    return {
      descriptions: {
        specializations: '',
        specializationsEng: '',
        phone: '',
        aboutMe: '',
        awards: '',
        nfzDescription: '',
        treatedDiseases: '',
        experience: '',
        paymentMethods: '',
        kindOfPatients: ''
      }
    }
  },
  methods: {
    setData(data) {
      this.descriptions = data
    },
    getData() {
      return this.descriptions
    }
  }
}
</script>
