import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  patients: {
    rows: [],
    count: 0
  },
  filters: {
    page: 1,
    limit: 50,
    search: '',
    status: ''
  },
  patient: {
    firstName: '',
    lastName: ''
  },
  visits: [],
  checkUps: [],
  prescriptions: [],
  selectedVisit: {},
  visitDates: [],
  selectedVisitDate: '',
  visitCheckups: [],
  checkUpsChange: false,
  services: [],
  visitServices: [],
  plannedPatientId: null,
  hasCertPassword: false,
  referrals: [],
  procedures: []
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
