<template>
  <b-container fluid>
    <b-row class="detail-wrapper">
      <b-col :md="sizeVisit" id="visits" :order = "justify.firstBlockOrder" :class="{ 'transition' : justify.firstBlockTransition }"
>
        <!-- <h5 class="text-right">
          <b-icon-fullscreen v-b-tooltip.hover title="Full View" v-if="!firstBlockfullView"  @click="toggleFirstBlock()"></b-icon-fullscreen>
          <b-icon-fullscreen-exit v-b-tooltip.hover title="Exit Full View" v-if="firstBlockfullView"  @click="toggleFirstBlock()"></b-icon-fullscreen-exit>
        </h5> -->
        <visit @copy-prescription="handleCopyPrescription"/>
      </b-col>
      <b-col
        :md="sizeAddVisit"
        :order = "justify.secondBlockOrder"
        :class="{ 'transition' : justify.secondBlockTransition }"
      >
        <!-- <h5 class="text-right">
        <b-icon-fullscreen v-b-tooltip.hover title="Full View" v-if="!secondBlockfullView"  @click="toggleSecondBlock()"></b-icon-fullscreen>
          <b-icon-fullscreen-exit v-b-tooltip.hover title="Exit Full View" v-if="secondBlockfullView"  @click="toggleSecondBlock()"></b-icon-fullscreen-exit>
        </h5> -->
        <add-visit ref="addVisit" @get-data="getPatientData()" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Visit from './Visit/index'
import AddVisit from './AddVisit'
export default {
  components: {
    Visit,
    AddVisit
  },
  computed: {
    ...mapState('Setting', {
      justify: (state) => state.patientJustify
    }),
    ...mapGetters('Patient', ['getVisitsToTimeLine']),
    sizeVisit() {
      // return this.justify === 'center' ? 6 : this.justify === 'left' ? 12 : 0
      return this.justify.expand === 'center' ? 6 : 12
    },
    sizeAddVisit() {
      // return this.justify === 'center' ? 6 : this.justify === 'right' ? 12 : 0
      return this.justify.expand === 'center' ? 6 : 12
    }
  },
  data() {
    return {
      size: 'both',
      firstBlockfullView: false,
      secondBlockfullView: false,
      firstBlockOrder: 1,
      secondBlockOrder: 2
    }
  },
  async beforeMount() {
    await this.getPatientData()
    if (this.getVisitsToTimeLine && this.getVisitsToTimeLine.length > 0) {
      const { id, type } = this.getVisitsToTimeLine[0]
      this.setSelectedVisit({ id, type })
    }
    this.$refs.addVisit.init()
  },
  methods: {
    ...mapActions('Patient', ['getPatient', 'setSelectedVisit']),
    ...mapActions('Setting', ['setPatientJustify']),
    async getPatientData() {
      const patientId = this.$route.params.id
      let patientVisitId = null
      if (this.$route.params.visit) {
        patientVisitId = this.$route.params.visit
      }
      await this.getPatient({ id: patientId, selectedVisitId: patientVisitId })
    },
    handleCopyPrescription(id) {
      this.$refs.addVisit.copyPrescription(id)
    }
    // toggleFirstBlock() {
    //   this.firstBlockfullView = !this.firstBlockfullView
    //   this.firstBlockOrder = 1
    //   if (this.firstBlockfullView) {
    //     this.setPatientJustify('left')
    //     this.secondBlockfullView = true
    //   } else {
    //     this.setPatientJustify('center')
    //     this.secondBlockfullView = false
    //   }
    // },
    // toggleSecondBlock() {
    //   this.secondBlockfullView = !this.secondBlockfullView
    //   if (this.secondBlockfullView) {
    //     this.setPatientJustify('left')
    //     this.firstBlockfullView = true
    //     this.firstBlockOrder = 2
    //     this.secondBlockOrder = 1
    //   } else {
    //     this.setPatientJustify('center')
    //     this.firstBlockfullView = false
    //     this.firstBlockOrder = 1
    //     this.secondBlockOrder = 2
    //   }
    // }
  }
}
</script>
<style scoped>
.transition{
  transition: all 100ms linear;
}
/* .col-0 {
  width: 0;
  flex-shrink: 1;
  transition: all 400ms ease;
}
.col-12 {
  flex-grow: 1;
  transition: all 400ms ease;
} */
</style>
