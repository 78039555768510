<template>
  <b-container>
    <h2>
      {{ $t('organization.doctors') }}
      <b-button @click="handleClickAddDoctor()">{{
        $t('organization.addDoctor')
      }}</b-button>
    </h2>

    <b-row>
      <b-table
        hover
        :fields="fields"
        :items="doctors"
        @row-clicked="handleClickDoctorRow"
      >
      </b-table>
    </b-row>
  </b-container>
</template>
<script>
import doctorService from '../../services/doctors'
export default {
  data() {
    return {
      doctors: [],
      fields: [
        {
          key: 'firstName',
          label: this.$t('organization.firstName')
        },
        {
          key: 'lastName',
          label: this.$t('organization.lastName')
        },
        {
          key: '',
          label: this.$t('organization.actions'),
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        }
      ]
    }
  },

  beforeMount() {
    this.getDoctors()
  },
  methods: {
    async getDoctors() {
      try {
        const { data } = await doctorService.getAll()
        this.doctors = data
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('organization.getDoctorsError'),
          'error'
        )
      }
    },
    handleClickDoctorRow(item) {
      this.$router.push(`/app/doktorzy/edytuj/${item.id}`)
    },
    handleClickAddDoctor() {
      this.$router.push('/app/doktorzy/dodaj')
    }
  }
}
</script>
<style scoped>
.row {
  cursor: pointer;
}
</style>
