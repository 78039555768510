<template>
  <div class="main-wrapper" :class="{ 'sidebar-open': sidebarState }">
    <div class="navigation" ref="navigation">
      <div class="navigation-toggler">
        <b-button @click="toggleSidebar()" pill><font-awesome-icon
            :icon="sidebarState ? 'arrow-left' : 'arrow-right'" /></b-button>
      </div>
      <div style="margin: 0 30px">
        <component :is="navBarComponent" />
      </div>
      <div>
        <b-dropdown variant="primary">
          <template #button-content>
            <font-awesome-icon icon="user-cog" style="font-size: 30px; margin-right: 10px" />
            {{ shortUser }}
          </template>
          <b-dropdown-item @click="handleChangeUsg()">{{
            $t('layout.changeUsg')
            }}</b-dropdown-item>
          <b-dropdown-item @click="logout()">{{
            $t('layout.logout')
            }}</b-dropdown-item>
          <b-dropdown-item>
            <b-button-group>
              <b-button @click="changeLanguage('pl')" :disabled="$i18n.language === 'pl'">Pl</b-button>
              <b-button @click="changeLanguage('en')" :disabled="$i18n.language === 'en'">Eng</b-button>
            </b-button-group>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="sidebar">
      <div class="sidebar-home-link">
        <router-link to="/app/wizyty"><font-awesome-icon icon="home" size="lg" style="color: #fff" /></router-link>
      </div>
      <visit-time-line v-if="menuType === 'timeline'" />
      <b-nav vertical v-else>
        <b-nav-item v-for="item in navLinks" :key="item.link" :active="isMenuSelected(item.link)" :to="item.link"
          active-class="nav-item-active" link-classes="nav-item">{{ item.label }}</b-nav-item>
        <b-nav-item-dropdown text="NFZ" toggle-class="nav-item">
          <b-dropdown-item v-for="el in nfzLinks" :key="el.link" :to="el.link">{{
            el.label
            }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    </div>
    <div class="main" :style="`height: calc(100vh - ${navigationHeight}px)`">
      <router-view />
      <b-modal ref="modal" hide-footer hide-header id="usg-modal">
        <div>
          <h4>{{ $t('chooseUsg') }}</h4>
          <div class="button-wrapper">
            <b-button @click="handleSetUsgDevice('usg_1')">USG 1</b-button>
            <b-button @click="handleSetUsgDevice('usg_2')">USG 2</b-button>
            <b-button @click="handleSetUsgDevice('usg_3')">USG 3</b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <loader />
  </div>
</template>
<script>
import VisitFilters from '../components/VisitFilters'
import PatientFilters from '../views/Patient/PatientFilters'
import PatientDetails from '../views/Patient/PatientDetails'
import Loader from '../components/Loader.vue'
import authService from '../services/auth'
import VisitTimeLine from '../views/Patient/VisitTimeLine.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    VisitFilters,
    PatientFilters,
    PatientDetails,
    Loader,
    VisitTimeLine
  },

  data() {
    return {
      sidebarState: true,
      user: localStorage.getItem('user'),
      navigationHeight: 0,
      nfzLinks: [
        {
          label: 'Nowy raport',
          link: '/app/nfz/nowy-raport'
        },
        {
          label: 'Raporty NFZ',
          link: '/app/nfz/raporty'
        },
        {
          label: 'Szablony rozliczeniowe',
          link: '/app/nfz/szablony-rozliczeniowe'
        },
        {
          label: 'Faktury rozliczeniowe',
          link: '/app/nfz/faktury-rozliczeniowe'
        },
        {
          label: 'Umowa',
          link: '/app/nfz/umowa'
        },
        {
          label: 'Rejestr świadczeń',
          link: '/app/nfz/rejestr-swiadczen'
        },
        {
          label: 'Raporty zarzącze',
          link: '/app/nfz/raporty-zarzadcze'
        }
      ]
    }
  },
  computed: {
    navBarComponent() {
      const name = this.$route.name
      switch (name) {
        case 'dashboard.visits':
          return 'VisitFilters'
        case 'dashboard.patients':
          return 'PatientFilters'
        case 'dashboard.patient-visit':
          return 'PatientDetails'
        case 'dashboard.patient':
          return 'PatientDetails'
        default:
          return null
      }
    },
    navLinks() {
      const isSuperAdmin = authService.isSuperAdmin()
      let mainNavs = isSuperAdmin
        ? []
        : [
          {
            label: this.$t('menu.visits'),
            link: '/app/wizyty'
          },
          {
            label: this.$t('menu.patients'),
            link: '/app/pacjenci'
          }
        ]

      const optionalNavs = authService.isAdmin()
        ? [
          {
            label: this.$t('layout.adminOrganization'),
            link: '/app/organizacja'
          },
          {
            label: this.$t('layout.adminDoctors'),
            link: '/app/doktorzy'
          }
        ]
        : []
      return [...mainNavs, ...optionalNavs]
    },
    menuType() {
      return this.$route.name === 'dashboard.patient' ||
        this.$route.name === 'dashboard.patient-visit'
        ? 'timeline'
        : 'menu'
    },
    shortUser() {
      const splitted = this.user.split(' ')
      return `${splitted[0][0]}${splitted[1][0]}`
    }
  },
  mounted() {
    this.navigationHeight = this.$refs.navigation.clientHeight + 30
    window.addEventListener('resize', () => {
      this.navigationHeight = this.$refs.navigation.clientHeight + 30
    })
    const usg = localStorage.getItem('usg')
    if (!usg) {
      this.$refs.modal.show()
    } else {
      this.setUsgDevice(usg)
    }
    setInterval(
      () => {
        authService.refreshToken()
      },
      1000 * 60 * 5
    )
  },
  methods: {
    ...mapActions('Visit'),
    ...mapActions('Setting', ['setUsgDevice']),
    handleSetUsgDevice(device) {
      try {
        this.setUsgDevice(device)
        localStorage.setItem('usg', device)
        this.$refs.modal.hide()
      } catch (e) { }
    },
    logout() {
      authService.logout()
      this.$router.push('/login')
    },
    handleChangeUsg() {
      this.$bvModal.show('usg-modal')
    },
    toggleSidebar() {
      this.sidebarState = !this.sidebarState
    },
    isMenuSelected(path) {
      return path === this.$route.path
    },
    changeLanguage(language) {
      this.$i18n.locale = language
    }
  }
}
</script>
<style scoped lang="scss">
@import '../theme.scss';

$sidebarWidth: 300px;
$navbarHeight: 100px;

.main-wrapper {
  display: grid;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  grid-template-areas:
    'sidebar navbar'
    'sidebar main';
  align-content: start;
  align-items: start;
  grid-template-rows: auto 1fr;
  row-gap: 20px;
}

.sidebar {
  grid-area: sidebar;
  width: 20px;
  background-color: $primary;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  >* {
    display: none;
  }
}

.navigation {
  margin-top: 10px;
  position: relative;
  grid-area: navbar;
  width: calc(100vw);
  padding: 0 50px;
  align-items: center;
  display: grid;
  grid-template-columns: auto 80px;
}

.main {
  grid-area: main;
  width: calc(100vw);
  overflow: scroll;
}

.sidebar-open {
  .sidebar {
    width: $sidebarWidth;
    padding: 30px 20px;

    >* {
      display: block;
    }
  }

  .navigation,
  .main {
    width: calc(100vw - #{$sidebarWidth});
  }
}

.sidebar,
.navigation,
.main {
  transition: all 0.2s ease-out;
}

.nav-item {
  color: #fff !important;

  span {
    color: #fff;
  }
}

.nav-item-active {
  color: $primary;
  background-color: #fff;
}

.navigation-toggler {
  position: absolute;
  left: -20px;
  top: 15px;
}

.sidebar-home-link {
  text-align: center;
  margin-bottom: 30px;

  svg {
    width: 50px;
    height: 50px;
  }
}
</style>
