export default {
  getFilters: (state) => state.filters,
  getVisits: (state) => state.visits,
  getDiagnosisText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'diagnosis'),
  getInterviewText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'interview'),
  getRecommendationsText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'recommendations'),
  getCheckUpsText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'checkUps'),
  getUsgText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'usg'),
  getPreliminaryInterviewText: (state) =>
    state.texts.filter((el) => el.descriptionType === 'preliminaryInterview'),
  getVisitsCount: (state) => {
    const visitsCount = {
      wizyta: 0,
      telewizyta: 0,
      recepta: 0,
      total: 0
    }
    state.visits.rows.forEach((el) => {
      visitsCount.total++
      visitsCount[el.visitType]++
    })
    return visitsCount
  }
}
