import visitApi from '../../services/visits'
import lookups from '../../services/lookups'
import reformatVisitWithBlank from '../../Utils/reformatVisitWithBlank'

export default {
  async fetchVisits({ commit, state, dispatch }) {
    try {
      let { data } = await visitApi.get(state.filters)
      let freeSlots = await visitApi.getSlots(state.filters)
      if (freeSlots.data.length === 0) {
        freeSlots = [{ slot: '11:00', status: 'free' }]
      }
      const fdata = { ...data }
      try {
        const resultRows = reformatVisitWithBlank.getTimeZone(
          [...data.rows],
          freeSlots.data,
          state.filters
        )
        fdata.rows = resultRows
      } catch {}
      commit('setVisits', fdata)
    } catch (e) {
      throw new Error(e)
    }
  },
  async fetchCalendarSlots({ commit, state }) {
    try {
      let { data } = await visitApi.getCalendarSlotStatus(state.filters)
      if (data.length === 0) {
        data = [
          {
            date: '2023-05-04',
            reserved: 0,
            free: 0
          }
        ]
      }
      let freeSlots = data.filter((slots) => {
        return slots.free > 0
      })
      let reservedSlots = data.filter((slots) => {
        return slots.free === 0 && slots.reserved > 0
      })
      let freeSlotData = { dates: [] }
      let reservedSlotData = { dates: [] }
      freeSlots.map((slot, i) => {
        freeSlotData.dates.push(slot.date)
      })
      reservedSlots.map((slot, i) => {
        reservedSlotData.dates.push(slot.date)
      })
      const slotData = { ...data }
      slotData.freeSlots = freeSlotData
      slotData.reservedSlots = reservedSlotData
      commit('setCalendarSlots', slotData)
    } catch (e) {}
  },

  setFilters({ commit }, payload) {
    commit('setFilters', payload)
  },
  setPage({ commit }, payload) {
    commit('setPage', payload)
  },
  saveSavedVisitId({ commit }, payload) {
    commit('setSavedVisitId', payload)
  },
  async getVisitTexts({ commit }) {
    try {
      const { data } = await visitApi.getTexts()
      commit('setVisitTexts', data)
    } catch (e) {}
  },
  setUsgButton({ commit }, payload) {
    commit('setUsgButton', payload)
  },
  showUsgDescription({ commit }, payload) {
    commit('showUsgDescription', payload)
  },
  setModalPhotos({ commit }, payload) {
    commit('setModalPhotos', payload)
  },
  setTabIndex({ commit }, payload) {
    commit('setTabIndex', payload)
  },
  showCalendarTab({ commit }) {
    commit('setCalendarTab', true)
  },
  hideCalendarTab({ commit }) {
    commit('setCalendarTab', false)
  },
  setVisitProperty({ commit }, payload) {
    commit('setVisitProperty', payload)
  },
  setVisitData({ commit }, payload) {
    commit('setVisitData', payload)
  },
  resetVisitData({ commit }) {
    commit('setVisitData', {
      patientId: null,
      diagnosis: '',
      interview: '',
      recommendations: '',
      procedures: '',
      checkUps: '',
      controlVisit: '',
      services: [],
      conditions: '',
      status: false,
      controlDate: null
    })
  },

  updateVisit({ state }) {
    visitApi.put(state.currentVisit)
  },

  async getIcd10({ commit }) {
    try {
      const { data } = await lookups.getLookup('icd10')
      const icd10 = data.map((el) => ({
        name: el.nazwa,
        code: el.kod.toString()
      }))

      commit('setIcd10', icd10)
    } catch (error) {
      this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
    }
  },

  async getIcd9({ commit }) {
    try {
      const { data } = await lookups.getLookup('icd9')
      const icd9 = data.map((el) => ({
        name: el.nazwa,
        code: el.kod.toString()
      }))

      commit('setIcd9', icd9)
    } catch (error) {
      this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
    }
  }
}
