<template>
  <div>
    <b-form-select @change="addService($event)">
      <b-form-select-option
        v-for="el in leftServices"
        :key="el.name"
        :value="el"
        :label="el.name"
      ></b-form-select-option>
    </b-form-select>
    <div v-for="el in visitServices" :key="`services-${el.name}`">
      <b-row>
        <b-col cols="6">{{ el.name }}</b-col>
        <b-col cols="4"
          ><b-form-input
            :value="el.price"
            type="number"
            @input="handleChangePrice(el.serviceId, $event)"
        /></b-col>
        <b-col cols="2"
          ><b-button variant="danger" @click="removeService(el.serviceId)">{{
            $t('visit.remove')
          }}</b-button></b-col
        >
      </b-row>
    </div>
    <div>
      <h6>{{ $t('visit.price') }}: {{ cost }} PLN</h6>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import visitService from '@/services/visits'
export default {
  computed: {
    ...mapState('Patient', {
      services: (state) => state.services,
      visitServices: (state) => state.visitServices
    }),
    leftServices() {
      return this.services.filter(
        (el) => !this.visitServices.some((service) => service.name === el.name)
      )
    },
    cost() {
      return this.visitServices.reduce(
        (prev, curr) => prev + parseFloat(curr.price),
        0
      )
    }
  },

  methods: {
    ...mapActions('Patient', ['setVisitService', 'addVisitService']),
    async addService(data) {
      this.addVisitService(data)
      this.$emit('change', this.summary)
      const payload = {
        ...data,
        patientId: this.$route.params.id,
        visitId: this.$route.params.visit
      }
      await visitService.saveService(payload)
    },
    async removeService(serviceId) {
      this.setVisitService(
        this.visitServices.filter((el) => el.serviceId !== serviceId)
      )
      this.$emit('change', this.summary)
      const payload = {
        visitId: this.$route.params.visit,
        serviceId
      }
      await visitService.deleteService(payload)
    },
    handleChangePrice(id, event) {
      this.setVisitService(
        this.visitServices.map((el) => {
          if (id === el.serviceId) {
            this.updateService({
              visitId: this.$route.params.visit,
              serviceId: id,
              price: el.price
            })
            return {
              ...el,
              price: event
            }
          }
          return el
        })
      )
      this.$emit('change', this.summary)
    },
    async updateService(data) {
      const payload = {
        visitId: this.$route.params.visit,
        serviceId: data.serviceId,
        price: data.price
      }
      await visitService.updateService(payload)
    }
  }
}
</script>
