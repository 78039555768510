<template>
  <div>
    <div class="mb-3" v-if="isEpidCheckup.isEpid">
      <simple-collapse
        :title="`{{$t('visit.epidInterview')}}: ${
          isEpidCheckup.isPositive ? $t('visit.positive') : $t('visit.negative')
        }`"
        :is-open="collapse"
        @toggle="handleClickCollapse()"
      >
        <div v-html="data.description" />
      </simple-collapse>
    </div>
    <div class="mb-3" v-else>
      <p>
        {{ data.checkUpType }}
      </p>
      <div v-html="data.description"></div>
    </div>
    <div>
      <b-carousel
        controls
        indicators
        :interval="0"
        lazy
        v-if="filteredPhotoLinks.length > 0"
      >
        <div
          @click="toggleImage(data.photoLinks)"
          v-for="photo in filteredPhotoLinks"
          :key="photo.photoLink"
        >
          <b-carousel-slide>
            <template v-slot:img>
              <div style="display: flex; justify-content: center">
                <b-img-lazy
                  v-if="
                    photo &&
                    photo.photoLink &&
                    photo.photoLink.search('null') === -1
                  "
                  :src="photo.photoLink"
                  style="max-width: 100%; height: 300px"
                ></b-img-lazy>
                <div style="z-index: 2">
                  <div
                    v-for="el in photo.parameters"
                    :key="`${el.photoId}${el.id}`"
                  >
                    {{ el.name }}: {{ el.value }} {{ el.unit }}
                  </div>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </div>
      </b-carousel>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import SimpleCollapse from '../../../../components/collapse/Collapse'

export default {
  components: {
    SimpleCollapse
  },
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      showEpid: false,
      collapse: false
    }
  },
  computed: {
    filteredPhotoLinks() {
      return this.data.photoLinks.filter(
        (el) => el.photoLink.search('null') === -1
      )
    },
    isEpidCheckup() {
      return {
        isEpid: this.data.checkUpType === 'Wywiad Epidemiologiczny',
        isPositive:
          this.data.description &&
          this.data.description.search('pozytywny') > -1
      }
    }
  },
  mounted() {
    if (this.isEpidCheckup.isPositive) {
      this.collapse = true
    }
  },
  methods: {
    ...mapActions('Visit', ['setModalPhotos']),
    toggleImage(links) {
      this.setModalPhotos(links)
      this.$bvModal.show('images-modal')
    },
    handleShowEpid() {
      this.showEpid = true
    },
    handleClickCollapse() {
      this.collapse = !this.collapse
    }
  }
}
</script>
<style scoped>
div {
  white-space: pre-wrap;
}
</style>
