<template>
  <div class="collapse-wrapper">
    <b-row>
      <b-col cols="12" @click="toggle()">
        <h5>
          {{ title
          }}
          <b-button pill size="sm" v-if="iconName" v-b-modal="'diag-modal'"><font-awesome-icon :icon="iconName"></font-awesome-icon></b-button>
          <font-awesome-icon
            v-if="isOpen && showDownIcon"
            class="float-right"
            icon="chevron-up"
          />
          <font-awesome-icon class="float-right" v-if="!isOpen && showDownIcon" icon="chevron-down" />
        </h5>
      </b-col>
      <b-col cols="12">
        <b-collapse v-model="isOpen">
          <div>
            <slot />
          </div>
        </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    isOpen: {
      required: true,
      type: Boolean
    },
    iconName: {
      required: false,
      type: String
    },
    showDownIcon: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 25px;
  font-weight: 700;
}
.collapse-wrapper {
  margin: 10px 0;
}
</style>
