import axios from './index'

export default {
  get(params) {
    return axios.get('/api/auth/visits', { params })
  },
  getSlots(params) {
    return axios.get('/api/auth/visits/avail-slots', { params })
  },
  getCalendarSlotStatus({ startDate, endDate }) {
    return axios.get(
      `/api/auth/visits/doctor-avail-status?start=${startDate}&end=${endDate}`
    )
  },
  getSingle(visitId, patientId) {
    return axios.get(`/api/auth/visits/${visitId}/${patientId}`)
  },
  getSinglePlanned(visitId) {
    return axios.get(`/api/auth/planned-visits/${visitId}`)
  },
  save(body) {
    return axios.post('/api/auth/visits', body)
  },
  put(body) {
    return axios.put('/api/auth/visits', body)
  },
  getPdf(visitId, format, patientId) {
    return axios.post(
      '/api/auth/visits-description',
      { visitId, format, patientId },
      {
        responseType: 'blob'
      }
    )
  },
  sendEmail(visitId, patientId) {
    return axios.post('/api/auth/visit-email', { visitId, patientId })
  },
  setVisitStatus({ patientId, visitId, status }) {
    return axios.put('/api/auth/visit-status', { patientId, visitId, status })
  },
  setControlVisit({ patientId, visitId, date }) {
    return axios.put('/api/auth/control-visit', { patientId, visitId, date })
  },
  sendNextDayVisit(payload) {
    return axios.post('/api/auth/visit-next-date', payload)
  },
  deletePlannedVisit(id) {
    return axios.delete(`/api/auth/visit/${id}`)
  },
  setPlannedVisit(payload) {
    return axios.post('/')
  },
  setUsgDescription(payload) {
    return axios.post('/api/auth/visit-usg-description', payload)
  },
  updateCheckupDescription(id, description) {
    return axios.put(`/api/auth/checkups/${id}/description`, { description })
  },
  saveService(payload) {
    return axios.post(`/api/auth/visit/${payload.visitId}/service`, payload)
  },
  updateService(payload) {
    return axios.put(
      `/api/auth/visit/${payload.visitId}/service/${payload.serviceId}`,
      payload
    )
  },
  deleteService(payload) {
    return axios.delete(
      `/api/auth/visit/${payload.visitId}/service/${payload.serviceId}`
    )
  },
  updateVisitType(visitId, visitType) {
    return axios.put(`/api/auth/visit/${visitId}/type`, {
      visitType: visitType
    })
  },
  getIcd(search) {
    return axios.get(`/api/auth/visit/icd`, {
      params: {
        search
      }
    })
  },
  getAllIcd() {
    return axios.get(`/api/auth/visit/icd-all`)
  },
  sendReferral(payload) {
    return axios.post('/api/auth/referral', payload)
  },
  summarizeSpeech(payload) {
    return axios.post('/api/auth/aiservices', payload)
  },
  postponeVisit(id, date) {
    return axios.put(`/api/auth/visit-plan/${id}`, { date })
  },
  getTexts() {
    return axios.get('/api/auth/visit-texts')
  }
}
