<template>
  <b-row>
    <b-card style="width: 100%">
      <b-row v-if="$route.params.visit && patient">
        <b-col> </b-col>
      </b-row>
      <b-row v-if="patient">
        <b-col cols="9">
          <b-row>
            <b-col
              cols="12"
              :md="6"
              v-if="$route.params.visit"
            >
              <h5 v-if="date">{{ $t('visit.visit') }} {{ date }}</h5>
            </b-col>
            <b-col
              cols="12"
              :md="6"
            >
              <div
                class="patient-wrapper"
                @click="openModal()"
                :class="{
                  'alert-color': !isPatientValid
                }"
              >
                {{ patient.firstName }} {{ patient.lastName }},
                {{ patient.age }} lat <template v-if="patient.months && patient.age < 18"> {{ patient.months % 12 }}
                  miesięcy</template>, {{
                    patient.pesel }},<br />
                {{ patient.address }}, {{ patient.postalCode }}
                {{ patient.city }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <!-- <b-row style="justify-content: right">
            <b-button-group style="margin-bottom: 5px">
              <b-button variant="primary" @click="setPatientJustify('left')"
                ><font-awesome-icon icon="align-left" />
              </b-button>
              <b-button variant="success" @click="setPatientJustify('center')"
                ><font-awesome-icon icon="align-justify" />
              </b-button>
              <b-button @click="setPatientJustify('right')"
                ><font-awesome-icon icon="align-right"
              /></b-button>
            </b-button-group>
          </b-row> -->
        </b-col>
      </b-row>
    </b-card>
    <modal
      :data="patient"
      v-if="patient"
      @submit="updatePatient($event)"
      modal-id="patient-modal"
    />
  </b-row>
</template>
<script>
import visit from '../../services/visits'
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import patientService from '@/services/patients'
import Modal from './Modal'
export default {
  components: {
    Modal
  },
  computed: {
    ...mapState('Patient', {
      patient: (state) => state.patient
    }),
    ...mapGetters('Patient', ['isPatientValid']),
    date () {
      return `${moment(this.visit.dateStart).format(
        'YYYY-MM-DD HH:mm'
      )} - ${moment(this.visit.dateEnd).format('HH:mm')}`
    }
  },
  data () {
    return {
      visit: {
        dateStart: '',
        dateEnd: ''
      },
      collapse: false
    }
  },
  beforeMount () {
    this.getVisit()
  },
  methods: {
    ...mapActions('Setting', ['setPatientJustify']),
    ...mapActions('Patient', [
      'getPatient',
      'setCheckUpsChange',
      'setPatientData'
    ]),
    openModal () {
      this.$bvModal.show('patient-modal')
    },
    async updatePatient (patient) {
      try {
        const { data } = await patientService.updatePatient(patient)
        this.setPatientData(data)
        this.$swal('Pacjent', 'Pacjent został zaktualizowany', 'success')
      } catch (e) {
        this.$swal('Pacjent', 'Błąd aktualizacji pacjenta', 'error')
      }
    },
    async getVisit () {
      try {
        const id = this.$route.params.visit
        if (id) {
          const { data } = await visit.getSinglePlanned(id)
          this.visit = data
        }
      } catch (e) { }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../theme.scss';

.patient-wrapper {
  cursor: pointer;
  color: $primary;
}

.alert-color {
  color: red
}
</style>
