import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  authUser: {
    auth: false,
    authType: false,
    user: {}
  },
  patientJustify: { expand: 'center' },
  usg: ''
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
