<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-table
          :fields="fields"
          :items="patients.rows"
          @row-clicked="handleClickRow"
          hover
        >
          <template v-slot:cell(patient)="data">
            <div>
              {{ data.item.lastName }} {{ data.item.firstName }},
              {{ getAge(data.item.birthDate) }} lat <template
                v-if="getAge(data.item.birthDate) < 18 && data.item.months"
              >
                {{
                  data.item.months % 12 }}
                miesięcy</template>
            </div>
          </template>
          <template v-slot:cell(nextVisitDate)="data">
            <div style="text-align: center">
              {{ data.value | formatDateDay }}
            </div>
          </template>
          <template v-slot:cell(lastVisitDate)="data">
            <div style="text-align: center">
              {{ data.value | formatDateDay }}
            </div>
          </template>
          <template v-slot:cell(pesel)="data">
            <div style="text-align: center">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <div style="text-align: center">
              <b-button
                @click.stop="startVisit(data.item.id)"
                variant="outline"
                :title="$t('patientList.makeVisit')"
              ><font-awesome-icon icon="stethoscope" /></b-button>
              <b-button
                @click.stop="showPlannedVisit(data.item)"
                variant="outline"
                :title="$t('patientList.planVisit')"
              >
                <font-awesome-icon icon="calendar-plus" /></b-button>
              <b-button
                @click.stop="goToPatient(data.item)"
                variant="outline"
                :title="$t('patientList.openHistory')"
              >
                <font-awesome-icon icon="search-plus" />
              </b-button>
              <b-button
                @click.stop="openModal(data.item)"
                variant="outline"
                :title="$t('patientList.editPatient')"
              >
                <font-awesome-icon icon="edit" />
              </b-button>
              <b-button
                @click="setPhoneNumber(data.item.phone)"
                variant="outline"
                :title="$t('patientList.showContact')"
                v-b-modal.contact-modal
              >
                <font-awesome-icon icon="phone-square-alt" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <modal
      :data="editedPatient"
      modal-id="edit-patient"
      @submit="updatePatient($event)"
      @visit="updatePatientAndStartVisit($event)"
      @visit-date="updatePatientAndSetVisit($event)"
    />
    <b-modal id="contact-modal">
      <a :href="`phone:${phoneNumber}`">{{ phoneNumber }}</a>
    </b-modal>
    <calendar-modal :patient-id="selectedPatient" />
  </b-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import patientService from '@/services/patients'
import visitService from '@/services/visits'
import moment from 'moment'
import CalendarModal from './CalendarModal.vue'
import Modal from './Modal.vue'

export default {
  components: { CalendarModal, Modal },
  data () {
    return {
      editedPatient: {
        sex: 'M',
        nfz: 1
      },
      addedPatient: {
        sex: 'M',
        nfz: 1
      },
      phoneNumber: '',
      selectedPatient: ''
    }
  },
  computed: {
    ...mapState('Patient', {
      patients: (state) => state.patients,
      filters: (state) => state.filters
    }),
    fields () {
      return [
        {
          key: 'patient',
          label: this.$t('patientList.patient')
        },
        {
          key: 'lastVisitDate',
          label: this.$t('patientList.previousVisit'),
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        },
        {
          key: 'nextVisitDate',
          label: this.$t('patientList.nextVisit'),
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        },
        {
          key: 'pesel',
          label: 'PESEL',
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        },
        {
          key: 'id',
          label: this.$t('patientList.actions'),
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        }
      ]
    }
  },
  methods: {
    ...mapActions('Patient', [
      'setFilters',
      'fetchPatients',
      'setPlannedPatientId'
    ]),
    setFiltersState (prop, value) {
      this.setFilters({ prop, value })
    },
    openModal (patient) {
      this.editedPatient = JSON.parse(JSON.stringify(patient))
      this.$bvModal.show('edit-patient')
    },
    async updatePatient (patient) {
      try {
        await patientService.updatePatient(patient)
        this.fetchPatients()
        this.$swal(
          'Zaktualizowano pacjenta',
          'Pomyślnie zaktualzowano pacjenta',
          'success'
        )
      } catch (e) {
        this.$swal(this.$t('error'), 'Błąd aktualizacji użytkownika', 'error')
      }
    },
    async updatePatientAndStartVisit (patient) {
      await this.updatePatient(patient)
      await this.startVisit(patient.id)
    },
    async updatePatientAndSetVisit (patient) {
      await this.updatePatient(patient)
      this.setPlannedPatientId(patient.id)
      this.$bvModal.show('calendar-modal')
    },
    async showPlannedVisit (patient) {
      this.setPlannedPatientId(patient.id)
      this.$bvModal.show('calendar-modal')
    },
    async startVisit (patientId) {
      const start = moment().set({ minute: 0 }).format('YYYY-MM-DD HH:mm')
      const end = moment()
        .set({ minute: 0 })
        .add(30, 'minutes')
        .format('YYYY-MM-DD HH:mm')
      const { data } = await visitService.sendNextDayVisit({
        patientId,
        start,
        end,
        adhoc: true
      })
      this.$router.push(
        `/app/pacjenci/${patientId}/wizyta/${data.visitId}?start=true`
      )
    },
    goToPatient (item) {
      this.$router.push(`/app/pacjenci/${item.id}`)
    },
    handleClickRow (item) {
      this.startVisit(item.id)
    },
    getAge (date) {
      return date ? moment().diff(date, 'years') : ''
    },
    setPhoneNumber (number) {
      this.phoneNumber = number
    }
  }
}
</script>
<style scoped>
.row {
  cursor: pointer;
}

.btn:hover {
  background: #089bab;
}
</style>
