<template>
  <b-container fluid>
    <b-row class="justify-content-center align-content-center">
      <router-view />
    </b-row>
    <div class="language-container">
      <b-button @click="changeLocale('pl')">PL</b-button>
      <b-button @click="changeLocale('en')">Eng</b-button>
    </div>
  </b-container>
</template>
<script>
export default {
  methods: {
    changeLocale(language) {
      this.$i18n.locale = language
    }
  }
}
</script>
<style lang="scss">
.language-container {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
