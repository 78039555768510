<template>
  <b-row>
    <b-col v-for="(el, i) in data" :key="`procedures-data-${i}`">
      {{ el.code }} {{ el.text }}
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    data: {
      type: Array
    }
  }
}
</script>
