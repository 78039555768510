<template>
  <div class="referral">
    <b-modal
      id="referral-cancel-modal"
      :title="$t('visit.referralCancelTitleDescription')"
      size="md"
      @hide="$emit('close')"
    >
      <div class="">
        <b>{{ $t('visit.removeReferralConfirmation') }}</b> <br />
        {{ $t('visit.removeReferralInfo') }}
      </div>
      <template #modal-footer>
        <b-button
          @click="$bvModal.hide('referral-cancel-modal')"
          class="btn-sm m-1"
          >{{ $t('close') }}</b-button
        >
        <b-button
          @click="handleCancelConfirmed(referralData.id)"
          :disabled="loading ? '' : disabled"
          class="btn btn-danger btn-sm m-1"
          >{{ referralData.btnCaption }}</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="referral-info-modal"
      :title="$t('visit.referralTitleDescription')"
      size="xl"
      @hide="$emit('close')"
    >
      <center>
        <b-spinner label="Spinning" v-if="loaded == false"></b-spinner>
      </center>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          id="frP1"
          class="embed-responsive-item"
          v-if="loaded"
          :src="iframe.srcdoc"
          type="application/pdf"
          frameborder="0"
        ></iframe>
      </div>
      <template #modal-footer>
        <b-button @click="$bvModal.hide('referral-info-modal')">{{
          $t('close')
        }}</b-button>
      </template>
    </b-modal>
    <div v-for="el in referrals" :key="`referral-${el.id}`">
      <div style="margin-top: 5px; margin-right: 6px">
        <b-button
          v-if="el.cancel_date == null"
          variant="danger"
          @click="handleCancel(el.id)"
          class="mr-1 float-right"
        >
          <font-awesome-icon icon="hospital" title="Cancel Referral" />
        </b-button>
        <b-button
          variant="primary"
          @click="handleViewDetails(el.id)"
          class="mr-1 float-right"
        >
          <font-awesome-icon icon="hospital" title="Download Referral" />
        </b-button>
        <span v-if="el.cancel_date != null" class="ml-2 mt-3 float-left"
          ><i
            ><b>{{ $t('visit.closed') }}: </b> :
            {{ new Date(el.cancel_date * 1000).toLocaleString('pl-PL') }}</i
          ></span
        >
      </div>
      <div style="clear: both; margin: 0px"></div>
      <Single
        v-if="el.cancel_date != null"
        style="
          background: #eee;
          border: 1px dashed;
          border-style: dashed;
          border-color: #dc3545;
          color: #dc3545;
          margin-top: 4px;
        "
        :data="el"
        category="canceledReferral"
        :class="`visit-${el.visitId}`"
      />
      <Single
        v-else
        :data="el"
        category="referral"
        :class="`visit-${el.visitId}`"
      />
    </div>
  </div>
</template>
<script>
import referralApi from '../../../../services/referralApi'
import Single from './Single'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      loaded: false,
      loading: false,
      referralData: {
        title: ''
      },
      iframe: {
        style: null,
        wrapperStyle: null,
        srcdoc: null
      }
    }
  },
  components: {
    Single
  },
  computed: {
    ...mapState('Patient', {
      referrals: (state) => state.referrals
    })
  },
  methods: {
    async handleDownload(referralId) {
      // let response = await prescriptionApi.downloadPrescription(referralId)
      //
      // let blob = new Blob([response.data], { type: 'application/pdf' })
      // this.iframe.srcdoc=window.URL.createObjectURL(blob)
      // let link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = `prescription_${referralId}.pdf`
      // link.click()
      // link.remove()
    },
    async handleCancelConfirmed(referralId) {
      try {
        this.loading = true
        this.referralData.btnCaption = 'Usuń...'
        await referralApi.cancelReferral(referralId)
        this.loading = false
        // alert('Skierowania skutecznie usunięta')
        this.$bvModal.hide('referral-cancel-modal')
        this.$swal(
          this.$t('success'),
          this.$t('visit.referralCancelSuccess'),
          'success'
        )
      } catch (ex) {
        this.$bvModal.hide('referral-cancel-modal')
        this.$swal(
          this.$t('error'),
          this.$t('visit.referralCancelError'),
          'error'
        )
      }
    },
    async handleCancel(referralId) {
      this.loading = false
      this.$bvModal.show('referral-cancel-modal')
      this.referralData = {
        id: referralId,
        btnCaption: 'Usuń',
        title: 'Ok: „Potwierdzenie usunięcia referral”'
      }
    },
    async handleViewDetails(referralId) {
      try {
        this.$bvModal.show('referral-info-modal')
        this.iframe.srcdoc = null
        this.loading = true
        this.loaded = false
        this.referralData = {
          id: referralId,
          title: 'szczegóły skierowania - ' + referralId
        }
        this.iframe.style = {
          position: 'absolute',
          width: '90%',
          height: '100%'
        }
        // let response = await prescriptionApi.getPrescriptionDetails(referralId)
        // let html = response.data.prescriptionHtmlContent
        // this.iframe.srcdoc = html // 'data:text/html;charset=utf-8,' + encodeURI(html)
        let response = await referralApi.downloadReferral(referralId)
        let blob = new Blob([response.data], { type: 'application/pdf' })
        this.iframe.srcdoc = window.URL.createObjectURL(blob)
        this.iframe.wrapperStyle = {
          overflow: 'hidden',
          height: '100%'
        }
        this.loaded = true
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(response.data)
        }, 2000)
        setTimeout(
          () => document.getElementById('frP1').contentWindow.print(),
          3000
        )
        this.loading = false
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.getReferralError'), 'error')
        this.$bvModal.hide('referral-info-modal')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
