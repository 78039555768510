<template>
  <div class="wrapper">
    <b-button @click.stop="goToVisit(data.item)" variant="outline" :title="$t('visitsList.makeVisit')"
      :disabled="data.item.disabled"><font-awesome-icon icon="stethoscope" /></b-button>
    <b-button @click.stop="setVisit(data.item)" variant="outline" :title="$t('visitsList.planVisit')"
      :disabled="data.item.disabled"><font-awesome-icon icon="calendar-plus" /></b-button>
    <b-button @click.stop="goToPatient(data.item)" variant="outline" :title="$t('visitsList.openPatientHistory')"
      :disabled="data.item.disabled">
      <font-awesome-icon icon="search-plus" />
    </b-button> <b-button @click.stop="deleteVisit(data.item)" variant="danger"
      :disabled="data.item.disabled"><font-awesome-icon icon="trash" /></b-button>

    <font-awesome-icon @click.stop=" handleClickOpen(data.item)"
      :icon="open ? 'angle-double-left' : 'angle-double-right'" :class="{ disabled: data.item.disabled }"
      :disabled="data.item.disabled" />
    <div v-if="open">
      <b-button @click.stop="editPatient(data.item)" variant="outline" :title="$t('visitsList.editPatient')"
        :disabled="data.item.disabled"><font-awesome-icon icon="edit" /></b-button>
      <b-button @click.stop="setPhoneNumber(data.item.phone)" variant="outline"
        :title="$t('visitsList.seePatientContact')" :disabled="data.item.disabled" v-b-modal.contact-modal>
        <font-awesome-icon icon="phone-square-alt" />
      </b-button>
      <b-button @click.stop="handleChangeVisitType(data.item.id)" variant="outline"
        :title="$t('visitsList.editVisitType')" :disabled="data.item.disabled" v-b-modal.visit-type-modal>
        <font-awesome-icon icon="exchange-alt" />
      </b-button>
      <b-button @click.stop="handleMoveVisit(data.item.id)" variant="outline" :title="$t('visitsList.moveVisit')"
        :disabled="data.item.disabled">
        <font-awesome-icon icon="arrow-right" />
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    handleClickOpen(data) {
      if (data['disabled']) {
        return
      }
      this.open = !this.open
    },
    goToVisit(data) {
      this.$emit('go-to-visit', data)
    },
    setVisit(data) {
      this.$emit('set-visit', data)
    },
    goToPatient(data) {
      this.$emit('go-to-patient', data)
    },
    editPatient(data) {
      this.$emit('edit-patient', data)
    },
    setPhoneNumber(data) {
      this.$emit('set-phone-number', data)
    },
    handleChangeVisitType(data) {
      this.$emit('change-visit-type', data)
    },
    deleteVisit(data) {
      this.$emit('delete', data)
    },
    handleMoveVisit(id) {
      this.$emit('move', id)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.65;
}
</style>
