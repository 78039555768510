<template>
  <b-row>
    <b-col cols="12">
      <b-button v-b-modal.addServiceModal>Dodaj usługę</b-button>
    </b-col>
    <b-col>
      <b-table :items="items" :fields="fields" striped responsive="sm">
      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
         <font-awesome-icon icon="arrow-up" v-if="row.detailsShowing"></font-awesome-icon>
         <font-awesome-icon icon="arrow-down" v-else></font-awesome-icon>
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button-group>
          <b-button @click="handleUpdateService(row.item.id, row.item)" v-b-modal.updateServiceModal variant="primary">
            <font-awesome-icon icon="edit"></font-awesome-icon>
          </b-button>
          <b-button @click="handleDeleteService(row.item.id)" variant="danger">
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-button-group>
      </template>

      <template #row-details="row">
        <b-button v-b-modal.addDoctorServiceModal @click="handleAddDoctorService(row.item.id)">{{ $t('organization.addDoctorService') }}</b-button>
        <b-table :items="row.item.items" :fields="doctorFields">
          <template #cell(actions)="subrow">
            <b-button-group>
              <b-button @click="handleUpdateDoctorService(subrow.item)" variant="primary" v-b-modal.updateDoctorServiceModal>
                <font-awesome-icon icon="edit"></font-awesome-icon>
              </b-button>
              <b-button @click="handleDeleteDoctorService(subrow.item.id)" variant="danger">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </template>
    </b-table>
    </b-col>
    <service-modal ref="addServiceModal" custom-id="addServiceModal" @submit="handleSubmitAddService"></service-modal>
    <service-modal ref="updateServiceModal" custom-id="updateServiceModal" @submit="handleSubmitUpdateService"></service-modal>
    <doctor-service-modal ref="addDoctorServiceModal" custom-id="addDoctorServiceModal" @submit="handleSubmitAddDoctorService"></doctor-service-modal>
    <doctor-service-modal ref="updateDoctorServiceModal" custom-id="updateDoctorServiceModal" @submit="handleSubmitUpdateDoctorService"></doctor-service-modal>
  </b-row>
</template>
<script>
import services from '../../../services/services'
import ServiceModal from './ServiceModal.vue'
import DoctorServiceModal from './DoctorServiceModal.vue'

export default {
  components: {
    ServiceModal,
    DoctorServiceModal
  },
  computed: {
    doctorId() {
      return this.$route.params.id
    },
    servicesOption() {
      return this.items.map(el => ({
        text: el.name,
        value: el.id
      }))
    }
  },
  data() {
    return {
      items: [],
      serviceToAdd: 0,
      fields: [
        {
          key: 'show_details',
          label: this.$t('')
        },
        {
          key: 'name',
          label: this.$t('organization.name')
        },
        {
          key: 'isHidden',
          label: this.$t('organization.isHidden')
        },
        {
          key: 'price',
          label: this.$t('organization.price')
        },
        {
          key: 'dateFrom',
          label: this.$t('organization.dateFrom')
        },
        {
          key: 'dateTo',
          label: this.$t('organization.dateTo')
        },
        {
          key: 'wwwName',
          label: this.$t('organization.wwwName')
        },
        {
          key: 'wwwIsHidden',
          label: this.$t('organization.wwwIsHidden')
        },
        {
          key: 'actions',
          label: this.$t('organization.actions')
        }
      ],
      doctorFields: [
        {
          key: 'price',
          label: this.$t('organization.price')
        },
        {
          key: 'dateFrom',
          label: this.$t('organization.dateFrom')
        },
        {
          key: 'dateTo',
          label: this.$t('organization.dateTo')
        },
        {
          key: 'actions',
          label: this.$t('organization.actions')
        }
      ],
      updateService: {
        id: 0
      },
      addDoctorService: {
        serviceId: 0
      },
      updateDoctorService: {
        id: 0
      }
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const servicesData = await services.getServices()
        const doctorServices = await services.getDoctorServices(this.doctorId)
        this.items = servicesData.data.map(el => ({
          ...el,
          items: doctorServices.data.filter(service => service.serviceId === el.id)
        }))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('doctorPage.servicesGetError'), 'error')
      }
    },
    handleUpdateService(id, payload) {
      this.updateService = {
        id
      }
      this.$refs.updateServiceModal.setData(payload)
    },
    handleAddDoctorService(serviceId) {
      this.addDoctorService = {
        serviceId
      }
    },
    handleUpdateDoctorService(payload) {
      const { id, ...form } = payload
      this.updateDoctorService = {
        id
      }
      this.$refs.updateDoctorServiceModal.setData(form)
    },

    async handleSubmitAddService(payload) {
      try {
        await services.addService(payload)
        this.$bvModal.hide('addServiceModal')
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.addServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.addServiceError'), 'error')
      }
    },
    async handleSubmitAddDoctorService(payload) {
      try {
        await services.addDoctorService({
          ...payload,
          serviceId: this.addDoctorService.serviceId,
          doctorId: this.doctorId
        })
        this.$bvModal.hide('addDoctorServiceModal')
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.updateServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.uptadeServiceError'), 'error')
      }
    },
    async handleSubmitUpdateService(payload) {
      try {
        await services.updateService(this.updateService.id, payload)
        this.$bvModal.hide('updateServiceModal')
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.updateServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.updateServiceError'), 'error')
      }
    },
    async handleSubmitUpdateDoctorService(payload) {
      try {
        await services.updateDoctorService(this.updateDoctorService.id, payload)
        this.$bvModal.hide('updateDoctorServiceModal')
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.removeServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.removeServiceError'), 'error')
      }
    },
    async handleDeleteService(payload) {
      try {
        await services.deleteService(payload)
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.removeServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.removeServiceError'), 'error')
      }
    },
    async handleDeleteDoctorService(payload) {
      try {
        await services.deleteDoctorService(payload)
        this.getData()
        this.$swal(this.$t('success'), this.$t('organization.removeServiceSuccess'))
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('organization.removeServiceError'), 'error')
      }
    }
  }
}
</script>
