<template>

  <div
    class="vue-speech-recognition"
    :class="{
      error: error,
      active: isRecording
    }"
    @click="start"
  >
    <span>

    </span>
    <!-- <hr/>
    <hr/>
    <button
        @click="toggleRecording"
        class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {{ isRecording ? 'Stop Recording' : 'Record' }}
      </button> -->
  </div>
</template>

<script>

export default {
  name: 'RealTimeVoiceCapture',
  props: {
    lang: {
      type: String
    },
    deviceId: {
      type: String
    }
  },
  data: () => ({
    mediaRecorder: {
    },
    gladiaKey: '225e470c-57fc-4f62-aedc-bbbd29a7a8d2',
    devices: [],
    selectedDevice: '',
    audioStream: null,
    socket: null,
    SAMPLE_RATE: 48000,
    showResult: false,
    finals: {},
    partials: '',
    mediaStream: {},
    recorder: {},
    totalAudioSize: 0,
    error: null,
    isRecording: false,
    audioChunks: [],
    scriptsLoaded: {
      assemblyai: false,
      recordRTC: false
    },

    token: null // AssemblyAI token,

  }),
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  created() {
    this.audioChunks.push([])
  },

  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInRecording()) {
      next(false)
    } else {
      next()
    }
  },
  async mounted() {
    // Automatically load the script and initialize AssemblyAI
    try {
      await this.loadDependencies()
      console.log('Dependencies loaded and initialized successfully.')
    } catch (error) {
      console.error('Error during initialization:', error)
    }

    // this.listAudioDevices()
  },
  methods: {

    async listAudioDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.devices = devices.filter((d) => d.kind === 'audioinput')
        if (!this.devices.length) alert('No audio input devices found.')
      } catch (err) {
        console.error('Error listing audio devices:', err)
      }
    },
    async initiateSession() {
      const response = await fetch(`https://api.gladia.io/v2/live`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-GLADIA-KEY': this.gladiaKey
        },
        body: JSON.stringify({ sample_rate: this.SAMPLE_RATE })
      })
      if (!response.ok) throw new Error(`${response.status}: ${await response.text()}`)
      return response.json()
    },
    async startRecording() {
      try {
        const { url } = await this.initiateSession()
        // this.isRecording = true
        this.showResult = true
        this.finals = {}
        this.partials = '...'

        this.audioStream = await navigator.mediaDevices.getUserMedia({
          audio: this.deviceId ? { deviceId: { exact: this.deviceId } } : true
          // audio: true
        })

        this.recorder = new window.RecordRTC(this.audioStream, {
          type: 'audio',
          mimeType: 'audio/wav',
          recorderType: window.StereoAudioRecorder,
          timeSlice: 1000,
          ondataavailable: async (blob) => {
            const buffer = await blob.arrayBuffer()
            this.socket.send(buffer.slice(44)) // Remove WAV header
          },
          // timeSlice: 250,
          // desiredSampRate: 16000,
          // numberOfAudioChannels: 1,
          // bufferSize: 16384,
          // audioBitsPerSecond: 128000
          sampleRate: this.SAMPLE_RATE,
          desiredSampRate: this.SAMPLE_RATE,
          numberOfAudioChannels: 1
        })

        this.socket = new WebSocket(url)

        // socket.onopen = () => {
        //     // Check https://docs.gladia.io/api-reference/live-flow for more information about the parameters
        //     const configuration = {
        //       x_gladia_key: gladiaKey,
        //       frames_format: 'bytes',
        //       language_behaviour: 'automatic single language',

        //   "language_config": {
        //     "languages": [],
        //     "code_switching": false
        //   }

        //       sample_rate: SAMPLE_RATE
        //     };
        //     socket.send(JSON.stringify(configuration));
        //   };

        this.socket.onmessage = (event) => {
          const message = JSON.parse(event.data)
          console.log(message.data.utterance)
          if (message?.type === 'transcript') {
            if (message.data.is_final) {
              this.finals[message.data.utterance.start * 1000] = message.data.utterance.text
              this.partials = ''
              console.log(this.finals)
              this.$emit('transcriptionText', JSON.stringify(this.finals))
            } else {
              this.partials = message.data.utterance.text
            }
          }
          // console.log(event.data)
        }

        this.recorder.startRecording()
      } catch (err) {
        console.error('Error starting recording:', err)
        this.stopRecording()
      }
    },
    stopRecording() {
      // this.isRecording = false

      console.log('Stopping...')
      // eslint-disable-next-line no-unused-expressions
      this.recorder?.destroy()
      // eslint-disable-next-line no-unused-expressions
      this.audioStream?.getTracks().forEach((track) => track.stop())
      // eslint-disable-next-line no-unused-expressions
      this.socket?.close()
      this.$emit('stopped')
    },

    start() {
      this.toggleRecording()
      // if (this.isRecording) {
      //   this.stopRecording()
      //   return
      // }
      // this.startRecording()
    },
    loadScript(url, key) {
      return new Promise((resolve, reject) => {
        if (this.scriptsLoaded[key]) {
          resolve() // Script already loaded
          return
        }
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = () => {
          this.scriptsLoaded[key] = true
          console.log(`${key} script loaded successfully.`)
          resolve()
        }
        script.onerror = () =>
          reject(new Error(`Failed to load ${key} script.`))
        document.head.appendChild(script)
      })
    },
    async loadDependencies() {
      try {
        // Load AssemblyAI and RecordRTC scripts dynamically

        await this.loadScript(
          'https://www.WebRTC-Experiment.com/RecordRTC.js',
          'recordRTC'
        )
      } catch (error) {
        console.error('Error loading dependencies:', error)
        throw error // Ensure initialization halts if a dependency fails
      }
    },

    async toggleRecording() {
      if (this.isRecording) {
        await this.stopRecording()
      } else {
        await this.startRecording()
      }
      this.isRecording = !this.isRecording
    }

  }
}
</script>

<style lang="scss">
.vue-speech-recognition {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

.vue-speech-recognition-mini {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  }

  to {
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    transform: scale(0.9);
  }
}
</style>
