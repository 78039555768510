<template>
  <b-container>
    <h2>
      <b-button variant="outline" @click="handleClickBack()"
        ><font-awesome-icon icon="arrow-left"
      /></b-button>
      {{ header }}
      <b-button @click="handleAction()" variant="primary">{{
        $t('organization.save')
      }}</b-button>
    </h2>
    <b-tabs v-if="doctor" fill>
      <b-tab :title="$t('organization.doctorSettings')">
        <doctor ref="doctor" :page-type="this.pageType" />
      </b-tab>
      <b-tab :title="$t('organization.description')">
        <description ref="description"/>
      </b-tab>
      <b-tab
        :title="$t('organization.workHours')"
        v-if="this.pageType === 'update'"
      >
        <hours :id="$route.params.id" ref="hours" />
      </b-tab>
      <b-tab
        :title="$t('organization.services')"
        v-if="this.pageType === 'update'"
      >
        <services ref="services" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import doctorService from '../../../services/doctors'

import Doctor from './Doctor.vue'
import Hours from './Hours.vue'
import Description from './Description.vue'
import Services from './Services.vue'

export default {
  components: {
    Doctor,
    Hours,
    Description,
    Services
  },
  props: {
    pageType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      doctor: {}
    }
  },
  computed: {
    header() {
      return this.pageType === 'add'
        ? 'Dodaj doktora'
        : this.doctor
          ? `Aktualizuj doktora ${this.doctor.firstName} ${this.doctor.lastName}`
          : ''
    }
  },
  beforeMount() {
    if (this.pageType === 'update') {
      this.getDoctor()
      this.getHours()
    }
  },
  methods: {
    async getDoctor() {
      try {
        const id = this.$route.params.id
        const { data } = await doctorService.getSingle(id)
        const {
          specializations,
          specializationsEng,
          phone,
          aboutMe,
          awards,
          nfzDescription,
          treatedDiseases,
          experience,
          paymentMethods,
          kindOfPatients,
          ...rest
        } = data
        this.doctor = data
        this.$refs.doctor.setDoctor(rest)
        this.$refs.description.setData({
          specializations,
          specializationsEng,
          phone,
          aboutMe,
          awards,
          nfzDescription,
          treatedDiseases,
          experience,
          paymentMethods,
          kindOfPatients
        })
      } catch (error) {
        this.$swal(this.$t('error'), 'Błąd w pobraniu doktora', 'error')
      }
    },

    async getHours() {
      try {
        const id = this.$route.params.id
        const { data } = await doctorService.getHours(id)
        let parsedData = { ...data }

        for (const key in parsedData) {
          if (Array.isArray(parsedData[key])) {
            parsedData = {
              ...parsedData,
              [key]: {
                from: '',
                to: ''
              }
            }
          }
        }
        this.$refs.hours.setHours(parsedData)
      } catch (error) {
        this.$swal(this.$t('error'), 'Błąd w pobraniu godzin przyjęć', 'error')
      }
    },
    handleClickBack() {
      this.$router.push('/app/doktorzy')
    },

    async handleAction() {
      try {
        const id = this.$route.params.id
        const doctorData = await this.$refs.doctor.getDoctor()
        const descriptionData = await this.$refs.description.getData()
        switch (this.pageType) {
          case 'update':
            const hoursData = await this.$refs.hours.getHours()
            await doctorService.update({
              ...doctorData,
              ...descriptionData
            })
            await doctorService.updateHours(id, hoursData)
            break
          case 'add':
            await doctorService.create({
              ...doctorData,
              ...descriptionData
            })
            break
        }
        this.$router.push('/app/doktorzy')
        this.$swal('Akcja zakończona powodzeniem')
      } catch (error) {
        this.$swal(this.$t('error'), 'Błąd przy zapisywaniu lekarza', 'error')
      }
    }
  }
}
</script>
