<template>
  <b-card class="visit-card">
    <span style="position:absolute;right:-10px;top:15px;">
      <b-icon-arrow-right-circle-fill class="circle-arrow" v-b-tooltip.hover title="Click for wide view" v-if= "!justify.firstBlockfullView" @click="toggleFirstBlock()"></b-icon-arrow-right-circle-fill>
      <b-icon-arrow-left-circle-fill class="circle-arrow" v-b-tooltip.hover title="Exit wide view" v-if= "justify.firstBlockfullView" @click="toggleFirstBlock()"></b-icon-arrow-left-circle-fill>
    </span>
    <b-tabs
      :value="tabIndex"
      @input="handleChangeTabIndex"
      lazy
      @changed="handleChangeTabs"
    >
      <b-tab :title="$t('visit.visitsAndCheckups')" class="tab"
        ><Visits id="visit-body"
      /></b-tab>
      <b-tab :title="$t('visit.checkUps')" class="tab" v-if="getAllCheckups"
        ><CheckUps class="visit-body"
      /></b-tab>
      <b-tab
        :title="$t('visit.prescriptions')"
        class="tab"
        v-if="prescriptions.length"
        ><Prescriptions class="visit-body"
        @copy="handleCopyPrescription"
      /></b-tab>
      <b-tab :title="$t('visit.interview')" class="tab" v-if="interview"
        ><interview :data="interview" class="visit-body"
      /></b-tab>
      <b-tab :title="$t('visit.calendar')" v-if="showCalendarTab"
        ><Calendar
          class="visit-body"
          :dates="visitDates"
          @change="handleClickCalendarDate($event)"
      /></b-tab>
      <b-tab :title="$t('visit.referral')" v-if="referrals">
        <referrals class="visit-body" />
      </b-tab>
      <!-- <b-tab :title="$t('visit.icd10')" v-if="showIcdTab"
        ><Icd class="visit-body"
      /></b-tab> -->
      <b-tab v-if="procedures" :title="$t('visit.procedures')"
        ><procedures :data="procedures"
      /></b-tab>
    </b-tabs>
    <images-modal />
  </b-card>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Visits from './Visits'
import CheckUps from './ChekUps'
import Prescriptions from './Prescriptions'
import Calendar from './Calendar'
import Interview from './Interview.vue'
import ImagesModal from './ImagesModal'
// import Icd from './Icd.vue'
import Referrals from './Referrals'
import Procedures from './Procedures.vue'
export default {
  components: {
    Visits,
    CheckUps,
    Prescriptions,
    Calendar,
    Interview,
    ImagesModal,
    // Icd,
    Referrals,
    Procedures
  },
  computed: {
    ...mapState('Setting', {
      justify: (state) => state.patientJustify
    }),
    ...mapGetters('Patient', ['getVisitsAndCheckUps', 'getAllCheckups']),
    ...mapState('Patient', {
      visitDates: (state) => state.visitDates,
      checkUps: (state) => state.checkUps,
      checkUpsChange: (state) => state.checkUpsChange,
      interview: (state) => (state.patient ? state.patient.interview : ''),
      prescriptions: (state) => state.prescriptions,
      referrals: (state) => state.referrals,
      procedures: (state) => state.procedures
    }),
    ...mapState('Visit', {
      tabIndex: (state) => state.tabIndex,
      showCalendarTab: (state) => state.showCalendarTab,
      showIcdTab: (state) => state.showIcdTab
    }),
    currentTabCount() {
      const tabLength = document.querySelectorAll('.tab').length
      return tabLength
    }
  },
  methods: {
    ...mapActions('Setting', ['setPatientJustify']),
    ...mapActions('Patient', ['setVisitDate']),
    ...mapActions('Visit', ['setTabIndex', 'hideCalendarTab']),
    async handleClickCalendarDate(event) {
      try {
        await this.setVisitDate(event)
        this.$swal('Sukces', 'Pomyślnie zaplanowano wizytę', 'success')
        this.hideCalendarTab()
      } catch (error) {
        this.$swal('Błąd', 'Błąd przy planowaniu wizyty kontrolnej', 'error')
      }
    },
    handleChangeTabs() {
      if (this.showCalendarTab) {
        this.setTabIndex(this.currentTabCount)
      }
    },
    handleChangeTabIndex(index) {
      this.setTabIndex(index)
    },
    toggleFirstBlock() {
      this.justify.firstBlockfullView = !this.justify.firstBlockfullView
      if (this.justify.firstBlockfullView) {
        this.setPatientJustify({ expand: 'left', firstBlockTransition: true, secondBlockTransition: false, secondBlockfullView: true, firstBlockfullView: true, firstBlockOrder: 1, secondBlockOrder: 2 })
      } else {
        this.setPatientJustify({ expand: 'center', firstBlockTransition: true, secondBlockTransition: false, secondBlockfullView: false, firstBlockfullView: false, firstBlockOrder: 1, secondBlockOrder: 2 })
      }
    },
    handleCopyPrescription(id) {
      this.$emit('copy-prescription', id)
    }
  }
}
</script>
<style lang="scss">
.visit-body {
  overflow-y: scroll;
  height: calc(100vh - 260px);
}
.circle-arrow:hover{
  fill:#6c757d;
}
.circle-arrow{
  fill:#808080cf;
  cursor:pointer;
  font-size:25px;
}
</style>
