<template>
  <b-modal id="referral-modal" size="lg" hide-footer>
    <b-form @submit.prevent="handleSubmitReferral()">
      <b-form-group label="Typ skierowania">
        <b-form-radio-group
          id="referral-type"
          v-model="referralType"
          name="referral-type"
          :options="types"
        >
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        v-if="showPasswordInput && selectedComponent"
        :label="$t('visit.certPassword')"
      >
        <b-form-input type="password" v-model="password"></b-form-input>
      </b-form-group>
      <b-form-group label="CITO" v-if="selectedComponent">
        <b-form-checkbox v-model="isCito"></b-form-checkbox>
      </b-form-group>
      <component
        :is="selectedComponent"
        ref="referralComponent"
      />
      <b-row>
        <b-col style="text-align: right">
          <b-button size="lg" type="submit" variant="primary">{{
            $t('send')
          }}</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import Hospital from './Hospital.vue'
import Diagnosis from './Diagnosis.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Hospital,
    Diagnosis
  },
  data() {
    return {
      types: [
        {
          text: 'Skierowanie do szpitala',
          value: 'hospital'
        },
        // {
        //   text: 'Skierowanie do poradni specjalistycznej',
        //   value: 'specialist'
        // },
        {
          text: 'Skierowanie do pracowni diagnostycznej',
          value: 'diagnosis'
        }
        // {
        //   text: 'Skierowanie do zakładu opiekuńczo-leczniczego',
        //   value: 'careTreatment'
        // },
        // {
        //   text: 'Skierowanie na rehabilitację',
        //   value: 'rehabilitation'
        // },
        // {
        //   text: 'Skierowanie do hospicjum',
        //   value: 'hospice'
        // },
        // {
        //   text: 'Skierowanie na szczepienie',
        //   value: 'vaccination'
        // }
      ],
      referralType: '',
      labs: [],
      password: '',
      isCito: false
    }
  },
  computed: {
    selectedComponent() {
      switch (this.referralType) {
        case 'hospital':
          return 'Hospital'
        case 'diagnosis':
          return 'Diagnosis'
        default:
          return null
      }
    },
    ...mapState('Patient', {
      showPasswordInput: (state) => !state.hasCertPassword
    })
  },
  methods: {
    handleSubmitReferral() {
      const data = this.$refs.referralComponent.payload
      this.$emit('ok', {
        ...data,
        paragraphs: data.paragraphs.map((el) => ({
          content: el.content,
          title: el.title
        })),
        referralType: this.referralType,
        password: this.password,
        isCito: this.isCito
      })
      this.$bvModal.hide('referral-modal')
    }
  }
}
</script>
