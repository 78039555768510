<template>
  <b-modal id="images-modal" size="xl" hide-footer>
    <div>
      <b-carousel controls indicators :interval="0" lazy>
        <div v-for="photo in photoLinks" :key="photo.photoLink">
          <b-carousel-slide>
            <template v-slot:img>
              <div style="display: flex; justify-content: center">
                <b-img-lazy
                  v-if="photo && photo.photoLink"
                  :src="photo.photoLink"
                  style="width: 90%"
                ></b-img-lazy>
                <div style="z-index: 2">
                  <div
                    v-for="el in photo.parameters"
                    :key="`${el.photoId}${el.id}`"
                  >
                    {{ el.name }}: {{ el.value }} {{ el.unit }}
                  </div>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </div>
      </b-carousel>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('Visit', {
      photoLinks: 'modalPhotos'
    })
  }
}
</script>
