import axios from './index'

export default {
  getLookup(name, q) {
    return axios.get('/api/auth/lookups/values', {
      params: {
        for: name,
        q
      }
    })
  },

  copy() {
    return axios.get('/api/lookups/copy-to-db')
  }
}
