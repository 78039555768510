<template>
  <b-container fluid>
    <h2>
      {{ $t('organization.organizationEdit') }}
      <b-button @click="handleUpdate()">{{
        $t('organization.organizationEdit')
      }}</b-button>
    </h2>
    <b-row>
      <b-form>
        <b-form-group :label="$t('organization.name')" label-for="name">
          <b-form-input
            id="name"
            v-model="organization.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="REGON" label-for="regon">
          <b-form-input
            id="regon"
            v-model="organization.regon"
            required
          ></b-form-input> </b-form-group
        ><b-form-group
          :label="$t('organization.externalId')"
          label-for="externalId"
        >
          <b-form-input
            id="externalId"
            v-model="organization.externalId"
            required
          ></b-form-input> </b-form-group
        ><b-form-group
          :label="$t('organization.internalId')"
          label-for="internalId"
        >
          <b-form-input
            id="internalId"
            v-model="organization.internalId"
            required
          ></b-form-input> </b-form-group
        ><b-form-group
          :label="$t('organization.unitCode')"
          label-for="unitCode"
        >
          <b-form-input
            id="unitCode"
            v-model="organization.unitCode"
            required
          ></b-form-input> </b-form-group
        ><b-form-group :label="$t('organization.phone')" label-for="phone">
          <b-form-input
            id="phone"
            v-model="organization.phone"
            required
          ></b-form-input> </b-form-group
        ><b-form-group :label="$t('organization.city')" label-for="city">
          <b-form-input
            id="city"
            v-model="organization.city"
            required
          ></b-form-input> </b-form-group
        ><b-form-group :label="$t('organization.street')" label-for="street">
          <b-form-input
            id="street"
            v-model="organization.street"
            required
          ></b-form-input> </b-form-group
        ><b-form-group :label="$t('organization.houseNr')" label-for="houseNr">
          <b-form-input
            id="houseNr"
            v-model="organization.houseNr"
            required
          ></b-form-input> </b-form-group
        ><b-form-group
          :label="$t('organization.apartmentNr')"
          label-for="apartmentNr"
        >
          <b-form-input
            id="apartmentNr"
            v-model="organization.apartmentNr"
          ></b-form-input> </b-form-group
        ><b-form-group
          :label="$t('organization.postalCode')"
          label-for="postalCode"
        >
          <b-form-input
            id="postalCode"
            v-model="organization.postalCode"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('organization.simc')" label-for="simc">
          <b-form-input
            id="simc"
            v-model="organization.simc"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('organization.rwpdlCode')"
          label-for="rwpdl_kod"
        >
          <b-form-input
            id="rwpdl_kod"
            v-model="organization.rwpdl_kod"
            :options="rwpdlOptions"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <b-form>
        <h4>{{ $t('organization.tlsCert') }}</h4>
        <b-form-file
          v-model="tlsCertificate"
          name="certificate"
          :placeholder="$t('organization.tlsPlaceholder')"
        />
        <b-form-group :label="$t('labels.password')" label-for="tlsPassword">
          <b-form-input v-model="tlsPassword" id="tlsPassword"
        /></b-form-group>
        <h4>{{ $t('organization.wssCert') }}</h4>
        <b-form-file
          v-model="wssCertificate"
          name="certificate"
          required
          :placeholder="$t('organization.wssPlaceholder')"
        />
        <b-form-group :label="$t('labels.password')" label-for="wssPassword">
          <b-form-input id="wssPassword" v-model="wssPassword" required
        /></b-form-group>
        <b-button @click="handleSendCertificates()">{{
          $t('organization.saveCert')
        }}</b-button>
      </b-form>
    </b-row>
  </b-container>
</template>
<script>
import organizationService from '../../services/organization'

export default {
  data() {
    return {
      organization: {
        externalId: '',
        internalId: '',
        id: '',
        unitId: '',
        unitCode: '',
        postalCode: '',
        name: '',
        city: '',
        street: '',
        houseNr: '',
        apartmentNr: '',
        type: '',
        regon: '',
        phone: '',
        rwpdl_kod: ''
      },
      tlsCertificate: null,
      tlsPassword: '',
      wssCertificate: null,
      wssPassword: '',
      rwpdlOptions: [
        {
          text: 'Okręgowa Izba Lekarska w Białymstoku',
          value: '50'
        },
        {
          text: 'Okręgowa Izba Lekarska w Bielsku-Białej',
          value: '51'
        },
        {
          text: 'Okręgowa Izba Lekarska w Bydgoszczy',
          value: '52'
        },
        {
          text: 'Okręgowa Izba Lekarska w Gdańsku',
          value: '53'
        },
        {
          text: 'Okręgowa Izba Lekarska w Gorzowie Wielkopolskim',
          value: '54'
        },
        {
          text: 'Okręgowa Izba Lekarska w Katowicach',
          value: '55'
        },
        {
          text: 'Okręgowa Izba Lekarska w Kielcach',
          value: '56'
        },
        {
          text: 'Okręgowa Izba Lekarska w Krakowie',
          value: '57'
        },
        {
          text: 'Okręgowa Izba Lekarska w Lublinie',
          value: '58'
        },
        {
          text: 'Okręgowa Izba Lekarska w Łodzi',
          value: '59'
        },
        {
          text: 'Okręgowa Izba Lekarska w Olsztynie',
          value: '60'
        },
        {
          text: 'Okręgowa Izba Lekarska w Opolu',
          value: '61'
        },
        {
          text: 'Okręgowa Izba Lekarska w Płocku',
          value: '62'
        },
        {
          text: 'Okręgowa Izba Lekarska w Poznaniu',
          value: '63'
        },
        {
          text: 'Okręgowa Izba Lekarska w Rzeszowie',
          value: '64'
        },
        {
          text: 'Okręgowa Izba Lekarska w Szczecinie',
          value: '65'
        },
        {
          text: 'Okręgowa Izba Lekarska w Tarnowie',
          value: '66'
        },
        {
          text: 'Okręgowa Izba Lekarska w Toruniu',
          value: '67'
        },
        {
          text: 'Okręgowa Izba Lekarska w Warszawie',
          value: '68'
        },
        {
          text: 'Okręgowa Izba Lekarska we Wrocławiu',
          value: '69'
        },
        {
          text: 'Okręgowa Izba Lekarska w Zielonej Górze',
          value: '70'
        },
        {
          text: 'Wojskowa Izba Lekarska',
          value: '71'
        },
        {
          text: 'Okręgowa Izba Lekarska w Koszalinie',
          value: '72'
        },
        {
          text: 'Okręgowa Izba Lekarska w Częstochowie',
          value: '73'
        }
      ]
    }
  },

  async beforeMount() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        const { data } = await organizationService.getOrganization()
        this.organization = data
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('organization.getOrganizationError'),
          'error'
        )
      }
    },
    async handleUpdate() {
      try {
        await organizationService.update(this.organization)
        this.$swal(
          this.$t('success'),
          this.$t('organization.updateOrganizationSuccess'),
          'success'
        )
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('organization.updateOrganizationError'),
          'error'
        )
      }
    },

    async handleSendCertificates() {
      await this.handleSendTlsCertificate()
      await this.handleSendWssCertificate()
      await organizationService.updateCertificatePassword({
        tlsPassword: this.tlsPassword,
        wssPassword: this.wssPassword
      })
      this.$swal(this.$t('success'), 'Przesłano poprawnie certyfikaty')

      this.wssCertificate = null
      this.wssPassword = ''
      this.tlsPassword = ''
      this.tlsCertificate = null
    },
    async handleSendTlsCertificate() {
      try {
        if (this.tlsCertificate && this.tlsPassword) {
          await organizationService.sendFile(this.tlsCertificate, 'tls')
        }
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('organization.updateCertsError'),
          'error'
        )
      }
    },
    async handleSendWssCertificate() {
      try {
        if (this.wssCertificate && this.wssPassword) {
          await organizationService.sendFile(this.wssCertificate, 'wss')
        }
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('organization.updateCertsError'),
          'error'
        )
      }
    }
  }
}
</script>
<style scoped>
form {
  width: 100%;
  padding: 20px;
}
</style>
