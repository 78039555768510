<template>
  <b-modal
    :visible="open"
    @hide="$emit('close')"
    size="xl"
    scrollable
    @ok="sendPrescription($event)"
  >
    <template v-slot:modal-title>
      <div>
        <h5>{{ $t('visit.addPrescription') }}</h5>
      </div>
    </template>
    <b-row>
      <b-col
        md="6"
        sm="12"
        style="overflow-y: scroll; height: calc(100vh - 250px)"
      >
        <b-row if="patient">
          <b-col cols="12">
            <table class="prescription-table">
              <tr>
                <td>{{ $t('visit.addPatient') }}</td>
                <td>
                  {{ patient.firstName }}
                  {{ patient.lastName }}
                </td>
              </tr>
              <tr>
                <td>PESEL</td>
                <td>{{ patient.pesel }}</td>
              </tr>
              <tr>
                <td>{{ $t('visit.address') }}</td>
                <td>
                  {{ patient.address }}
                  <br />
                  {{ patient.city }},
                </td>
              </tr>
              <tr>
                <td>Teryt:</td>
                <td>
                  <span v-if="patient.teryt">{{ patient.teryt }}</span
                  ><b-form-input
                    v-model="prescription.teryt"
                    :state="prescription.teryt"
                    v-else
                  />
                </td>
              </tr>

              <tr>
                <td>{{ $t('visit.isInsuranced') }}?</td>
                <td :style="{ color: patient.ewus === 1 ? 'green' : 'red' }">
                  {{ patient.ewus === 1 ? 'Tak' : 'Nie' }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('visit.prescription365') }}</td>
                <td>
                  <b-form-checkbox
                    v-model="prescription.is365"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr v-if="!prescription.is365">
                <td>{{ $t('visit.validDateFrom') }}</td>
                <td>
                  <b-form-datepicker
                    :start-weekday="1"
                    :min="today"
                    v-model="prescription.dateFrom"
                    :placeholder="$t('visit.dateFrom')"
                  ></b-form-datepicker>
                </td>
              </tr>
              <tr>
                <td>{{ $t('visit.prescriptionType') }}</td>
                <td>
                  <b-form-select
                    v-model="prescription.prescriptionType"
                    :options="prescriptionTypes"
                  ></b-form-select>
                </td>
              </tr>
              <tr v-if="showPasswordInput">
                <td>{{ $t('visit.certPassword') }}</td>
                <td>
                  <b-form-input
                    type="password"
                    v-model="prescription.password"
                  ></b-form-input>
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="12">
            <span class="font-weight-bold">{{ $t('visit.drugs') }}</span>
            <div
              class="medicine-card"
              v-for="(item, i) in prescription.medicaments"
              :key="`med${i}`"
            >
              <span class="font-weight-bold"> {{ item.medicineName }}, </span>
              {{ item.manufacturer }}<br />
              {{ item.packageSize }} {{ item.unit }}<br />
              <b-form-group :label="$t('visit.quantity')">
                <b-form-radio-group
                  :label="$t('visit.quantity')"
                  v-model="item.quantity"
                >
                  <b-form-radio
                    v-for="el in quantity"
                    :key="'quantity' + el"
                    :value="el"
                    >{{ el }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
              <b-form-group :label="$t('visit.suggestedDose')">
                <b-form-input v-model="item.dose" />
                <b-form-radio-group
                  :label="$t('visit.suggestedDose')"
                  v-model="item.dose"
                >
                  <b-form-radio
                    v-for="el in doses"
                    :key="item.ean + el"
                    :value="el"
                    >{{ el }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Taksacja">
                <b-form-radio-group label="Taksacja" v-model="item.payment">
                  <b-form-radio
                    v-for="el in item.taksacja"
                    :key="item.ean + el"
                    :value="getPaymentValue(el)"
                    >{{ el.refundation }}
                    <span v-if="el.description"> - {{ el.description }}</span
                    ><span v-if="el.age">
                      - {{ el.age.toLowerCase() }}
                      {{ $t('visit.yearOld') }}</span
                    ></b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
              <b-form-group :label="$t('visit.notReplace')">
                <b-form-checkbox v-model="item.notReplace"></b-form-checkbox>
              </b-form-group>
              <b-button variant="danger" @click="deleteMedicine(i)">{{
                $t('visit.remove')
              }}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6" sm="12">
        <h4>{{ $t('visit.addDrugs') }}</h4>
        <b-row>
          <b-col>
            <b-form-group :label="$t('visit.searchDrugs')">
              <b-form-input
                v-model="filters.search"
                @update="searchMedicines()"
              ></b-form-input>
              <b-spinner label="Spinning" v-if="loadingMedicines"></b-spinner>
            </b-form-group>
            <span style="display: inline-block"
              >{{ $t('visit.onlyRefunded')
              }}<b-form-checkbox v-model="withPayment"></b-form-checkbox
            ></span>
            <div style="overflow-y: scroll; height: calc(100vh - 350px)">
              <div
                v-for="(item, i) in groupedMedicaments.priority"
                :key="`medicine${i}`"
                class="medicine-card"
              >
                <span class="font-weight-bold"> {{ item.medicineName }}, </span>
                {{ item.manufacturer }}<br />
                <span>{{ $t('visit.dose') }}: {{ item.strength }}</span
                ><br />
                <span
                  >{{ $t('visit.package') }}: {{ item.packageSize }}
                  {{ item.unit }}</span
                ><br />
                <span>{{ $t('visit.price') }}: {{ item.price }} PLN</span><br />
                <span
                  >{{ $t('visit.availableRefundations') }}:
                  <ul class="taxation-list">
                    <li
                      v-for="el in item.taksacja"
                      :key="`${item.ean}-taks-${el.id}`"
                    >
                      {{ el.refundation }}
                      <span v-if="el.description"> - {{ el.description }}</span
                      ><span v-if="el.age">
                        - {{ el.age.toLowerCase() }}
                        {{ $t('visit.yearOld') }}</span
                      >
                    </li>
                  </ul>
                </span>
                <span v-if="item.strong" style="color: red">{{ $t('visit.strongMedicamentAlert') }}</span><br />
                <span
                  >{{ $t('visit.activeSubstances') }}:
                  <ul class="taxation-list">
                    <li
                      v-for="el in item.activeSubstances"
                      :key="`${item.name}-as-${el.value}`"
                    >
                      {{ el.name }} {{ el.value }} {{ el.unit }}
                    </li>
                  </ul>
                </span><br />
                <b-button
                  variant="success"
                  v-if="!item.strong || (item.strong && prescription.medicaments.length === 0) || !isAnyStrongMedicament"
                  @click="addMedicine(item)"
                  :disabled="prescription.medicaments.length >= 5"
                  >{{ $t('visit.addDrug') }}</b-button
                >
              </div>
              <b-button
                v-if="
                  groupedMedicaments.priority.length > 0 && !showAllMedicaments
                "
                @click="handleShowAllMedicaments()"
                >{{ $t('visit.showAllDrugs') }}</b-button
              >
              <div
                v-if="
                  (groupedMedicaments.priority.length > 0 &&
                    showAllMedicaments) ||
                  groupedMedicaments.priority.length === 0
                "
              >
                <div
                  v-for="(item, i) in groupedMedicaments.rest"
                  :key="`medicine${i}`"
                  class="medicine-card"
                >
                  <span class="font-weight-bold">
                    {{ item.medicineName }},
                  </span>
                  {{ item.manufacturer }}<br />
                  <span>{{ $t('visit.dose') }}: {{ item.strength }}</span
                  ><br />
                  <span
                    >{{ $t('visit.package') }}: {{ item.packageSize }}
                    {{ item.unit }}</span
                  ><br />
                  <span>{{ $t('visit.price') }}: {{ item.price }} PLN</span
                  ><br />
                  <span
                    >{{ $t('visit.availableRefundations') }}:
                    <ul class="taxation-list">
                      <li
                        v-for="el in item.taksacja"
                        :key="`${item.ean}-taks-${el.id}`"
                      >
                        {{ el.refundation }}
                        <span v-if="el.description">
                          - {{ el.description }}</span
                        ><span v-if="el.age">
                          - {{ el.age.toLowerCase() }}
                          {{ $t('visit.yearOld') }}</span
                        >
                      </li>
                    </ul>
                  </span>
                  <span v-if="item.strong" style="color: red">{{ $t('visit.strongMedicamentAlert') }}</span><br />
                <span
                  >{{ $t('visit.activeSubstances') }}:
                  <ul class="taxation-list">
                    <li
                      v-for="el in item.activeSubstances"
                      :key="`${item.name}-as-${el.value}`"
                    >
                      {{ el.name }} {{ el.value }} {{ el.unit }}
                    </li>
                  </ul>
                </span><br />
                  <b-button
                    v-if="!item.strong || (item.strong && prescription.medicaments.length === 0) || !isAnyStrongMedicament"
                    variant="success"
                    @click="addMedicine(item)"
                    :disabled="prescription.medicaments.length >= 5"
                    >{{ $t('visit.addDrug') }}</b-button
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import moment from 'moment'
import medicineApi from '../../../services/medicines'
import prescriptionApi from '../../../services/prescriptionApi'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      filters: {
        search: ''
      },
      prescriptionTypes: [
        {
          value: 'Rp',
          text: 'Rp'
        },
        { value: 'Rpw', text: 'Rpw' }
      ],
      doses: ['1x1', '2x1', '3x1'],
      quantity: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      loadingMedicines: false,
      medicines: [],
      fullYearPrescription: false,
      prescription: {
        medicaments: [],
        is365: false,
        prescriptionType: 'Rp',
        password: ''
      },
      prescriptionModel: {
        medicaments: [],
        is365: false,
        prescriptionType: 'Rp',
        password: '',
        dateFrom: '',
        dateTo: ''
      },
      withPayment: false,
      today: moment().format('YYYY-MM-DD'),
      showAllMedicaments: false
    }
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('Patient', {
      patient: (state) => state.patient,
      showPasswordInput: (state) => !state.hasCertPassword
    }),
    ...mapState('Visit', {
      savedVisitId: (state) => state.savedVisitId
    }),
    filteredMedicaments() {
      return this.withPayment
        ? this.medicines.filter((el) => el.taksacja.length > 1)
        : this.medicines
    },
    isTeryt() {
      return this.patient.teryt || this.prescription.teryt
    },
    isAnyStrongMedicament() {
      return this.prescription.medicaments.some(el => el.strong)
    },
    groupedMedicaments() {
      return this.filteredMedicaments.reduce(
        (prev, curr) => {
          if (curr.prio) {
            prev.priority.push(curr)
          } else {
            prev.rest.push(curr)
          }
          return prev
        },
        { priority: [], rest: [] }
      )
    }
  },
  mounted() {},
  methods: {
    async searchMedicines() {
      try {
        this.loadingMedicines = true
        this.showAllMedicaments = false
        const { data } = await medicineApi.get(this.filters)
        this.medicines = data.medicines
      } catch (e) {
      } finally {
        this.loadingMedicines = false
      }
    },
    resetData() {
      this.prescription = this.prescriptionModel
    },
    addMedicine(item) {
      this.prescription.medicaments.push({
        ...item,
        dose: '1x1',
        quantity: '1',
        payment: '100%',
        notReplace: false
      })
    },
    deleteMedicine(i) {
      this.prescription.medicaments.splice(i, 1)
    },
    getDate() {
      return moment().format('YYYY-MM-DD HH:mm')
    },
    handleShowAllMedicaments() {
      this.showAllMedicaments = true
    },
    async copyPrescription(prescriptionId) {
      try {
        const data = await prescriptionApi.getPrescriptionCopy(prescriptionId)
        this.prescription = {
          ...this.prescription,
          ...data
        }
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.copyPrescriptionError'), 'error')
      }
    },
    sendPrescription(e) {
      try {
        if (this.isTeryt) {
          this.$emit('add', {
            ...this.prescription,
            visitId: this.$route.params.visit,
            patientId: this.patient.id
          })
          this.resetData()
        } else {
          e.preventDefault()
        }
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.addPrescriptionError'),
          'error'
        )
      }
    },
    getPaymentValue(payment) {
      if (payment.refundation === '100%') {
        return payment.refundation
      }
      return `${payment.refundation}:${payment.id}`
    }
  }
}
</script>
<style lang="scss">
.medicine-card {
  border: 1px solid #bbb;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}
.taxation-list {
  list-style-type: disc;
}
</style>
