import moment from 'moment'
export default {
  setPatients(state, payload) {
    state.patients = payload
  },
  setVisits(state, payload) {
    state.visits = payload
    if (payload.length) {
      state.selectedVisit = {
        id: payload[0].id,
        selected: true,
        title: `${payload[0].drFirstName[0]}${payload[0].drLastName[0]}`,
        date: moment(payload[0].date).format('YYYY-MM-DD'),
        description: payload[0].description,
        checkUps: payload[0].checkUps
      }
    } else {
      state.selectedVisit = null
    }
  },
  setCalendarSlots(state, payload) {
    state.calendarSlots = payload
  },
  setVisitVisibility(state, { isVisible, id, type }) {
    state[`${type}s`] = state.visits.map((el) => {
      if (el.id === id) {
        el.isVisible = isVisible
      }
      return el
    })
  },
  setPatient(state, payload) {
    state.patient = payload
  },
  setSelectedVisit(state, payload) {
    state.selectedVisit = payload
  },
  setSelectedVisitByVisible(state) {
    state.selectedVisit =
      state.visits.find((el) => el.isVisible) ||
      state.checkUps.find((el) => el.isVisible)
  },
  setFilters(state, payload) {
    state.filters[payload.prop] = payload.value
  },
  setCheckUps(state, payload) {
    state.checkUps = payload
  },
  setPrescriptions(state, payload) {
    state.prescriptions = payload
  },
  setVisitDates(state, payload) {
    state.visitDates = payload
  },
  setVisitDate(state, payload) {
    state.selectedVisitDate = payload
  },
  setVisitCheckups(state, payload) {
    state.visitCheckups = payload
  },
  setCheckUpsChange(state) {
    state.checkUpsChange = !state.checkUpsChange
  },
  setServices(state, payload) {
    state.services = payload
  },
  setVisitServices(state, payload) {
    state.visitServices = payload
  },
  addVisitService(state, payload) {
    state.visitServices.push(payload)
  },
  setPlannedPatientId(state, payload) {
    state.plannedPatientId = payload
  },
  setHasCertPassword(state, payload) {
    state.hasCertPassword = payload
  },
  setReferrals(state, payload) {
    state.referrals = payload
  },
  setProcedures(state, payload) {
    state.procedures = payload
  }
}
