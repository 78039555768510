<template>
  <div class="visit-body">
    <Single
      v-for="(el, i) in getVisitsAndCheckUps"
      :key="i"
      :id="`${el.type}-${el.data.id}`"
      :category="el.type"
      :data="el.data"
    />
  </div>
</template>
<script>
import Single from './Single'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Single
  },
  computed: {
    ...mapGetters('Patient', ['getVisitsAndCheckUps'])
  },
  methods: {
    ...mapActions('Patient', ['setSelectedVisit', 'setVisitVisibility']),
    handleScroll(isVisible, el) {
      const id = el.target.id.split('-')
      this.setVisitVisibility({ id: id[0], type: id[1], isVisible })
    }
  }
}
</script>
