import moment from 'moment'

export default {
  getPatients: (state) => state.patients,
  getPatient: (state) => state.patient,
  getVisits: (state) => state.visits,
  getSelectedVisit: (state) => state.selectedVisit,
  isPatientValid: (state) => {
    if (state.patient) {
      const requireKeys = [
        'firstName',
        'lastName',
        'pesel',
        'sex',
        'nfz',
        'postalCode',
        'city',
        'address'
      ]
      for (const key in state.patient) {
        if (requireKeys.includes(key) && !state.patient[key]) {
          return false
        }
      }
      return true
    }
    return false
  },
  getVisitsToTimeLine: (state, getters) => {
    let visits = getters.getVisitsAndCheckUps
    visits = visits
      .filter((el) => el.data.drFirstName && el.data.drLastName)
      .map((el) => ({
        type: el.type,
        id: el.data.id,
        selected:
          state.selectedVisit &&
          state.selectedVisit.id &&
          el.data.id &&
          state.selectedVisit.id === el.data.id &&
          state.selectedVisit.type === el.type,
        title:
          el.type === 'visit'
            ? `${el.data.drFirstName[0] || ''}${el.data.drLastName[0] || ''}`
            : el.data.checkUpType,
        date: moment(el.data.date).format('YYYY-MM-DD'),
        description: el.data.description,
        checkUps: el.data.checkUps
      }))
    return visits
  },
  getVisitsAndCheckUps: (state) => {
    const visits = state.visits.map((el) => ({
      type: 'visit',
      data: el
    }))
    const checkUps = state.checkUps.map((el) => ({
      type: 'checkUp',
      data: el
    }))
    const data = [...visits, ...checkUps].sort(
      (a, b) => new Date(b.data.date) - new Date(a.data.date)
    )
    return data
  },
  getAllCheckups: (state) =>
    [
      ...state.checkUps,
      ...state.visits.reduce((prev, curr) => [...prev, ...curr.checkUps], [])
    ].sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
}
