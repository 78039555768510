import axios from './index'

export default {
  sendEvent(patientId, visitId) {
    return axios.post('/api/auth/events', {
      patientId,
      visitId
    })
  },
  getAsssemblyAIToken() {
    return axios.get('/api/auth/token')
  }
}
