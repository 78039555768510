export const englishTranslations = {
  login: {
    login: 'Logging',
    badCredentials: 'Bad credential data',
    loginButton: 'Log in',
    sendCode: 'Send code',
    forgotPassword: 'Forgot password',
    code: 'Code'
  },
  labels: {
    email: 'Email',
    password: 'Password'
  },
  menu: {
    visits: 'Visits',
    patients: 'Patients'
  },
  error: 'Error',
  success: 'Success',
  chooseUsg: 'Choose USG device',
  forgotPassword: {
    haveCode: 'I have the code already',
    sendButton: 'Send',
    passwordResetRequestError: 'Erorr during sending request',
    passwordResetRequestSuccess: 'Request send. Check Your mailbox.'
  },
  layout: {
    changeUsg: 'Change USG device',
    adminOrganization: 'Admin organization',
    adminDoctors: 'Admin doctors',
    logout: 'Logout'
  },
  patientList: {
    patient: 'Patient, age',
    previousVisit: 'Previous visit',
    nextVisit: 'Next visit',
    actions: 'Actions',
    makeVisit: 'Start visit',
    planVisit: 'Plan visit',
    openHistory: "Open patient's history",
    editPatient: 'Edit patient',
    showContact: 'Show contact to patient'
  },
  setPassword: {
    key: 'Key',
    repeatPassword: 'Repeat password',
    sendNewPassword: 'Send new password',
    backToLogin: 'Back to login',
    sePasswordSuccess: 'Successful reset password',
    emptyFieldError: 'Fill all necessary fields',
    setPasswordError: 'Error during reset password'
  },
  visitsList: {
    epidStatus: 'Epidemic status',
    insurance: 'Insurance',
    insertedCheckup: 'Clinical data provided',
    chooseVisitType: 'Choose the visit type',
    gabinet: 'Direct',
    televisit: 'Remote',
    recipe: 'Reciepe issue',
    updateUserError: 'Updating user data error',
    successUpdateVisitType: 'Successfuly updated type of the visit',
    updateVisitTypeError: 'Updating type of the visit error',
    makeVisit: 'Make the appointment',
    planVisit: 'Plan the appointment',
    openPatientHistory: 'Browse the patient history',
    editPatient: 'Edit the patient record',
    seePatientContact: 'View the patient contact',
    editVisitType: 'Edit the visit type',
    dateField: 'Hr./conf.',
    patientField: 'Patient, age',
    epidField: 'Epid, eWuś. scores',
    actionField: 'Actions'
  },
  patient: {
    patient: 'Patient',
    validationType: 'Validation type',
    firstName: 'First name',
    lastName: 'Last name',
    sex: 'Sex',
    phone: 'Phone number',
    email: 'Email',
    city: 'City',
    address: 'Address',
    postalCode: 'Postal code',
    document: 'Document nr',
    authorizedPerson: 'Authorized person',
    interview: 'Inteview',
    comment: 'Comment',
    save: 'Save',
    saveAndStartVisit: 'Save and start visit',
    saveAndPlanVisit: 'Save and plan visit'
  },
  visit: {
    visit: 'The visit',
    captureMinimize: 'Minimize',
    captureSpeech: 'Capture Speech',
    captureSave: 'Save & Finalize',
    captureSummarize: 'Summarize',
    captureSummarizing: 'Summarizing',
    captureClear: 'Clear',
    startVisit: 'Start the visit',
    addRecipe: 'Add prescription',
    forms: 'Forms',
    sendEmail: 'Send the email',
    saveVisit: 'Save the visit',
    visitDescription: 'Visit description',
    requiredField: 'This field is required',
    diagnosis: 'Diagnosis',
    recommendations: 'Recommendations',
    checkUps: 'Examinations ordered',
    prescribedDrugs: 'medicines prescribed',
    drugs: 'medicines',
    quantity: 'Quantity',
    code: 'Code',
    suggestedDose: 'Suggested dose',
    controlVisitDate: 'Control visit date',
    manual: 'custom',
    forMonth: '1 month',
    forTwoMonths: '2 month',
    forThreeMonths: '3 month',
    forHalfYear: '6 month',
    forYear: '12 month',
    for: 'for',
    weeks: 'Weeks',
    cancel: 'Cancel',
    saveNextVisitDate: 'Save the next visit date',
    notChoosenDate: 'The date not choosen',
    visitDate: 'The date of next visit',
    usg: 'USG',
    addUsgSuccess: 'Usg added to the visit',
    addUsgError: 'Adding USG error',
    usgDescription: 'USG description',
    usgRefreshSuccess: 'The USG description has been updated',
    initDataError: 'Data initialisation error',
    saveVisitError: 'Saving visit error',
    madeRecipeSuccess: 'Prescription issued',
    recipe: 'Prescription',
    madeRecipeError: 'Prescription issue error',
    getRecipeError: 'Downloading prescription error',
    getRecipeStatusError: 'Downloading prescription status error',
    visitSummary: 'Podsumowanie',
    getPdfSummaryError: 'Downloading visit summary pdf error',
    getFormPdfError: 'Downloading form pdf error',
    getVisitDatesError: 'Downloading visit dates error',
    sendEmailError: 'Sending the email error',
    endVisitAlert: 'The visit has been terminated',
    changeVisitStatusError: 'Changing the visit status error',
    checkUpDate: 'Checkup date',
    chooseForm: 'Choose the form',
    generate: 'Generate',
    remove: 'Delete',
    price: 'Price',
    services: 'Services',
    addPrescription: 'Add prescription',
    addPatient: 'Add patient',
    addReferral: 'Add referral',
    address: 'Address',
    isInsuranced: 'Is insuranced',
    prescription365: 'Prescription 365 days',
    validDateFrom: 'Valid date from',
    validDateTo: 'Valid date to',
    prescriptionType: 'Prescription type',
    certPassword: 'Certificate password',
    yearOld: 'year old',
    notReplace: 'Do not replace',
    addDrugs: 'Add drugs',
    searchDrugs: 'Search drugs',
    onlyRefunded: 'R?',
    availableRefundations: 'Available refundations',
    addDrug: 'Add drug',
    showAllDrugs: 'Show all drugs',
    addPrescriptionError: 'Error during creating prescription',
    visitsAndCheckups: 'Visits and checkups',
    prescriptions: 'Prescriptions',
    interview: 'Interview',
    calendar: 'Calendar',
    epidInterview: 'Epidemiologic interview',
    positive: 'positive',
    negative: 'negative',
    dosing: 'Dosing',
    refundation: 'Refundation',
    setCheckUps: 'Set checkups',
    controlVisit: 'Control visit',
    total: 'Total',
    showAll: 'Show all',
    showToDo: 'Show to do',
    dose: 'Dose',
    package: 'Package',
    removePrescriptionConfirmation: 'Are You sure abote remove prescription?',
    removePrescriptionInfo: 'You cannot reverse it. Click "OK to confirm',
    removePrescriptionSubmit: 'Ok: "Prescription remove confirmation"',
    removed: 'Usunięto',
    referralDescription: 'Opis skierowania',
    getIcdError: 'Error during getting ICD',
    labCode: 'diagnostic laboratory code',
    procedure: 'Procedure',
    removeReferralConfirmation: 'Are You sure about cancel referral??',
    removeReferralInfo:
      'You cannot reverse it. Push „delete” if You want remove it',
    referralTitleDecription: 'Referral details',
    getReferralError: 'Error during getting referral',
    referralCancelSuccess: 'Referral is cancelled',
    referralCancelError: 'Errur during cancelling referral',
    referralCancelTitleDescription: 'Referral cancelling',
    prescriptionDetailsTitle: 'PrescriptionDetails',
    procedures: 'Procedures',
    strongMedicamentAlert: 'Strong medicine',
    activeSubstances: 'Active substances',
    conditionDescriptionPlaceholder: 'Condition description (required)',
    prescriptionDoctor: 'Doctor',
    dateFrom: 'Date from',
    showPatientRelated: 'Show Patient Related',
    showDoctorRelated: 'Show Doctor Related',
    presInfoText: 'Please select and add the medicine to view it in the prescription',
    presTitle: 'Prescription ',
    addThisBtn: 'Add This',
    resetFormBtn: 'Reset Form',
    issuePresBtn: 'Issue Prescription',
    infoPopUpDetails: {
      infoPopupTitle: 'Medicine Information',
      dose: 'Dose',
      package: 'Package',
      price: 'Price',
      availableRefunds: 'Available Refunds'
    },
    disablePresErrorMsg: 'You could not add a medicine to an already issued prescription. Please click reset form to issue prescription for any additional medicines'
  },
  organization: {
    doctors: 'Doctos',
    doctorSettings: 'Doctors settings',
    save: 'Save',
    workHours: 'Work hours',
    addDoctor: 'Add doctor',
    firstName: 'First name',
    lastName: 'Last name',
    actions: 'Actions',
    getDoctorsError: 'Error during getting doctors list',
    organizationEdit: 'Organization edit',
    name: 'Name',
    externalId: 'ID OID ext',
    internalId: 'ID OID Root',
    unitCode: 'Unit code',
    phone: 'Phone number',
    city: 'City',
    street: 'Street',
    houseNr: 'Street nr',
    apartmentNr: 'House nr',
    postalCode: 'Postal code',
    simc: 'SIMC number',
    rwpdlCode: 'RWPDL Code',
    tlsCert: 'TLS certificate',
    tlsPlaceholder: 'Choose or drop TLS certificate',
    wssCert: 'WSS certificate',
    wssPlaceholder: 'Choose or drop WSS certificate',
    saveCert: 'Load certificate',
    getOrganizationError: 'Error during getting organization data',
    updateOrganizationSuccess: 'Successfull update organization',
    updateOrganizationError: 'Error during organization update',
    updateCertsSuccess: 'Sucessfull send certificates',
    updateCertsError: 'Error during send certificates',
    statisticNumber: 'Statistic number',
    specialization: 'Specialization',
    isAdmin: 'Admin privileges',
    cert: 'Certificate',
    visitTime: 'Visit time',
    day: 'Day'
  }
}
