import Vue from 'vue'
// import '@babel/polyfill'
import './Utils/fliter'
import './Utils/directive'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './registerServiceWorker'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

export const bus = new Vue()

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

window.vm = vm
