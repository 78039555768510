<template>
  <b-container fluid>
    <b-row align-v="center" align-h="center" style="height: 100vh">
      <b-col md="4" sm="12">
        <b-card :title="$t('login.login')">
          <b-form @submit.prevent="sendCode" v-if="loginStep === 1">
            <b-form-group :label="$t('labels.email')">
              <b-form-input
                type="email"
                :placeholder="$t('labels.email')"
                required
                @input="getTenant"
                v-model="email"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Klinika" v-if="tenants.length > 0">
              <b-form-select :options="tenants" v-model="tenant" required />
            </b-form-group>
            <b-button type="submit" variant="primary" block>{{
              $t('login.sendCode')
            }}</b-button>
            <b-col style="text-align: center"
              ><b-link to="/reset-hasla">{{
                $t('login.forgotPassword')
              }}</b-link></b-col
            >
          </b-form>
          <b-form @submit.prevent="login" v-else>
            <b-form-group :label="$t('labels.password')">
              <b-form-input
                type="password"
                :placeholder="$t('labels.password')"
                required
                v-model="password"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('login.code')" v-if="is2fa">
              <b-form-input
                :placeholder="$t('login.code')"
                required
                v-model="code"
              ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary" block>{{
              $t('login.loginButton')
            }}</b-button>
            <b-alert :show="error" variant="danger" class="mt-3">{{
              $t('login.badCredentials')
            }}</b-alert>
            <b-col style="text-align: center"
              ><b-link to="/reset-hasla">{{
                $t('login.forgotPassword')
              }}</b-link></b-col
            >
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import auth from '../../services/auth'
import user from '../../services/user'
export default {
  data() {
    return {
      email: '',
      password: '',
      error: false,
      tenants: [],
      tenant: '',
      code: '',
      loginStep: 1,
      is2fa: false
    }
  },
  methods: {
    async login() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
          tenant: this.tenant,
          code: this.code
        }
        await auth.login(payload)
        if (auth.isSuperAdmin()) {
          this.$router.push({
            name: 'dashboard.organization'
          })
        } else {
          this.$router.push({
            name: 'dashboard.visits'
          })
        }
        this.error = false
      } catch (e) {
        this.error = true
      }
    },

    async sendCode() {
      try {
        if (this.email) {
          if (this.is2fa) {
            await auth.sendCode(this.email, this.tenant)
          }
          this.loginStep = 2
        }
      } catch (e) {
        this.$swal(
          this.$t('error'),
          'Nastąpił błąd przy wysyłaniu kodu',
          'error'
        )
      }
    },

    async getTenant() {
      try {
        const {
          data: { tenants, is2fa }
        } = await user.getUserTenants(this.email)
        this.is2fa = is2fa
        if (tenants.length === 1) {
          this.tenant = tenants[0].uuid
        } else {
          this.tenants = tenants.map((el) => {
            return {
              text: el.name,
              value: el.uuid
            }
          })
        }
      } catch (error) {}
    }
  }
}
</script>
<style scoped>
.col {
  text-align: center;
}
</style>
