<template>
  <b-container>
    <b-row>
      <b-col>
        Wczytaj plik szablonu
        <input type="file" class="btn btn-secondary" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h6>Wczytane szablony rozliczeniowe</h6>
      </b-col>
      <b-col>
        <b-table :fields="fields" :items="items"></b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button>Wystaw FV do szablonu</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nazwa' },
        { key: 'templateId', label: 'Numer szablonu' },
        {
          key: 'price',
          label: 'Kwota rozliczenia'
        },
        {
          key: 'invoiceNr',
          label: 'Numer faktury NFZ'
        }
      ],
      items: [
        {
          name: 'Szablon 1',
          templateId: '1',
          price: '1000',
          invoiceNr: '1'
        },
        {
          name: 'Szablon 2',
          templateId: '2',
          price: '2000',
          invoiceNr: '2'
        }
      ]
    }
  }
}
</script>
