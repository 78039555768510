<template>
      <b-modal :id="customId" hide-footer>
      <b-form @submit.prevent="handleSubmit">
        <b-form-group label-size="sm" label-align="right">
          <b-form-input v-model="form.name" :placeholder="$t('organization.name')" />
        </b-form-group>
        <b-form-group label-size="sm" label-align="right">
          <b-form-checkbox v-model="form.isHidden">
            {{ $t('organization.isHidden') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label-size="sm" label-align="right">
          <b-form-input v-model.number="form.price" type="number" :placeholder="$t('organization.price')" step="0.01"/>
        </b-form-group>
        <b-form-group :label="$t('organization.dateFrom')" label-size="sm" label-align="right">
          <b-form-input v-model="form.dateFrom" type="date" :placeholder="$t('organization.dateFrom')" />
        </b-form-group>
        <b-form-group :label="$t('organization.dateTo')" label-size="sm" label-align="right">
          <b-form-input v-model="form.dateTo" type="date" :placeholder="$t('organization.dateTo')" />
        </b-form-group>
        <b-form-group label-size="sm" label-align="right">
          <b-form-input v-model="form.wwwName" :placeholder="$t('organization.wwwName')" />
        </b-form-group>
        <b-form-group label-size="sm" label-align="right">
          <b-form-checkbox v-model="form.wwwIsHidden">
            {{ $t('organization.wwwIsHidden') }}
          </b-form-checkbox>
        </b-form-group>
        <b-button type="submit" variant="primary">{{ $t('send') }}</b-button>
      </b-form>
    </b-modal>
</template>
<script>

export default {
  props: {
    customId: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      form: {
        name: '',
        isHidden: false,
        price: 0,
        dateFrom: '',
        dateTo: '',
        wwwName: '',
        wwwIsHidden: false
      }
    }
  },
  methods: {
    setData(payload) {
      this.form = payload
    },
    handleSubmit() {
      this.$emit('submit', this.form)
    }
  }
}
</script>
