<template>
  <b-card style="margin-bottom: 10px">
    <b-row align-v="center">
      <b-col cols="2">
        <div class="rectangle">
          <span>{{ categoryShortcode }}</span>
        </div>
      </b-col>
      <b-col cols="10">
        <h5>
          {{ data.date | formatDate }}
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <component
          :is="currentComponent"
          :data="data"
          class="single-wrapper"
        ></component>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import Visit from './Visit'
import Prescription from './Prescription'
import CheckUp from './CheckUp'
import Referral from './Referral.vue'
import CanceledReferral from './CanceledReferral.vue'
import Procedure from './Procedure.vue'
export default {
  name: 'Single',
  components: {
    Visit,
    Prescription,
    CheckUp,
    Referral,
    CanceledReferral,
    Procedure
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
  computed: {
    categoryShortcode() {
      return this.categories[this.category]
    },
    currentComponent() {
      return this.components[this.category]
    }
  },
  data() {
    return {
      categories: {
        visit: 'W',
        checkUp: 'B',
        prescription: 'R',
        referral: 'S',
        CanceledReferral: 'AS',
        procedure: 'P'
      },
      components: {
        visit: 'Visit',
        checkUp: 'CheckUp',
        prescription: 'Prescription',
        referral: 'Referral',
        canceledReferral: 'CanceledReferral',
        procedure: 'Procedure'
      }
    }
  }
}
</script>
<style scoped>
.single-wrapper {
  padding: 10px;
  border-bottom: 1px solid #bbb;
  margin: 10px;
}
.single-wrapper:last-of-type {
  border: 0;
}
.rectangle {
  background-color: #089bab;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.code {
  padding-left: 10px;
  padding-top: 10px;
}
</style>
