<template>
  <b-container>
    <b-row align-v="center" align-h="center" style="height: 100vh">
      <b-col md="4" sm="12">
        <b-card>
          <b-form @submit.prevent="sendRequest">
            <b-form-group :label="$t('setPassword.key')">
              <b-form-input
                :placeholder="$t('setPassword.key')"
                required
                v-model="key"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('labels.email')">
              <b-form-input
                type="email"
                :placeholder="$t('labels.email')"
                required
                v-model="email"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('labels.password')">
              <b-form-input
                type="password"
                :placeholder="$t('labels.password')"
                required
                v-model="password"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('setPassword.repeatPassword')">
              <b-form-input
                type="password"
                :placeholder="$t('setPassword.repatPassword')"
                required
                v-model="passwordRepeat"
              ></b-form-input>
            </b-form-group>
            <b-button block type="submit" variant="primary">{{
              $t('setPassword.sendNewPassword')
            }}</b-button>
          </b-form>
          <b-col style="text-align: center"
            ><b-link to="/login">{{
              $t('setPassword.backToLogin')
            }}</b-link></b-col
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import userService from '../../services/user'
export default {
  data() {
    return {
      key: '',
      email: '',
      password: '',
      passwordRepeat: ''
    }
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    if (this.$route.query.key) {
      this.key = this.$route.query.key
    }
  },
  methods: {
    async sendRequest() {
      try {
        if (this.email && this.key && this.password) {
          await userService.setNewPassword(this.email, this.key, this.password)
          this.$swal(
            this.$t('success'),
            this.$t('setPassword.setPasswordSuccess')
          )
        } else {
          this.$swal(
            this.$t('error'),
            this.$t('setPassword.emptyFieldError'),
            'error'
          )
        }
      } catch (error) {
        this.$swal(
          this.$t('error'),
          this.$t('setPassword.setPasswordError'),
          'error'
        )
      }
    }
  }
}
</script>
