<template>
  <div>
    <b-row>
      <b-col cols="12">
        <template v-if="isModernVisit">
          <div v-if="data.interview" class="visit-detail">
            <h5>
              {{ $t('visit.visitDescription') }}:
              <b-button @click="handleCopyText('interview', data.interview)"
                >Kopiuj</b-button
              >
            </h5>
            <div v-html="data.interview" style="max-width: 100%"></div>
          </div>
          <div v-if="data.diagnosis" class="visit-detail">
            <h5>
              Rozpoznanie:<b-button v-if="parsedDiagnosis"
                @click="handleCopyDiagnosis(data.diagnosis)"
                >Kopiuj</b-button
              >
            </h5>
            <div v-if="parsedDiagnosis">
              <div
                v-for="diagnosis in parsedDiagnosis"
                :key="JSON.stringify(diagnosis)"
              >
                {{ diagnosis.code }} {{ diagnosis.text }}
              </div>
            </div>
            <div v-else v-html="data.diagnosis" style="max-width: 100%"></div>
          </div>
          <div v-if="data.recommendations" class="visit-detail">
            <h5>
              {{ $t('visit.recommendations') }}:
              <b-button
                @click="handleCopyText('recommendations', data.recommendations)"
                >Kopiuj</b-button
              >
            </h5>
            <div v-html="data.recommendations" style="max-width: 100%"></div>
          </div>
          <div v-if="data.checkUpsText" class="visit-detail">
            <h5>
              {{ $t('visit.setCheckUps') }}:
              <b-button @click="handleCopyText('checkUps', data.checkUpsText)"
                >Kopiuj</b-button
              >
            </h5>
            <div v-html="data.checkUpsText" style="max-width: 100%"></div>
          </div>
        </template>
        <template v-else>
          <div v-html="data.description"></div>
        </template>
        <div v-if="data.controlVisit" class="visit-detail">
          <h5>{{ $t('visit.controlVisit') }}:</h5>
          {{ data.controlVisit | formatDate }}
        </div>
      </b-col>
      <b-col cols="12">
        <Single
          v-for="el in data.checkUps"
          :key="el.id"
          :data="el"
          category="checkUp"
        />
      </b-col>
      <b-col cols="12">
        <Single
          v-for="el in data.prescriptions"
          :key="el.id"
          :data="el"
          category="prescription"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Single: () => import('./Single')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isModernVisit() {
      return (
        typeof this.data.diagnosis === 'string' ||
        typeof this.data.interview === 'string' ||
        typeof this.data.recommendations === 'string' ||
        typeof this.data.checkUps === 'string'
      )
    },
    parsedDiagnosis() {
      try {
        if (this.data.diagnosis) {
          return JSON.parse(this.data.diagnosis)
        } else return false
      } catch (error) {
        return false
      }
    },
    ...mapState('Visit', {
      visit: (state) => state.currentVisit
    })
  },
  methods: {
    ...mapActions('Visit', ['setVisitProperty', 'updateVisit']),

    handleCopyText(type, text) {
      this.setVisitProperty({
        key: type,
        value:
          this.visit[type].length > 0
            ? `${this.visit[type]}\n${text}`
            : `${text}`
      })
      this.updateVisit()
    },
    handleCopyDiagnosis(data) {
      try {
        const parsedData = JSON.parse(data)
        const currentDiagnosis = JSON.parse(this.visit.diagnosis)
        const newDiagnosis = [...currentDiagnosis, ...parsedData]
        this.setVisitProperty({
          key: 'diagnosis',
          value: JSON.stringify(newDiagnosis)
        })
      } catch (e) {
      }
    }
  }
}
</script>
<style scoped lang="scss">
.visit-detail {
  margin-bottom: 20px;
  h5 {
    line-height: 1.5;
  }
  div {
    white-space: pre-wrap;
  }
}
</style>
