import axios from './index'

export default {
  async sendReferral(payload) {
    try {
      const { data } = await axios.post('/api/auth/referral', payload)
      return data.Referral_id
    } catch (e) {
      throw new Error(e.response.request.responseText)
    }
  },
  async getReferralDetails(referralId) {
    try {
      const resp = await axios.get(`/api/auth/referral/${referralId}/info`)
      return resp
    } catch (e) {
      throw e
    }
  },
  async downloadReferral(referralId) {
    try {
      const resp = await axios.get(
        `/api/auth/referral/${referralId}/download-pdf`,
        { responseType: 'arraybuffer' }
      )
      return resp
    } catch (e) {
      throw e
    }
  },
  async cancelReferral(referralId) {
    try {
      const resp = await axios.post(`/api/auth/referral/${referralId}/cancel`)
      return resp
    } catch (e) {
      throw e
    }
  },

  getVisitReferral(patientId, visitId) {
    return axios.get(`/api/auth/referral/${patientId}/${visitId}`)
  }
}
