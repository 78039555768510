import axios from './index'

export default {
  importUsg(visitId, usg) {
    return axios.post('/api/auth/usg-import', { visitId, usg })
  },
  checkUsgDescriptions(visitId) {
    return axios.get(`/api/auth/usg-description/${visitId}`, {
      timeout: 1000 * 120
    })
  }
}
