import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import moment from 'moment'

const state = {
  visits: {
    rows: [],
    count: 0
  },
  filters: {
    search: '',
    status: ['wizyta', 'telewizyta', 'recepta'],
    date: moment().format('YYYY-MM-DD'),
    ended: false
  },
  currentVisit: {
    patientId: null,
    diagnosis: '',
    interview: '',
    procedures: '',
    recommendations: '',
    checkUps: '',
    controlVisit: '',
    services: [],
    status: false,
    controlDate: null
  },
  savedVisitId: null,
  showUsgButton: false,
  showUsgDescription: false,
  showIcdTab: true,
  texts: [],
  modalPhotos: [],
  previousTabIndex: 0,
  tabIndex: 0,
  showCalendarTab: false,
  icd10: [],
  icd9: [],
  calendarSlots: []
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
