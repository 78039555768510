<template>
  <b-modal id="set-visit-modal" hide-footer>
    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="8">
          <b-input placeholder="Wpisz nazwisko lub PESEL" :value="filters.search"
            v-debounce:1000ms="(val, e) => search(val)"></b-input>
        </b-col>
      </b-row>
    </b-col>
    <b-row>
      <b-col>
        <b-table :fields="fields" :items="patients.rows" @row-clicked="handleClickRow" hover>
          <template v-slot:cell(patient)="data">
            <div>
              {{ data.item.lastName }} {{ data.item.firstName }},
              {{ getAge(data.item.birthDate) }} lat <template
                v-if="getAge(data.item.birthDate) < 18 && data.item.months">
                {{
                  data.item.months % 12 }}
                miesięcy</template>
            </div>
          </template>
          <template v-slot:cell(pesel)="data">
            <div style="text-align: center">
              {{ data.value }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState('Patient', {
      patients: (state) => state.patients,
      filters: (state) => state.filters
    }),
    fields() {
      return [
        {
          key: 'patient',
          label: this.$t('patientList.patient')
        },
        {
          key: 'pesel',
          label: 'PESEL',
          thStyle: 'text-align: center',
          tdStyle: 'text-align: center'
        }
      ]
    }
  },
  methods: {
    ...mapActions('Patient', [
      'fetchPatients',
      'setFilters',
      'setPlannedPatientId',
      'resetPatients'
    ]),
    search(value) {
      this.setFiltersState('search', value)
      this.fetchPatients()
    },
    getAge(date) {
      return date ? moment().diff(date, 'years') : ''
    },
    setFiltersState(prop, value) {
      this.setFilters({ prop, value })
    },
    handleClickRow(data) {
      this.$emit('select', data)
      this.$bvModal.hide('set-visit-modal')
      this.resetPatients()
    }
  }
}
</script>
