import axios from './index'

export default {
  getServices() {
    return axios.get('/api/auth/services')
  },
  getDoctorServices(id) {
    return axios.get(`/api/auth/services/doctor/${id}`)
  },
  addService(payload) {
    return axios.post('/api/auth/services', payload)
  },
  addDoctorService(payload) {
    return axios.post(`/api/auth/services/doctor`, payload)
  },
  updateService(id, payload) {
    return axios.put(`/api/auth/services/${id}`, payload)
  },
  updateDoctorService(id, payload) {
    return axios.put(`/api/auth/services/doctor/${id}`, payload)
  },
  deleteService(id) {
    return axios.delete(`/api/auth/services/${id}`)
  },
  deleteDoctorService(id) {
    return axios.delete(`/api/auth/services/doctor/${id}`)
  }
}
