<template>
  <div>
    <b-form-checkbox @change="changeFavourites()" v-model="favouritesAdded" name="check-button" switch
      style="text-align:right;padding-right:30px; font-size: 12px;">
      <span style="display:inline-block; margin-top:2px">Show Only Favourites</span>  <b-icon-star-fill variant="warning"></b-icon-star-fill>
    </b-form-checkbox>
    <multiselect v-if="!favouritesAdded" v-model="search" tagPlaceholder=""  :placeholder="placeholder"
       :options="data" @search-change="handleInputValue" @select="handleSelect" track-by="label"
      label="label" :close-on-select="true" :show-labels="false" :showNoOptions="false"  ref="multiselect">
      <template slot="option" slot-scope="props" v-if="props.option.label != ''">
        <div>
          <span>
            <b-icon-star v-if="( props.option['favourites'] == undefined || props.option['favourites'] == false ) && !props.option['$isDisabled']" @click="addToFavourites(props)">
            </b-icon-star>
            <b-icon-star-fill variant="warning" v-if="props.option.favourites"
              @click="removeFavourites(props)"></b-icon-star-fill> {{ props.option.label }}
          </span>
        </div>
      </template>
    </multiselect>

    <multiselect v-if="favouritesAdded" v-model="ssearchFavourites" tagPlaceholder="" selectLabel="" :placeholder="placeholder"
       :options="favouriteStorage" @search-change="handleInputValue" @select="handleSelect"
      track-by="label" label="label" :close-on-select="true" :show-labels="false" :showNoOptions="false" ref="multiselect">
      <template slot="option" slot-scope="props">
      <div>
        <span>
          <!-- <b-icon-star v-if="!props.option['favourites']">
          </b-icon-star> -->
          <b-icon-star-fill variant="warning" v-if="props.option.favourites"
            @click="removeAddedFavourites(props)"></b-icon-star-fill>  {{ props.option.label }}
        </span>
      </div>
    </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean
    },
    initValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    id: {
      type: String
    },
    addedFav: {
      type: Boolean
    }
  },
  data() {
    return {
      search: null,
      ssearchFavourites: null,
      favouritesAdded: false,
      favouritesArray: [],
      favouriteStorage: [],
      FavouriteDialogTxt: ''
    }
  },

  mounted() {
    if (this.addedFav) {
      this.favouritesAdded = true
      this.$refs.multiselect.activate()
    }
    this.favouriteStorage = JSON.parse(localStorage.getItem('favourites' + this.id))
    this.favouritesArray = [...this.favouriteStorage]
    if (this.initValue) {
      this.search = {
        label: this.initValue
      }
    }
  },
  computed: {
    filteredList() {
      return this.data.filter((el) => {
        for (const key of this.keys) {
          if (
            el[key] &&
            el[key].toLowerCase().search(this.search.toLowerCase()) > -1
          ) {
            return true
          }
        }
        return false
      })
    }
  },
  methods: {
    handleSelect(option) {
      this.$emit('select', option.value)
    },
    handleInputValue(value) {
      this.$emit('input', value)
    },
    changeFavourites() {
      this.favouriteStorage = JSON.parse(localStorage.getItem('favourites' + this.id))
    },
    addToFavourites(props) {
      this.search = ''
      this.data[props.index]['favourites'] = true
      this.favouritesArray.push(this.data[props.index])
      localStorage.setItem('favourites' + this.id, JSON.stringify(this.favouritesArray))
      this.favouriteStorage = JSON.parse(localStorage.getItem('favourites' + this.id))
      this.FavouriteDialogTxt = props.option.label + ' has been added to the favourites' + '\n\n ' + 'Note: To remove the item from the favourite click the star again'
      this.$swal(this.$t('success'), this.FavouriteDialogTxt, 'success')
    },
    removeFavourites(props) {
      this.data[props.index]['favourites'] = false
      var removedItem = this.data[props.index].label
      let removeItemIndex = this.favouritesArray.findIndex(item => item.label === removedItem)
      this.favouritesArray.splice(removeItemIndex, 1)
      localStorage.setItem('favourites' + this.id, JSON.stringify(this.favouritesArray))
      this.favouriteStorage = JSON.parse(localStorage.getItem('favourites' + this.id))
      this.FavouriteDialogTxt = props.option.label + ' has been removed from favourites'
      this.$swal(this.$t('success'), this.FavouriteDialogTxt, 'success')
    },
    // remove favourites from the added dropdown
    removeAddedFavourites(props) {
      this.favouritesArray.splice(props.index, 1)
      localStorage.setItem('favourites' + this.id, JSON.stringify(this.favouritesArray))
      this.favouriteStorage = JSON.parse(localStorage.getItem('favourites' + this.id))
      this.FavouriteDialogTxt = props.option.label + ' has been removed from favourites'
      this.$swal(this.$t('success'), this.FavouriteDialogTxt, 'success')
    }
  }
}
</script>
