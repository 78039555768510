import axios from './index'

export default {
  getOrganization() {
    return axios.get('/api/auth/organizations')
  },
  update(data) {
    return axios.put('/api/auth/organizations', data)
  },

  sendFile(file, type) {
    const formData = new FormData()
    formData.append('certificate', file)
    return axios.post(`/api/auth/organizations/certificate/${type}`, formData)
  },

  updateCertificatePassword(data) {
    return axios.post(`/api/auth/organizations/certificate-password`, data)
  }
}
