import axios from './index'

export default {
  async sendPrescription(payload, visitId) {
    try {
      const { data } = await axios.post(
        '/api/auth/prescription/' + visitId,
        payload
      )
      return data
    } catch (e) {
      throw new Error(e)
    }
  },
  async getVisitPrescriptions(visitId) {
    try {
      const { data } = await axios.get(`/api/auth/prescription/${visitId}`)
      return data
    } catch (e) {
      throw e
    }
  },
  async getPrescriptionDetails(prescriptionId) {
    try {
      const resp = await axios.get(
        `/api/auth/prescription/${prescriptionId}/info`
      )
      return resp
    } catch (e) {
      throw e
    }
  },
  async downloadPrescription(prescriptionId) {
    try {
      const resp = await axios.get(
        `/api/auth/prescription/${prescriptionId}/download-pdf`,
        { responseType: 'arraybuffer' }
      )
      return resp
    } catch (e) {
      throw e
    }
  },
  async cancelPrescription(prescriptionId) {
    try {
      const resp = await axios.get(
        `/api/auth/prescription/${prescriptionId}/cancel`
      )
      return resp
    } catch (e) {
      throw e
    }
  },
  async getPrescriptionCopy(prescriptionId) {
    const { data } = await axios.get(
      `/api/auth/prescription/${prescriptionId}/copy`
    )
    return data
  }
}
