<template>
  <b-row>
    <b-col>
      <time-line :items="visits" />
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex'
import TimeLine from '../../components/TimeLine.vue'

export default {
  components: {
    TimeLine
  },
  computed: {
    ...mapGetters('Patient', { visits: 'getVisitsToTimeLine' })
  }
}
</script>
