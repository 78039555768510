export default {
  getTimeZone(data, freeSlots, filterParams) {
    // let minuteDiff
    // let addTimeInterval
    // for (let i = 0; i < data.length; i++) {
    //   if (i !== data.length - 1) {
    //     let date1 = new Date(data[i]['dateStart'])
    //     let date2 = new Date(data[i + 1]['dateStart'])
    //     minuteDiff = this.diff_minutes(date1, date2)
    //     if (minuteDiff > 30) {
    //       addTimeInterval = new Date(date1.getTime() + 30 * 60 * 1000)
    //       data.splice(i + 1, 0, {
    //         dateStart: addTimeInterval,
    //         lastName: '',
    //         firstName: '',
    //         birthDate: '',
    //         comment: '',
    //         status: '',
    //         disabled: true
    //       })
    //     }
    //   }
    // }

    // compute freeSlot
    const formattedFreeSlots = freeSlots.map((fslt, rCtr) => {
      const slotStart = fslt.slot
      const offset = '2'
      const convertedDt = new Date(
        new Date(
          Date.parse(
            `${filterParams.date}T${slotStart}:00+${offset.padStart(2, '0')}:00`
          )
        ).toUTCString()
      )

      const resultData = {
        id: rCtr,
        dateStart: convertedDt,
        dateEnd: convertedDt,
        iName: ' ',
        patientId: 0,
        introduced: '1990-04-12T06:17:02.000Z',
        sex: 'K',
        lastName: '',
        firstName: '',
        yearBirth: 1982,
        pesel: ' ',
        nip: '',
        regon: null,
        country: '',
        postalCode: ' ',
        city: '  ',
        address: ' ',
        phone: ' ',
        nfz: 1,
        email: ' ',
        document: null,
        interview: null,
        visitType: 'wizyta',
        confirmed: 0,
        isPresent: null,
        rodo: 2,
        ewus: 1,
        comment: ' ',
        epid_status: '',
        disabled: true,
        lastVisitDate: '1999-10-14T07:38:00.000Z',
        lastCheckupDate: '1999-09-08T22:00:00.000Z'
      }
      return resultData
    })

    // code to insert time interval before first record
    // let firstRecordStartDate
    // let firstRecordStartTime
    // let timeDiff
    // while (true) {
    //   if (firstRecordStartTime === '09:00:00') {
    //     break
    //   }
    //   firstRecordStartDate = new Date(data[0].dateStart)
    //   firstRecordStartTime = new Date(
    //     firstRecordStartDate.getTime()
    //   ).toLocaleTimeString('en-GB')
    //   timeDiff = new Date(firstRecordStartDate.getTime() - 30 * 60 * 1000)
    //   const data1 = []
    //   data1.unshift({
    //     dateStart: timeDiff,
    //     lastName: '',
    //     firstName: '',
    //     birthDate: '',
    //     comment: '',
    //     status: '',
    //     disabled: true
    //   })
    // }

    // // code to Insert time interval after last record
    // let lastRecordStartDate = new Date(data[data.length - 1].dateStart)
    // let length = 19 - data.length
    // length = length < 0 ? 0 : length
    // for (let i = 0; i < length; i++) {
    //   lastRecordStartDate = new Date(
    //     lastRecordStartDate.getTime() + 30 * 60 * 1000
    //   )
    //   const data1 = []
    //   data1.push({
    //     dateStart: lastRecordStartDate,
    //     lastName: '',
    //     firstName: '',
    //     birthDate: '',
    //     comment: '',
    //     status: '',
    //     disabled: true
    //   })
    // }
    // const finalData = data.filter((slot) => {
    //   const dtStart = new Date(
    //     new Date(slot.dateStart).getTime()
    //   ).toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' })
    //   return (
    //     freeSlots
    //       .filter((f) => f.status === 'free')
    //       .map((fslot) => fslot.slot)
    //       .includes(dtStart) || slot.firstName !== ''
    //   )
    // })
    data = data.concat(formattedFreeSlots)
    data.sort((a, b) => {
      return new Date(a.dateStart) - new Date(b.dateStart)
    })

    return data
  },
  diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000
    diff /= 60
    return Math.abs(Math.round(diff))
  }
}
