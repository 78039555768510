export default {
  setVisits(state, payload) {
    state.visits = payload
  },
  setCalendarSlots(state, payload) {
    state.calendarSlots = payload
  },
  setFilters(state, payload) {
    state.filters[payload.prop] = payload.value
  },
  setPage(state, payload) {
    state.filters.page = payload
  },
  setSavedVisitId(state, payload) {
    state.savedVisitId = payload
  },
  setVisitTexts(state, payload) {
    state.texts = payload
  },
  setUsgButton(state, payload) {
    state.showUsgButton = payload
  },
  showUsgDescription(state, payload) {
    state.showUsgDescription = payload
  },
  setModalPhotos(state, payload) {
    state.modalPhotos = payload
  },
  setTabIndex(state, payload) {
    state.previousTabIndex = state.tabIndex
    state.tabIndex = payload
  },
  setCalendarTab(state, payload) {
    state.showCalendarTab = payload
    if (!payload) {
      state.tabIndex = state.previousTabIndex
    }
  },
  setVisitProperty(state, payload) {
    state.currentVisit[payload.key] = payload.value
  },
  setVisitData(state, payload) {
    state.currentVisit = {
      ...state.currentVisit,
      ...payload
    }
  },
  setIcd10(state, payload) {
    state.icd10 = payload
  },
  setIcd9(state, payload) {
    state.icd9 = payload
  }
}
