<template>
  <b-row style="height: 100%" align-v="center">
    <b-col cols="12">
      <b-row align-v="center" style="height: 100%">
        <b-col md="7" xs="12">
          <b-row>
            <b-button @click="changeDate('prev')" variant="outline"><font-awesome-icon
                icon="arrow-left"></font-awesome-icon></b-button>
            <b-form-datepicker @input="handleChangeDate($event)" :start-weekday="1" :value="filters.date"
              style="width: 300px" :date-info-fn="dateClass"  @context="onContext"></b-form-datepicker>
            <b-button variant="outline" @click="changeDate('next')"><font-awesome-icon
                icon="arrow-right"></font-awesome-icon></b-button>
          </b-row>
        </b-col>
        <b-col md="5" xs="12">
          <h5>
            {{ $t('visit.total') }}: {{ getVisitsCount.total }}
            <b-button @click="toggleAllVisits()">
              <span v-if="filters.ended === false">{{
                $t('visit.showAll')
              }}</span>
              <span v-else>{{ $t('visit.showToDo') }}</span>
            </b-button>
          </h5>

          <b-form-group>
            <b-form-checkbox-group
              @input="setFiltersState('status', $event)"
              :checked="filters.status"
              :options="checkboxes"
              value-field="item"
              text-field="name"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      statusOptions: [
        {
          value: '',
          text: 'Wybierz opcję'
        }
      ],
      visitDate: '',
      visitTypes: ['wizyta', 'telewizyta', 'recepta'],
      radioEnded: [
        { name: 'Wszystkie', value: null },
        { name: 'Zakończone', value: true },
        { name: 'Niezakończone', value: false }
      ],
      calendarHover: false,
      attributes: [
        {
          key: 'today',
          highlight: {
            color: 'blue',
            fillMode: 'outline'
          },
          dates: new Date()
        },
        {
          highlight: {
            color: 'gray',
            fillMode: 'solid'
          },
          contentStyle: {
            color: 'black'
          },
          popover: {
            label: ''
          }
        },
        {
          highlight: {
            color: 'green',
            fillMode: 'solid'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('Visit', {
      calendarSlots(state) {
        return state.calendarSlots
      },
      filters: (state) => state.filters
    }),
    checkboxes() {
      return [
        {
          item: 'wizyta',
          html: `<span>Gabinet: ${this.getVisitsCount.wizyta}</span>`
        },
        {
          item: 'telewizyta',
          html: `<span class="text-info">Telewizyta:  ${this.getVisitsCount.telewizyta}</span>`
        },
        {
          item: 'recepta',
          html: `<span class="text-success">Recepturowa:  ${this.getVisitsCount.recepta}</span>`
        }
      ]
    },
    ...mapGetters('Visit', ['getVisitsCount'])
  },
  async beforeMount() {
    await this.fetchCalendarSlotData()
    await this.getData()
  },
  methods: {
    ...mapActions('Visit', ['fetchVisits', 'setFilters', 'fetchCalendarSlots']),
    dateClass(ymd, date) {
      if (this.calendarSlots?.freeSlots?.dates) {
        let highlight = (this.calendarSlots.freeSlots.dates.includes(ymd)) ? 'slot_free' : (this.calendarSlots.reservedSlots.dates.includes(ymd)) ? 'slot_reserved' : 'slot_regular'
        return highlight
      } else {
        return 'slot_regular'
      }
    },
    onContext(ctx) {
      this.computeCalendarSlots(ctx.activeYMD)
    },
    async getData() {
      try {
        this.loading = true
        this.fetchVisits({ filters: this.filters })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async fetchCalendarSlotData() {
      try {
        this.fetchCalendarSlots({ filters: this.filters })
      } catch (e) {
        throw new Error(e)
      }
    },
    handleChangeDate(value) {
      this.visitDate = moment(value).format('YYYY-MM-DD')
      this.setFiltersState('date', value)
    },
    handleChangeVisitType(event) {
      this.setFiltersState('status', this.visitTypes)
      this.getData()
    },
    setFiltersState(prop, value) {
      this.setFilters({ prop, value })
      this.getData()
    },
    toggleAllVisits() {
      this.setFiltersState('ended', this.filters.ended === false ? null : false)
    },
    computeCalendarSlots(mthInfo) {
      const startMonth = moment(mthInfo).startOf('month').format('YYYY-MM-DD')
      const endMonth = moment(mthInfo).endOf('month').format('YYYY-MM-DD')
      this.setFiltersState('startDate', startMonth)
      this.setFiltersState('endDate', endMonth)
      // const startDtMth = new Date(startMonth).getMonth()
      // const endDtMth = new Date(endMonth).getMonth()
      // const isValidMonth = startDtMth === endDtMth
      // if (this.filters.startDate !== startMonth && isValidMonth) {
      //   this.fetchCalendarSlots({ filters: this.filters })
      // }
      this.fetchCalendarSlotData()
    },
    changeDate(direction) {
      switch (direction) {
        case 'prev':
          this.setFiltersState(
            'date',
            moment(this.filters.date).subtract(1, 'days').format('YYYY-MM-DD')
          )
          break
        case 'next':
          this.setFiltersState(
            'date',
            moment(this.filters.date).add(1, 'days').format('YYYY-MM-DD')
          )
          break
      }
    }
  }
}
</script>
