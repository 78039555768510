<template>
  <b-form>
    <b-form-group :label="$t('organization.visitTime')" label-for="time">
      <b-form-input id="time" v-model="hours.time" required></b-form-input>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 1`">
      <b-form-group label="Od" label-for="day_1.from" label-cols="1">
        <b-form-input
          id="day_1.from"
          v-model="hours.day_1.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_1.to" label-cols="1">
        <b-form-input
          id="day_1.to"
          v-model="hours.day_1.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 2`">
      <b-form-group label="Od" label-for="day_2.from" label-cols="1">
        <b-form-input
          id="day_2.from"
          v-model="hours.day_2.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_2.to" label-cols="1">
        <b-form-input
          id="day_2.to"
          v-model="hours.day_2.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 3`">
      <b-form-group label="Od" label-for="day_3.from" label-cols="1">
        <b-form-input
          id="day_3.from"
          v-model="hours.day_3.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_3.to" label-cols="1">
        <b-form-input
          id="day_3.to"
          v-model="hours.day_3.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 4`">
      <b-form-group label="Od" label-for="day_4.from" label-cols="1">
        <b-form-input
          id="day_4.from"
          v-model="hours.day_4.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_4.to" label-cols="1">
        <b-form-input
          id="day_4.to"
          v-model="hours.day_4.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 5`">
      <b-form-group label="Od" label-for="day_5.from" label-cols="1">
        <b-form-input
          id="day_5.from"
          v-model="hours.day_5.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_5.to" label-cols="1">
        <b-form-input
          id="day_5.to"
          v-model="hours.day_5.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 6`">
      <b-form-group label="Od" label-for="day_1.from" label-cols="1">
        <b-form-input
          id="day_6.from"
          v-model="hours.day_6.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_6.to" label-cols="1">
        <b-form-input
          id="day_6.to"
          v-model="hours.day_6.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-form-group :label="`${$t('organization.day')} 7`">
      <b-form-group label="Od" label-for="day_7.from" label-cols="1">
        <b-form-input
          id="day_7.from"
          v-model="hours.day_7.from"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Do" label-for="day_7.to" label-cols="1">
        <b-form-input
          id="day_7.to"
          v-model="hours.day_7.to"
          required
        ></b-form-input>
      </b-form-group>
    </b-form-group>
  </b-form>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hours: {
        time: '',
        day_1: {
          from: '',
          to: ''
        },
        day_2: {
          from: '',
          to: ''
        },
        day_3: {
          from: '',
          to: ''
        },
        day_4: {
          from: '',
          to: ''
        },
        day_5: {
          from: '',
          to: ''
        },
        day_6: {
          from: '',
          to: ''
        },
        day_7: {
          from: '',
          to: ''
        }
      }
    }
  },
  methods: {
    setHours(data) {
      this.hours = data
    },
    getHours() {
      return this.hours
    }
  }
}
</script>
