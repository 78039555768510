<template>
  <div>
    <b-form-group :label="$t('visit.labCode')">
      <complete-select
        @select="handleSelectLab($event)"
        @input="handleSearchLab"
        :data="labsOptions"
        id = "labs"
        required
        placeholder="Oddział szpitala"
      />
    </b-form-group>
    <b-form-group :label="$t('visit.mainRecognition')">
      <complete-select
        @select="handleSelectIcd($event)"
        @input="handleSearchIcd"
        :data="icd10Options"
        id="icd10"
        required
        placeholder="Please type ICD10 code or name"
      />
    </b-form-group>
    <b-form-group :label="$t('visit.additionalRecognition')">
      <b-button
        @click="handleAddNewRecognition()"
        variant="primary"
        v-if="payload.additionalRecognitions.length === 0"
      >
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
      <b-row
        v-else
        v-for="(recognition, i) in payload.additionalRecognitions"
        :key="'recognition' + i"
      >
        <b-col cols="9">
          <complete-select
            @select="handleSelectAdditionalIcd(i, $event)"
            @input="handleSearchIcd"
            :data="icd10Options"
             id="icd10"
            placeholder="Please type ICD10 code or name"
          ></complete-select>
        </b-col>
        <b-col cols="3"
          ><b-button @click="handleAddNewRecognition()" variant="primary">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
          <b-button @click="handleRemoveRecognition(i)" variant="danger">
            <font-awesome-icon icon="trash"></font-awesome-icon> </b-button
        ></b-col>
      </b-row>
    </b-form-group>
    <b-form-group :label="$t('visit.procedure')">
      <complete-select
        @select="handleSelectProcedure($event)"
        @input="handleSearchIcd9"
        :data="icd9Options"
        id="icd9"
        placeholder="ICD9"
      />
    </b-form-group>
    <div>
      <span>{{ $t('visit.referralDescription') }} </span>
      <b-button @click="handleAddNewParagraph()" variant="primary">
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </b-button>
      <b-row
        v-for="(paragraph, i) in payload.paragraphs.filter((el) => !el.hide)"
        :key="`paragraph-${i}`"
        class="mt-1"
      >
        <b-col cols="7">
          <b-form-group
            :label="paragraph.title"
            v-if="paragraph.isTitleDisabled"
          >
            <b-form-textarea
              v-model="paragraph.content"
              :placeholder="$t('visit.description')"
            ></b-form-textarea>
          </b-form-group>
          <div v-else>
            <b-form-input
              v-model="paragraph.title"
              :placeholder="$t('visit.title')"
            ></b-form-input>
            <b-form-textarea
              v-model="paragraph.content"
              :placeholder="$t('visit.description')"
            ></b-form-textarea>
          </div>
        </b-col>
        <b-col cols="1" v-if="!paragraph.isTitleDisabled">
          <b-button @click="handleRemoveParagraph(i)" variant="danger">
            <font-awesome-icon icon="trash"></font-awesome-icon>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import CompleteSelect from '@/components/CompleteSelect.vue'
import lookups from '../../../../../services/lookups'
export default {
  components: {
    CompleteSelect
  },
  data() {
    return {
      payload: {
        mainRecognition: {
          code: '',
          text: ''
        },
        destination: {
          code: '',
          text: ''
        },
        additionalRecognitions: [],
        paragraphs: [
          {
            content: '',
            title: 'Procedura (opcjonalna)',
            hide: true
          },
          {
            content: '',
            title: 'Cel badania (uzasadnienie)',
            isTitleDisabled: true
          },
          {
            content: '',
            title: 'Badania dotychczas wykonane',
            isTitleDisabled: true
          }
        ]
      },
      labCodes: [
        {
          name: 'PRACOWNIA REZONANSU MAGNETYCZNEGO 7250'
        },
        {
          name: 'PRACOWNIA REZONANSU MAGNETYCZNEGO DLA DZIECI 7251'
        }
      ],
      icdList: [],
      icd9List: [],
      labsList: [],
      icdSearch: '',
      labSearch: '',
      icd9Search: ''
    }
  },
  computed: {
    labsOptions() {
      return this.labsList.map(el => ({
        label: `${el.kod} ${el.nazwa}`,
        value: el,
        $isDisabled: el.kod.includes('–')
      }))
    },
    icd9Options() {
      return this.icd9List.map(el => ({
        label: `${el.code} ${el.name}`,
        value: el,
        $isDisabled: el.code.includes('–')
      }))
    },
    icd10Options() {
      return this.icdList.map(el => ({
        label: `${el.code} ${el.name}`,
        value: el,
        $isDisabled: el.code.includes('–')
      }))
    }
  },
  methods: {
    handleSelectIcd(data) {
      this.payload.mainRecognition = {
        code: data.code,
        text: data.name
      }
    },
    handleAddNewRecognition() {
      this.payload.additionalRecognitions.push({
        code: '',
        text: ''
      })
    },
    handleRemoveRecognition(i) {
      this.payload.additionalRecognitions =
        this.payload.additionalRecognitions.filter((el, index) => index !== i)
    },
    handleSelectAdditionalIcd(index, payload) {
      this.payload.additionalRecognitions =
        this.payload.additionalRecognitions.map((el, i) => {
          if (index === i) {
            return {
              code: payload.code,
              text: payload.name
            }
          }
          return el
        })
    },
    handleSelectLab(el) {
      this.payload.destination = {
        code: el.kod,
        text: el.nazwa
      }
    },

    handleSelectProcedure(el) {
      this.payload.paragraphs[0].content = `${el.code} ${el.name}`
    },
    handleAddNewParagraph() {
      this.payload.paragraphs.push({
        content: '',
        title: ''
      })
    },
    handleRemoveParagraph(i) {
      this.payload.paragraphs = this.payload.paragraphs.filter(
        (el, index) => index !== i
      )
    },
    async getLabs(searchText) {
      try {
        const { data } = await lookups.getLookup('diag-labs', searchText)
        this.labs = data.map((el) => ({
          ...el,
          kod: el.kod.toString()
        }))
        this.labsList = this.labs
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
      }
    },
    async getIcd10(searchText) {
      try {
        const { data } = await lookups.getLookup('icd10', searchText)
        const icd10 = data.map((el) => ({
          name: el.nazwa,
          code: el.kod.toString()
        }))
        this.icdList = icd10
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
      }
    },
    async getIcd9(searchText) {
      try {
        const { data } = await lookups.getLookup('icd9', searchText)
        const icd9 = data.map((el) => ({
          name: el.nazwa,
          code: el.kod.toString()
        }))
        this.icd9List = icd9
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
      }
    },
    handleSearchLab(input) {
      this.labSearch = input
      this.getLabs(input)
    },
    handleSearchIcd(input) {
      this.icdSearch = input
      this.getIcd10(input)
    },
    handleSearchIcd9(input) {
      this.icd9Search = input
      this.getIcd9(input)
    }
  }
}
</script>
