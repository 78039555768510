import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthorizedLayout from '../layouts/AuthorizedLayout'
import UnauthorizedLayout from '../layouts/UnauthorizedLayout'
import Visits from '../views/Visits'
import PatientList from '../views/Patient/List'
import PatientVisit from '../views/Patient/Visit/index.vue'
import PatientDetail from '../views/Patient/Detail/index.vue'
import Login from '../views/Login'
import PasswordReset from '../views/PasswordReset'
import SetNewPassword from '../views/SetNewPassword'
import Organization from '../views/Organization/Organization.vue'
import Doctors from '../views/Organization/Doctors.vue'
import Doctor from '../views/Organization/Doctor/index.vue'
import BenefitsRegister from '../views/Nfz/BenefitsRegister.vue'
import BillingInvoices from '../views/Nfz/BillingInvoices.vue'
import BillingTemplates from '../views/Nfz/BillingTemplates.vue'
import Contract from '../views/Nfz/Contract.vue'
import ManagementReports from '../views/Nfz/ManagementReports.vue'
import NewRaport from '../views/Nfz/NewRaport.vue'
import Raports from '../views/Nfz/Raports.vue'

import auth from '../services/auth'

Vue.use(VueRouter)

const loginRoutes = (prop, mode) => [
  {
    path: 'login',
    name: prop + '.login',
    meta: { dark: mode, auth: false, name: 'Logowanie' },
    component: Login
  },
  {
    path: 'reset-hasla',
    name: prop + '.password-reset',
    meta: { dark: mode, auth: false, name: 'Reset hasła' },
    component: PasswordReset
  },
  {
    path: 'ustaw-haslo',
    name: prop + '.set-password',
    meta: { dark: mode, auth: false, name: 'Ustaw nowe hasło' },
    component: SetNewPassword
  },
  {
    path: '*',
    redirectTo: '/login'
  }
]

const childRoutes = (prop, mode) => [
  {
    path: 'wizyty',
    name: prop + '.visits',
    meta: { dark: mode, auth: true, name: 'Wizyty' },
    component: Visits
  },
  {
    path: 'pacjenci',
    name: prop + '.patients',
    meta: { dark: mode, auth: true, name: 'Pacjenci' },
    component: PatientList
  },
  {
    path: 'pacjenci/:id',
    name: prop + '.patient',
    meta: { dark: mode, auth: true, name: 'Pacjent' },
    component: PatientDetail
  },
  {
    path: 'pacjenci/:id/wizyta/:visit',
    name: prop + '.patient-visit',
    meta: { dark: mode, auth: true, name: 'Pacjent' },
    component: PatientVisit
  },
  {
    path: 'organizacja',
    name: prop + '.organization',
    meta: { dark: mode, auth: true, name: 'Organizacja' },
    component: Organization
  },
  {
    path: 'doktorzy',
    name: prop + '.doctors',
    meta: { dark: mode, auth: true, name: 'Doktorzy' },
    component: Doctors
  },
  {
    path: 'doktorzy/edytuj/:id',
    name: prop + '.doctor-edit',
    meta: { dark: mode, auth: true, name: 'Edytuj doktora' },
    component: Doctor,
    props: {
      pageType: 'update'
    }
  },
  {
    path: 'doktorzy/dodaj',
    name: prop + '.doctor-add',
    meta: { dark: mode, auth: true, name: 'Dodaj doktora' },
    component: Doctor,
    props: {
      pageType: 'add'
    }
  },
  {
    path: 'nfz/faktury-rozliczeniowe',
    name: prop + '.billing-invoices',
    meta: { dark: mode, auth: true, name: 'Faktury rozliczeniowe' },
    component: BillingInvoices,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/szablony-rozliczeniowe',
    name: prop + '.billing-templates',
    meta: { dark: mode, auth: true, name: 'Szablony rozliczeniowe' },
    component: BillingTemplates,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/umowa',
    name: prop + '.contract',
    meta: { dark: mode, auth: true, name: 'Umowa' },
    component: Contract,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/raporty-zarzadcze',
    name: prop + '.management-reports',
    meta: { dark: mode, auth: true, name: 'Raporty zarządcze' },
    component: ManagementReports,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/nowy-raport',
    name: prop + '.new-raport',
    meta: { dark: mode, auth: true, name: 'Nowy raport' },
    component: NewRaport,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/raporty',
    name: prop + '.raports',
    meta: { dark: mode, auth: true, name: 'Raporty' },
    component: Raports,
    props: {
      isNfz: true
    }
  },
  {
    path: 'nfz/rejestr-swiadczen',
    name: prop + '.benefits-register',
    meta: { dark: mode, auth: true, name: 'Rejestr świadczeń' },
    component: BenefitsRegister,
    props: {
      isNfz: true
    }
  },
  {
    path: '*',
    redirectTo: '/app/wizyty'
  }
]

const routes = [
  {
    path: '/app',
    component: AuthorizedLayout,
    meta: { auth: true },
    children: childRoutes('dashboard')
  },
  {
    path: '/',
    name: 'login',
    component: UnauthorizedLayout,
    meta: { auth: false },
    children: loginRoutes('login')
  },
  {
    path: '*',
    redirect: '/app/wizyty'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/reset-hasla', '/ustaw-haslo', '/', '']
  const authRequired = !publicPages.includes(to.path)
  if (authRequired && !auth.loggedIn()) {
    return next('/login')
  }
  if (publicPages.includes(to.path) && auth.loggedIn()) {
    return next('/app/wizyty')
  }
  next()
})

export default router
