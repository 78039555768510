<template>
  <b-container>
    <b-row>
      <b-table :items="items" :fields="fields">
      </b-table>
    </b-row>
    <b-row>
      <b-col>
        <b-button @click="toggleEdit">Edytuj raport</b-button>
        <b-button>Wyślij</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      isEdit: false,
      fields: [
        { key: 'nazwa', label: 'Nazwa poradni' },
        { key: 'pilny', label: 'Przypadek pilny' },
        { key: 'stabilny', label: 'Przypadek stabilny' }
      ],
      items: [
        {
          nazwa: 'Poradnia psychologiczna',
          pilny: '2024-06-27',
          stabilny: '2024-06-28'
        },
        {
          nazwa: 'Poradnia psychologiczna',
          pilny: '2024-06-27',
          stabilny: '2024-06-28'
        }
      ]
    }
  },
  methods: {
    toggleEdit() {
      this.isEdit = !this.isEdit
    }
  }
}
</script>
