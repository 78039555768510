<template>
  <div class="mr-3">
    <b-row>
      <b-col class="text-right">
        <b-button @click="handleAddNewCondition()" variant="primary">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <ol>
          <li v-for="(condition, i) in parsedConditions" :key="'condition' + i">
            <b-row align-v="center">
              <b-col cols="11">
                <complete-select @input="handleInput" @select="handleSelectCondition(i, $event)" :data="options"
                  :init-value="`${condition.code} ${condition.text}`" placeholder="Please type ICD10 code or name"
                  id="icd10" :addedFav="condition.text == '' ? true : false"></complete-select>
              </b-col>
              <b-col cols="1" class="text-left" >
                <!-- <b-button size="sm" @click="handleAddNewCondition()" variant="primary">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button> -->
                <b-button size="sm"  @click="handleRemoveCondition(i)" variant="danger">
                  <font-awesome-icon icon="trash"></font-awesome-icon> </b-button></b-col>
              <b-col cols="12" class="mt-3 mb-3">
                <b-form-textarea :value="condition.description" @input="handleUpdateDescription(i, $event)"
                  v-debounce:300ms="updateVisit" :placeholder="$t('visit.conditionDescriptionPlaceholder')"
                  required></b-form-textarea>
              </b-col>
            </b-row>
          </li>
        </ol>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CompleteSelect from '../../../components/CompleteSelect.vue'
import moment from 'moment'
import lookups from '../../../services/lookups'
export default {
  components: {
    CompleteSelect
  },
  computed: {
    ...mapState('Visit', {
      conditions: (state) => state.currentVisit.diagnosis
      // icd10: (state) => state.icd10
    }),
    parsedConditions() {
      return this.conditions ? JSON.parse(this.conditions) : []
    },
    // filteredIcd10() {
    //   return this.input
    //     ? this.icd10.filter(
    //       (el) => el.code.search(this.input) || el.name.search(this.input)
    //     )
    //     : []
    // }
    options() {
      return this.icd10.map(el => ({
        label: `${el.code} ${el.name}`,
        value: el,
        $isDisabled: el.code.includes('–')
      }))
    }
  },
  mounted() {
    if (this.parsedConditions.length === 0) {
      this.handleAddNewCondition()
    }
  },
  data() {
    return {
      input: '',
      icd10: []
    }
  },
  methods: {
    ...mapActions('Visit', ['setVisitProperty', 'updateVisit']),
    setCondition(el) {
      this.setVisitProperty({
        key: 'diagnosis',
        value: JSON.stringify([...this.parsedConditions, el])
      })
      this.updateVisit()
    },
    handleAddNewCondition() {
      const currentConditions = this.parsedConditions
      this.setVisitProperty({
        key: 'diagnosis',
        value: JSON.stringify([
          ...currentConditions,
          {
            code: '',
            text: '',
            description: '',
            date: ''
          }
        ])
      })
    },
    handleRemoveCondition(i) {
      this.setVisitProperty({
        key: 'diagnosis',
        value: JSON.stringify(
          this.parsedConditions.filter((el, index) => index !== i)
        )
      })
      this.updateVisit()
    },
    handleSelectCondition(index, payload) {
      this.setVisitProperty({
        key: 'diagnosis',
        value: JSON.stringify(
          this.parsedConditions.map((el, i) => {
            if (index === i) {
              return {
                code: payload.code,
                text: payload.name,
                date: moment().toISOString(),
                description: el.description
              }
            }
            return el
          })
        )
      })
      this.updateVisit()
    },
    handleInput(value) {
      this.input = value
      this.getIcd10(this.input)
    },
    async getIcd10(searchText) {
      try {
        const { data } = await lookups.getLookup('icd10', searchText)
        const icd10 = data.map((el) => ({
          name: el.nazwa,
          code: el.kod.toString()
        }))
        this.icd10 = icd10
      } catch (error) {
        this.$swal(this.$t('error'), this.$t('visit.getIcdError'), 'error')
      }
    },
    handleUpdateDescription(index, value) {
      this.setVisitProperty({
        key: 'diagnosis',
        value: JSON.stringify(
          this.parsedConditions.map((el, i) => {
            if (index === i) {
              return {
                ...el,
                description: value
              }
            }
            return el
          })
        )
      })
    }
  }
}
</script>
