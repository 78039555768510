<template>
  <b-modal id="calendar-modal">
    <b-form-datepicker
      v-model="date"
      :start-weekday="1"
      @input="handleChangeDate()"
      :date-info-fn="dateClass"  @context="onContext"
    ></b-form-datepicker>
    <calendar
      :patient-id="patientId"
      :dates="dates"
      @change="setVisitDate($event)"
    />
  </b-modal>
</template>
<script>
import Calendar from '../Patient/Visit/Visit/Calendar'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import visitApi from '@/services/visits'

export default {
  components: {
    Calendar
  },
  computed: {
    ...mapState('Patient', {
      dates: (state) => state.visitDates,
      patientId: (state) => state.plannedPatientId
    }),
    ...mapState('Visit', {
      calendarSlots(state) {
        return state.calendarSlots
      },
      filters: (state) => state.filters
    })
  },
  data() {
    return {
      date: moment()
    }
  },
  async beforeMount() {
    await this.getData()
  },
  methods: {
    ...mapActions('Patient', ['setVisitDate', 'fetchVisitDates']),
    ...mapActions('Visit', ['setFilters', 'fetchCalendarSlots']),
    dateClass(ymd, date) {
      let highlight = (this.calendarSlots.freeSlots.dates.includes(ymd)) ? 'slot_free' : (this.calendarSlots.reservedSlots.dates.includes(ymd)) ? 'slot_reserved' : 'slot_regular'

      return highlight
    },
    onContext(ctx) {
      this.computeCalendarSlots(ctx.activeYMD)
    },
    async getData() {
      try {
        this.fetchCalendarSlots({ filters: this.filters })
      } catch (e) {
        throw new Error(e)
      }
    },
    computeCalendarSlots(mthInfo) {
      const startMonth = moment(mthInfo).startOf('month').format('YYYY-MM-DD')
      const endMonth = moment(mthInfo).endOf('month').format('YYYY-MM-DD')
      this.setFiltersState('startDate', startMonth)
      this.setFiltersState('endDate', endMonth)
      // const startDtMth = new Date(startMonth).getMonth()
      // const endDtMth = new Date(endMonth).getMonth()
      // const isValidMonth = startDtMth === endDtMth
      // if (this.filters.startDate !== startMonth && isValidMonth) {
      //   this.fetchCalendarSlots({ filters: this.filters })
      // }
      this.getData()
    },
    setFiltersState(prop, value) {
      this.setFilters({ prop, value })
      // this.getData()
    },
    handleChangeDate() {
      this.fetchVisitDates(this.date)
    },
    async setVisitDate(payload) {
      const dateSplitted = payload.date.split(',')
      const date = dateSplitted[0].trim()
      const hoursSplitted = dateSplitted[1].split('-')
      const hourStart = hoursSplitted[0].trim()
      const hoursEnd = hoursSplitted[1].trim()
      const start = `${date} ${hourStart}`
      const end = `${date} ${hoursEnd}`
      const patientId = payload.patientId
      await visitApi.sendNextDayVisit({
        visitId: null,
        patientId,
        start,
        end
      })
      this.$bvModal.hide('calendar-modal')
    }
  }
}
</script>
