<template>
  <div>
    <div
      v-for="(values, name) in groupedDates"
      :key="name"
      style="margin: 10px;"
    >
      <h5>{{ name }}</h5>
      <div
        v-for="(el, i) in values"
        class="calendar-date"
        :key="i"
        @click="setDate(el)"
      >
        {{ el.data }}, {{ getDay(el.dzien) }}, {{ el.godz_od }} -
        {{ el.godz_do }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    dates: {
      type: Array,
      required: true
    },
    patientId: {
      default: () => null
    }
  },
  computed: {
    groupedDates() {
      const dateObject = {}
      this.dates.forEach((el) => {
        if (!dateObject[el.data]) {
          dateObject[el.data] = []
        }
        dateObject[el.data].push(el)
      })
      return dateObject
    }
  },
  data() {
    return {
      daysOfWeek: ['Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob', 'Nie']
    }
  },
  methods: {
    ...mapActions('Visit', ['hideCalendarTab']),
    getDay(day) {
      return this.daysOfWeek[day - 1]
    },
    setDate(date) {
      const patientId = this.$route.params.patientId || this.patientId
      const visitId = this.$route.params.visit || null
      this.$emit('change', {
        date: `${date.data}, ${date.godz_od} - ${date.godz_do}`,
        patientId,
        visitId
      })
    }
  }
}
</script>
<style lang="css" scoped>
.calendar-date {
  padding: 10px;
  margin: 10px;
  font-size: 15px;
}
.calendar-date:hover {
  cursor: pointer;
  box-shadow: 0 0 5px #000;
}
</style>
