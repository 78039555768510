<template>
  <div
    class="vue-speech-recognition"
    :class="{
      error: error,
      active: isRecording
    }"
    @click="start"
  >
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'VueMicRecognition',
  props: {
    lang: {
      type: String
    },
    deviceId: {
      type: String
    }
  },
  data: () => ({
    mediaRecorder: {
    },
    totalAudioSize: 0,
    error: null,
    isRecording: false,
    audioChunks: []

  }),
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  created() {
    this.audioChunks.push([])
  },

  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInRecording()) {
      next(false)
    } else {
      next()
    }
  },
  methods: {
    confirmLeave() {
      return window.confirm(
        'Do you really want to leave? you have unsaved Recording in progress!'
      )
    },
    start() {
      if (this.isRecording) {
        this.stopRecording()
        return
      }
      this.startRecording()
    },
    startRecording() {
      this.isRecording = true
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: { deviceId: { exact: this.deviceId || 'default' } } })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              this.audioChunks[this.audioChunks.length - 1].push(e.data)
              this.totalAudioSize += e.data.size
            }
          }
          this.mediaRecorder.onstop = () => {
            stream.getTracks() // get all tracks from the MediaStream
              .forEach(track => track.stop())
            this.$emit('stopped')
            this.$emit('transcription', this.audioChunks)
          }
          this.mediaRecorder.start()
          this.isRecording = true
          this.$emit('started')
        })
        .catch((_error) => {

        })
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.isRecording = false
        this.mediaRecorder.stop()
      }
    },
    confirmStayInRecording() {
      return this.isRecording && !this.confirmLeave()
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInRecording()) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    clearAll() {},
    formatTime(milliseconds) {
      const minutes = Math.floor(milliseconds / 60000)
      const seconds = ((milliseconds % 60000) / 1000).toFixed(3)
      return `${minutes}:${seconds}`
    },
    created() {
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    }
  }
}
</script>

<style lang="scss">
.vue-speech-recognition {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

.vue-speech-recognition-mini {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  }

  to {
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    transform: scale(0.9);
  }
}
</style>
