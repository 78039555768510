<template>
  <div>
    <b-card>
      <template #header>
        <span style="display:inline-block;position:absolute;left:-10px;top:15px;">
          <b-icon-arrow-right-circle-fill
            class="circle-arrow"
            v-if="justify.secondBlockfullView"
            @click="toggleSecondBlock()"
            v-b-tooltip.hover
            title="Exit wide view"
          ></b-icon-arrow-right-circle-fill>
          <b-icon-arrow-left-circle-fill
            class="circle-arrow"
            v-if="!justify.secondBlockfullView"
            @click="toggleSecondBlock()"
            v-b-tooltip.hover
            title="Click for wide view"
          ></b-icon-arrow-left-circle-fill>
        </span>
        <b-row class="visit-header">
          <b-col class="ml-1">
            <span>{{ $t('visit.visit') }}
              <span v-if="visitTime">{{ visitTime }}</span>
              <span v-else>{{ currentTime }}</span></span></b-col>
          <b-col v-if="showSavingVisit"><b-button @click="startSavingVisit()">{{
            $t('visit.startVisit')
              }}</b-button></b-col>
          <b-button-group v-else>
            <b-button
              v-if="isPatientValid"
              @click="togglePrescriptionNew()"
              variant="danger"
              :title="$t('visit.addRecipe')"
            >
              <!-- <b-spinner
                v-if="pendingPrescription"
                label="Spinning"
              ></b-spinner> -->
              <!-- <font-awesome-icon icon="prescription-bottle" v-else /> -->
              <font-awesome-icon
                icon="prescription-bottle"
                style="color:white"
              />
            </b-button>
            <b-button
              v-if="isPatientValid"
              @click="$bvModal.show('referral-modal')"
              variant="primary"
              :title="$t('visit.addReferral')"
              :disabled="pendingReferral"
            >
              <b-spinner
                v-if="pendingReferral"
                label="Spinning"
              ></b-spinner>
              <font-awesome-icon
                icon="hospital"
                v-else
              />
            </b-button>
            <b-button
              v-if="isPatientValid"
              @click="togglePrescription()"
              variant="primary"
              :title="$t('visit.addRecipe')"
            >
              <b-spinner
                v-if="pendingPrescription"
                label="Spinning"
              ></b-spinner>
              <font-awesome-icon
                icon="prescription-bottle"
                v-else
              />
            </b-button>
            <b-button
              variant="success"
              @click="getPdf()"
              title="Podsumowanie"
            ><font-awesome-icon icon="clipboard-list" /></b-button>
            <b-button
              v-if="showUsgButton"
              @click="handleClickUsg()"
              title="USG"
            >
              <b-spinner
                v-if="loadingUsg"
                label="Spinning"
              ></b-spinner>
              <font-awesome-icon
                v-else
                icon="stethoscope"
              />
            </b-button>
            <b-button
              @click="handleClickForm()"
              :title="$t('visit.forms')"
            ><font-awesome-icon icon="align-center" /></b-button>
            <b-button
              v-if="showEmailButton && patientEmail"
              @click="sendEmail()"
              :title="$t('visit.sendEmail')"
            ><font-awesome-icon icon="inbox" /></b-button>
            <b-button
              @click="endVisit()"
              :title="$t('visit.saveVisit')"
            ><font-awesome-icon icon="save" /></b-button>
          </b-button-group>
        </b-row>
      </template>
      <div v-if="!showSavingVisit && visit">
        <div class="visit-body">
          <b-form>
            <simple-collapse
              :title="$t('visit.visitDescription')"
              :is-open="collapse === 1"
              @toggle="handleClickCollapse(1)"
            >
              <b-button
                v-for="(el, i) in getInterviewText"
                :key="`${el.descriptionType}-${i}`"
                :title="el.text"
                @click="handleClickTextButton('interview', el.text)"
              >{{ el.text.substr(0, 10)
                }}{{ el.text.length > 10 ? '...' : '' }}</b-button>
              <b-button
                variant="transparent"
                style="display:inline;float:right"
                @click="toggleCaptureDiscussion()"
              >
                <font-awesome-icon
                  v-if="minimized === 0 || minimized === 2"
                  icon="microphone"
                ></font-awesome-icon>
              </b-button>

              <b-button
                variant="transparent"
                style="display:inline;float:left;color:orange;border:1px solid #000;"
                @click="toggleCaptureDiscussionRT()"
              >
                <font-awesome-icon
                  v-if="minimized === 0 || minimized === 2"
                  icon="microphone"
                ></font-awesome-icon>
              </b-button>
              <b-button
                variant="transparent"
                style="display:inline;float:right"
                @click="handleMaximize"
              >
                <div
                  v-if="minimized === 1"
                  class="vue-speech-recognition-mini"
                  :class="{

                    'active': true
                  }"
                ><span></span></div>
              </b-button>
              <b-form-textarea
                :disabled="visit.status === 1"
                :state="visit.interview.length > 0"
                :value="visit.interview"
                @input="handleSetVisitProperty('interview', $event)"
                name="interview"
                v-debounce:300ms="handleUpdateVisit"
                rows="3"
                max-rows="100"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="visit.interview.length > 0">
                {{ $t('visit.requiredField') }}
              </b-form-invalid-feedback>
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.diagnosis')"
              :is-open="collapse === 2"
              :iconName="parsedConditions.length >= 1 && (parsedConditions[0].text != '') ? 'edit' : 'plus'"
              :showDownIcon="parsedConditions.length == 0 || (parsedConditions[0].text == '') ? false : true"
              @toggle="handleClickCollapse(2)"
            >
              <ol class="border border-color pt-3">
                <li
                  v-for="(item, index) in parsedConditions"
                  :key="index"
                >
                  <div
                    class="p-1 mr-5"
                    v-if="item.text != '' || item.description != ''"
                  >
                    <p class="font-weight-bold text-color"><span>{{ item.code }}</span><span> {{ item.text }}</span></p>
                    <p>{{ item.description }}</p>
                  </div>
                </li>
              </ol>
            </simple-collapse>
            <!-- <simple-collapse
              :title="$t('visit.diagnosis')"
              :is-open="collapse === 2"
              @toggle="handleClickCollapse(2)"
            >
              <b-button
                v-for="(el, i) in getDiagnosisText"
                :key="`${el.descriptionType}-${i}`"
                :title="el.text"
                @click="handleClickTextButton('diagnosis', el.text)"
                >{{ el.text.substr(0, 10)
                }}{{ el.text.length > 10 ? '...' : '' }}</b-button
              >
              <b-form-textarea
                :disabled="visit.status === 1"
                :state="visit.diagnosis.length > 0"
                :value="visit.diagnosis"
                @input="handleSetVisitProperty('diagnosis', $event)"
                v-debounce:300ms="handleUpdateVisit"
                rows="3"
                max-rows="100"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="visit.diagnosis.length > 0">
                {{ $t('visit.requiredField') }}
              </b-form-invalid-feedback>
            </simple-collapse> -->
            <simple-collapse
              :title="$t('visit.recommendations')"
              :is-open="collapse === 3"
              @toggle="handleClickCollapse(3)"
            >
              <b-button
                v-for="(el, i) in getRecommendationsText"
                :key="`${el.descriptionType}-${i}`"
                :title="el.text"
                @click="handleClickTextButton('recommendations', el.text)"
              >{{ el.text.substr(0, 10)
                }}{{ el.text.length > 10 ? '...' : '' }}</b-button>
              <b-form-textarea
                :disabled="visit.status === 1"
                :state="visit.recommendations.length > 0"
                :value="visit.recommendations"
                @input="handleSetVisitProperty('recommendations', $event)"
                v-debounce:300ms="handleUpdateVisit"
                rows="3"
                max-rows="100"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="visit.recommendations.length > 0">
                {{ $t('visit.requiredField') }}
              </b-form-invalid-feedback>
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.checkUps')"
              :is-open="collapse === 4"
              @toggle="handleClickCollapse(4)"
            >
              <b-button
                v-for="(el, i) in getCheckUpsText"
                :key="`${el.descriptionType}-${i}`"
                :title="el.text"
                @click="handleClickTextButton('checkUps', el.text)"
              >{{ el.text.substr(0, 10)
                }}{{ el.text.length > 10 ? '...' : '' }}</b-button>
              <b-form-textarea
                :disabled="visit.status === 1"
                :state="visit.checkUps.length > 0"
                :value="visit.checkUps"
                @input="handleSetVisitProperty('checkUps', $event)"
                v-debounce:300ms="handleUpdateVisit"
                rows="3"
                max-rows="100"
              ></b-form-textarea>
              <b-form-invalid-feedback :state="visit.checkUps.length > 0">
                {{ $t('visit.requiredField') }}
              </b-form-invalid-feedback>
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.procedures')"
              :is-open="collapse === 5"
              @toggle="handleClickCollapse(5)"
            >
              <procedures />
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.prescribedDrugs')"
              :is-open="collapse === 6"
              @toggle="handleClickCollapse(6)"
            >
              <b-col cols="12">
                <div
                  v-for="prescription in prescriptions"
                  :key="prescription.id"
                >
                  <span class="font-weight-bold">{{ $t('visit.drugs') }}</span>
                  <div
                    class="medicine-card"
                    v-for="(item, i) in prescription.medicines"
                    :key="`med${i}`"
                  >
                    <span class="font-weight-bold"> {{ item.name }}</span>
                    <br />

                    <span>{{ item.package }}, {{ item.postac }}</span><br />
                    <span>{{ $t('visit.quantity') }}: {{ item.quantity }}</span><br />
                    <span>{{ $t('visit.suggestedDose') }}: {{ item.dose }} </span><br />
                    <span>{{ $t('visit.refundation') }}: {{ item.refund }}</span>
                  </div>
                  <span class="font-weight-bold">{{ $t('visit.code') }}: {{ prescription.code }}</span>
                </div>
              </b-col>
            </simple-collapse>
            <simple-collapse
              v-if="referrals.length > 0"
              :title="$t('visit.referral')"
              :is-open="collapse === 7"
              @toggle="handleClickCollapse(7)"
            >
              <b-col cols="12">
                <div
                  v-for="referral in referrals"
                  :key="referral.id"
                  class="referral"
                >
                  <h6>
                    {{ referral.request.title }}
                    {{ referral.date | formatDate }}
                  </h6>
                  <span> {{ $t('visit.code') }}: {{ referral.code }} </span>
                  <div>
                    <span class="font-weight-bold">{{ $t('visit.mainRecognition') }}:</span>
                    <span>
                      {{ referral.request.main_recognitions.ICD10Item.code }}
                      {{
                        referral.request.main_recognitions.ICD10Item.text
                      }}</span>
                  </div>
                  <div v-if="
                    referral.request.additional_recognitions.items.length > 0
                  ">
                    <span class="font-weight-bold">{{ $t('visit.additionalRecognition') }}:</span>
                    <b-col
                      cols="12"
                      v-for="(item, i) in referral.request
                        .additional_recognitions.items"
                      :key="`med${i}`"
                    >
                      <span>{{ item.code }} {{ item.text }}</span>
                    </b-col>
                  </div>
                  <div>
                    <span class="font-weight-bold">{{ $t('visit.referralDescription') }}:</span>
                    <b-col
                      cols="12"
                      v-for="(
                        description, i
                      ) in referral.request.paragraphs.items.filter(
                          (el) => !!el.content
                        )"
                      :key="`description-${i}`"
                    >
                      <h6>{{ description.title }}</h6>
                      <span>{{ description.content }}</span>
                    </b-col>
                  </div>
                </div>
              </b-col>
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.controlVisitDate')"
              :is-open="collapse === 8"
              @toggle="handleClickCollapse(8)"
            >
              <b-form-radio-group v-model="controlDate">
                <b-form-radio :value="null">{{
                  $t('visit.manual')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(1, 'months')">{{
                  $t('visit.forMonth')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(2, 'months')">{{
                  $t('visit.forTwoMonths')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(3, 'months')">{{
                  $t('visit.forThreeMonths')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(6, 'months')">{{
                  $t('visit.forHalfYear')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(1, 'year')">{{
                  $t('visit.forYear')
                }}</b-form-radio>
                <b-form-radio :value="getControlVisitsValues(controlDateWeeks, 'weeks')">{{ $t('visit.for') }}
                  <b-form-select
                    v-model="controlDateWeeks"
                    :options="controlDateWeeksOptions"
                  />
                  {{ $t('visit.weeks') }}
                </b-form-radio>
              </b-form-radio-group>
              <b-button
                v-if="controlDate"
                @click="setControlVisit()"
              >{{
                $t('visit.saveNextVisitDate')
              }}</b-button>
              <b-form-datepicker
                v-if="controlDate === null"
                :start-weekday="1"
                v-model="date"
                :date-info-fn="dateClass"
                @context="onContext"
                :label-no-date-selected="$t('visit.notChoosenDate')"
                @input="getVisitDates()"
              ></b-form-datepicker>

              <h6>
                {{ $t('visit.visitDate') }}: {{ visitDate || controlDate }}
              </h6>
            </simple-collapse>
            <simple-collapse
              v-if="visitCheckups.length > 0"
              :title="$t('visit.usgDescription')"
              :is-open="collapse === 9"
              @toggle="handleClickCollapse(9)"
            >
              <check-up
                v-for="el in visitCheckups"
                :key="`checkup-${el.id}`"
                :visit="visit"
                :checkup="el"
                :texts="getUsgText"
              />
            </simple-collapse>
            <simple-collapse
              :title="$t('visit.services')"
              :is-open="collapse === 8"
              @toggle="handleClickCollapse(8)"
            >
              <services @change="handleChangeServices($event)" />
            </simple-collapse>
          </b-form>
        </div>
      </div>
    </b-card>
    <referral-modal @ok="handleSendReferral($event)" />
    <add-prescription
      v-if="isPatient"
      ref="addPrescription"
      :open="prescription"
      @close="togglePrescription()"
      @add="savePresciption($event)"
    />
    <add-prescription-new
      v-if="isPatient"
      ref="addPrescriptionNew"
      :open="prescriptionNew"
      @close="togglePrescriptionNew()"
      @refresh="refreshData($event)"
    />
    <capture-discussion
      :key="componentKey"
      v-if="isPatient"
      @minimized="handleMinimize"
      @save-summary="saveSummary"
      ref="captureDiscussionRef"
      :open="captureDiscussion"
      @close="toggleCaptureDiscussion()"
    />
    <capture-discussion-real-time
      :key="'rt-' + componentKey"
      v-if="isPatient"
      @minimized="handleMinimize"
      @save-summary="saveSummary"
      ref="captureDiscussionRTRef"
      :open="captureDiscussionRT"
      @close="toggleCaptureDiscussionRT()"
    />
    <forms
      :forms="forms"
      @send-form="handleSendForm($event)"
    />
    <b-modal
      id="visit-summary-modal"
      :title="$t('visit.visitSummary')"
      size="xl"
      @hide="$emit('close')"
    >
      <center>
        <b-spinner
          label="Spinning"
          v-if="loaded == false"
        ></b-spinner>
      </center>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          id="frP1"
          class="embed-responsive-item"
          v-if="loaded"
          :src="iframe.srcdoc"
          type="application/pdf"
          frameborder="0"
        ></iframe>
      </div>
      <template #modal-footer>
        <b-button @click="$bvModal.hide('visit-summary-modal')">{{
          $t('visit.close')
        }}</b-button>
      </template>
    </b-modal>
    <b-modal
      scrollable
      title="Scrollable Content"
      size="lg"
      id="diag-modal"
      ok-only
      ok-title="Add"
    >
      <template #modal-title>
        Add Diagnosis
      </template>
      <condition />
    </b-modal>
  </div>
</template>
<script>
import CheckUp from './CheckUp'
import prescriptionApi from '../../../services/prescriptionApi'
import AddPrescription from './AddPrescription'
import AddPrescriptionNew from './AddPrescriptionNew'
import CaptureDiscussion from './CaptureDiscussion'
import CaptureDiscussionRealTime from './CaptureDiscussionRealTime'
import visits from '../../../services/visits'
import Services from './Services'
import Forms from './Forms'
import SimpleCollapse from '../../../components/collapse/Collapse'
import Procedures from './Procedures.vue'
import moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'
import forms from '../../../services/forms'
import { bus } from '../../../main'
import usg from '../../../services/usg'
import ReferralModal from './Visit/ReferralModal/index.vue'
import referralApi from '../../../services/referralApi'
import events from '../../../services/events'
import Condition from './Condition.vue'

export default {
  components: {
    AddPrescription,
    AddPrescriptionNew,
    CaptureDiscussion,
    CaptureDiscussionRealTime,
    SimpleCollapse,
    CheckUp,
    Services,
    Forms,
    ReferralModal,
    Procedures,
    Condition
  },
  data () {
    return {
      minimized: 0,
      componentKey: 0,
      controlDateWeeksOptions: [1, 2, 3, 4, 5],
      controlDate: null,
      controlDateWeeks: 1,
      prescription: false,
      prescriptionNew: false,
      captureDiscussion: false,
      captureDiscussionRT: false,
      pendingPrescription: false,
      visitTime: '',
      loadingUsg: false,
      collapse: 1,
      usgDescription: '',
      services: [],
      planned: {
        dateStart: '',
        dateEnd: ''
      },
      forms: [],
      prescriptionCode: '',
      prescriptionData: null,
      prescriptionStatus: '',
      isPrescriptionCompleted: false,
      savedVisit: false,
      prescriptionMedicines: [],
      date: '',
      showEmailButton: false,
      showSavingVisit: false,
      showSaveButton: false,
      referrals: [],
      prescriptions: [],
      pendingReferral: false,
      currentTime: {},
      iframe: {
        style: null,
        wrapperStyle: null,
        srcdoc: null
      },
      loaded: false,
      loading: false
    }
  },
  computed: {
    ...mapState('Setting', {
      justify: (state) => state.patientJustify
    }),
    ...mapState('Patient', {
      visitDate: (state) => state.selectedVisitDate,
      patientEmail: (state) => state.patient.email,
      visitCheckups: (state) => state.visitCheckups,
      isPatient: (state) => !!state.patient
    }),
    ...mapState('Visit', {
      calendarSlots (state) {
        return state.calendarSlots
      },
      filters: (state) => state.filters,
      usgDescriptionCollapse: (state) => state.showUsgDescription,
      showUsgButton: (state) => state.showUsgButton,
      visit: (state) => state.currentVisit
    }),
    ...mapGetters('Visit', [
      'getDiagnosisText',
      'getInterviewText',
      'getRecommendationsText',
      'getCheckUpsText',
      'getUsgText'
    ]),
    ...mapGetters('Patient', ['isPatientValid']),
    parsedConditions () {
      return this.testJSON(this.visit.diagnosis) ? JSON.parse(this.visit.diagnosis) : []
    }
  },
  mounted () {
    this.currentTime = moment().format('HH:mm:SS')
    this.countdown()
  },
  watch: {
    date (old, val) {
      this.handleSetVisitProperty('controlVisit', val)
    },
    usgDescriptionCollapse (old, val) {
      if (val) {
        this.collapse = 7
      }
    }
  },

  async beforeMount () {
    this.handleSetVisitProperty('patientId', this.$route.params.id)
    this.handleSetVisitProperty('visitId', this.$route.params.visit)
    await this.getVisitTexts()
    await this.getPrescriptions()
    await this.getReferrals()
    await this.getForms()
    await this.getData()
  },
  beforeDestroy () {
    this.resetVisitData()
  },
  methods: {

    handleMaximize () {
      this.minimized = 2
      const el = document.querySelector('#speech-modal___BV_modal_outer_')
      document.querySelector('#speech-modal___BV_modal_backdrop_').style.display = 'block'
      el.style = 'position: absolute; z-index: 1040;'
      el.style.left = ''
      const e11 = document.querySelector('#speech-modal___BV_modal_content_')
      e11.style.left = ''
    },
    handleMinimize () {
      this.minimized = 1
      document.querySelector('#speech-modal___BV_modal_backdrop_').style.display = 'none'
      const el = document.querySelector('#speech-modal___BV_modal_outer_')
      el.style.left = '-9999px'
      const e11 = document.querySelector('#speech-modal___BV_modal_content_')
      e11.style.left = '-9999px'
    },
    ...mapActions('Setting', ['setPatientJustify']),
    ...mapActions('Visit', [
      'saveSavedVisitId',
      'getVisitTexts',
      'setUsgButton',
      'showUsgDescription',
      'showCalendarTab',
      'setVisitProperty',
      'setVisitData',
      'resetVisitData',
      'updateVisit',
      'fetchCalendarSlots',
      'setFilters'
    ]),
    ...mapActions('Patient', [
      'importUsgFiles',
      'getPatient',
      'setCheckUpsChange',
      'setPatientData',
      'fetchVisitDates',
      'setVisitDate'
    ]),
    dateClass (ymd, date) {
      let highlight = (this.calendarSlots.freeSlots.dates.includes(ymd)) ? 'slot_free' : (this.calendarSlots.reservedSlots.dates.includes(ymd)) ? 'slot_reserved' : 'slot_regular'

      return highlight
    },
    testJSON (text) {
      try {
        JSON.parse(text)
      } catch (error) {
        return false
      }
      return true
    },
    onContext (ctx) {
      this.computeCalendarSlots(ctx.activeYMD)
    },
    async getData () {
      try {
        this.fetchCalendarSlots({ filters: this.filters })
      } catch (e) {
        throw new Error(e)
      }
    },
    computeCalendarSlots (mthInfo) {
      const startMonth = moment(mthInfo).startOf('month').format('YYYY-MM-DD')
      const endMonth = moment(mthInfo).endOf('month').format('YYYY-MM-DD')
      this.setFiltersState('startDate', startMonth)
      this.setFiltersState('endDate', endMonth)
      // const startDtMth = new Date(startMonth).getMonth()
      // const endDtMth = new Date(endMonth).getMonth()
      // const isValidMonth = startDtMth === endDtMth
      // if (this.filters.startDate !== startMonth && isValidMonth) {
      //   this.fetchCalendarSlots({ filters: this.filters })
      // }
      this.getData()
    },
    setFiltersState (prop, value) {
      this.setFilters({ prop, value })
      // this.getData()
    },
    async handleClickUsg () {
      const visitId = this.$route.params.visit
      const patientId = this.$route.params.id
      try {
        this.loadingUsg = true
        if (visitId && patientId) {
          await this.importUsgFiles({ visitId, patientId })
          this.setUsgButton(false)
          this.showUsgDescription(true)
          this.$swal(
            this.$t('visit.usg'),
            this.$t('visit.addUsgSuccess'),
            'success'
          )
        }
        this.setCheckUpsChange()
      } catch (e) {
        this.$swal(this.$t('visit.usg'), this.$t('visit.addUsgError'), 'error')
      } finally {
        this.loadingUsg = false
      }
      try {
        await usg.checkUsgDescriptions(visitId)
        await this.getPatient({ id: patientId, selectedVisitId: visitId })
        this.$swal(
          this.$t('visit.usgDescription'),
          this.$t('visit.usgRefreshSuccess'),
          'success'
        )
      } catch (e) {
        this.$swal(
          this.$t('visit.usg'),
          this.$t('visit.usgRefreshError'),
          'error'
        )
      }
    },
    handleClickForm () {
      this.$bvModal.show('forms-modal')
    },
    countdown () {
      if (this.showSavingVisit) {
        this.currentTime = moment().format('HH:mm:SS')
        setTimeout(this.countdown, 1)
      } else {
        this.visitTime = this.currentTime || ''
      }
    },
    handleSetVisitProperty (key, value) {
      this.setVisitProperty({
        key,
        value
      })
    },
    fixTextareas () {
      const textareas = document.querySelectorAll('textarea')
      textareas.forEach((el) => {
        el.addEventListener('keydown', (event) => {
          el.scrollTop = el.scrollHeight
        })
      })
    },
    async init () {
      if (this.$route.params.id && this.$route.params.visit) {
        try {
          await this.getVisit()
          const date = moment()
          if (
            (date.unix() >
              moment(this.planned.dateStart).subtract(30, 'minute').unix() &&
              date.unix() <
              moment(this.planned.dateEnd).add(30, 'minute').unix()) ||
            this.$route.query.start
          ) {
            await this.startSavingVisit()
          } else {
            this.showSavingVisit = true
            this.setUsgButton(false)
          }
        } catch (e) {
          this.$swal(this.$t('error'), this.$t('visit.initDataError'), 'error')
        }
      }
    },
    getControlVisitsValues (quantity, type) {
      return moment().add(quantity, type).format('YYYY-MM-DD')
    },
    async startSavingVisit () {
      this.setUsgButton(true)
      this.showSavingVisit = false
      try {
        if (!this.savedVisit) {
          await this.saveVisit()
        }
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.saveVisitError'), 'error')
      }
    },
    handleClickCollapse (collapse) {
      this.collapse = collapse
    },
    async getVisit () {
      try {
        const { data } = await visits.getSingle(
          this.$route.params.visit,
          this.$route.params.id
        )
        this.setVisitData({
          ...data,
          patientId: this.$route.params.id
        })

        const planned = await visits.getSinglePlanned(this.$route.params.visit)
        this.plannedVisit = planned.data
        this.savedVisit = true
      } catch (e) {
        if (e.response.status === 404) {
          this.savedVisit = false
        } else {
          throw e
        }
      }
    },

    async getForms () {
      try {
        const { data } = await forms.getForms()
        this.forms = data.forms
      } catch (e) { }
    },
    async saveVisit () {
      try {
        if (!this.visit.controlVisit) {
          this.handleSetVisitProperty('controlVisit', null)
        }
        await visits.save(this.visit)
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.saveVisitError'), 'error')
      }
    },
    async handleUpdateVisit () {
      try {
        await this.updateVisit()
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.updateVisitError'), 'error')
      }
    },
    togglePrescription () {
      this.prescription = !this.prescription
    },
    togglePrescriptionNew () {
      this.prescriptionNew = !this.prescriptionNew
    },
    toggleCaptureDiscussion () {
      this.componentKey += 1
      this.captureDiscussion = !this.captureDiscussion
    },

    toggleCaptureDiscussionRT() {
      this.componentKey += 1
      this.captureDiscussionRT = !this.captureDiscussionRT
    },

    async refreshData (data) {
      await this.getPrescriptions()
    },
    async savePresciption (data) {
      try {
        this.pendingPrescription = true
        await prescriptionApi.sendPrescription(data, data.visitId)
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        this.getPatient({ id: patientId, selectedVisitId: visitId })
        this.$swal(
          this.$t('visit.recipe'),
          this.$t('visit.madeRecipeSuccess'),
          'success'
        )
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.madeRecipeError'), 'error')
      } finally {
        this.pendingPrescription = false
      }
      try {
        await this.getPrescriptions()
        this.collapse = 5
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.getRecipeError'), 'error')
      }
    },

    async checkPrescriptionStatus (prescription) {
      try {
        const { status, code } =
          await prescriptionApi.getPrescriptionConfirmation(prescription)
        this.prescriptionStatus = status
        this.prescriptionCode = code
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.getRecipeStatusError'),
          'error'
        )
        throw new Error(e)
      }
    },
    async getPrescriptions () {
      try {
        this.prescriptions = await prescriptionApi.getVisitPrescriptions(
          this.$route.params.visit
        )
      } catch (e) {
        throw e
      }
    },

    async getReferrals () {
      try {
        const { data } = await referralApi.getVisitReferral(
          this.$route.params.id,
          this.$route.params.visit
        )
        this.referrals = data
      } catch (e) {
        throw e
      }
    },
    handleClickTextButton (key, value) {
      this.handleSetVisitProperty(key, this.visit[key] + value)
      this.handleUpdateVisit()
    },
    saveSummary (summaryData) {
      this.$bvModal.hide('speech-modal')
      const formattedContent = summaryData.contentPlain
      this.handleSetVisitProperty('interview', this.visit['interview'] + formattedContent)
      if (summaryData.source) this.handleSetVisitProperty('interview_audio_text', this.visit['interview'] + summaryData.source)
      this.handleUpdateVisit()
    },
    async getPdf () {
      try {
        this.$bvModal.show('visit-summary-modal')
        this.$emit('get-data')
        this.visitDate = moment().format('YYYY-MM-DD HH:mm')
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        const { data } = await visits.getPdf(
          visitId,
          'pdf_browser_i',
          patientId
        )
        // const { data } = await prescriptionApi.downloadPrescription('da1016500-640d-4dff-9')
        const blob = new Blob([data], { type: 'application/pdf' })
        this.iframe.srcdoc = null
        this.loading = true
        this.loaded = false
        this.visitSummaryData = {
          id: '',
          title: 'Podsumowanie wizyty'
        }
        this.iframe.style = {
          position: 'absolute',
          width: '90%',
          height: '100%'
        }
        this.iframe.srcdoc = window.URL.createObjectURL(blob)
        this.iframe.wrapperStyle = {
          overflow: 'hidden',
          height: '100%'
        }
        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data)
        }, 2000)
        this.showEmailButton = true
        this.showSaveButton = true
        await this.getVisit()
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.getPdfSummaryError'),
          'error'
        )
      } finally {
        this.loaded = true
        this.loading = false
      }
    },
    async handleSendForm (payload) {
      try {
        const { data } = await forms.sendForm(payload)
        const blob = new Blob([data], { type: 'application/pdf' })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob)
          return
        }
        const objectUrl = window.URL.createObjectURL(blob)
        var link = document.createElement('a')
        link.href = objectUrl
        link.target = '_blank'
        link.click()
        setTimeout(function () {
          window.URL.revokeObjectURL(data)
        }, 1000)
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.getFormPdfError'), 'error')
      } finally {
        this.$bvModal.hide('forms-modal')
      }
    },
    async updateUsgDescription () {
      try {
        if (this.usgDescription) {
          const visitId = this.$route.params.visit
          const patientId = this.$route.params.id
          await visits.setUsgDescription({
            visitId,
            patientId,
            text: this.usgDescription
          })
        }
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.setUsgDescriptionError'),
          'error'
        )
      }
    },
    async getVisitDates () {
      try {
        await this.fetchVisitDates(this.date)
        this.showCalendarTab()
        bus.$emit('showCalendar')
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.getVisitDatesError'),
          'error'
        )
      }
    },
    async sendEmail () {
      try {
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        await visits.sendEmail(visitId, patientId)
        this.$swal('Email', 'Email został wysłany', 'success')
        this.showEmailButton = false
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.sendEmailError'), 'error')
      }
    },
    async endVisit () {
      try {
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        await visits.setVisitStatus({ patientId, visitId, status: true })
        events.sendEvent(patientId, visitId)
        this.handleSetVisitProperty('status', true)
        this.showSaveButton = false
        this.$swal(
          this.$t('success'),
          this.$t('visit.endVisitAlert'),
          'success'
        )
      } catch (e) {
        this.$swal(
          this.$t('error'),
          this.$t('visit.changeVisitStatusError'),
          'error'
        )
      }
    },
    async setControlVisit () {
      const { visitId, patientId } = this.visit
      const date = this.controlDate
      if (date) {
        try {
          await visits.setControlVisit({ visitId, patientId, date })
          this.$swal(
            this.$t('success'),
            'Wizyta kontrolna została ustawiona',
            'success'
          )
        } catch (e) {
          this.$swal(this.$t('error'), 'Błąd zmiany statusu wizyty', 'error')
        }
      }
    },
    handleChangeServices (services) {
      this.handleSetVisitProperty('services', services)
    },
    async handleSendReferral (data) {
      try {
        this.pendingReferral = true
        const payload = {
          ...data,
          visitId: this.$route.params.visit,
          patientId: this.$route.params.id
        }
        await visits.sendReferral(payload)
        this.$swal(this.$t('success'), this.$t('visit.referralSuccess'))
        this.getReferrals()
        const visitId = this.$route.params.visit
        const patientId = this.$route.params.id
        this.getPatient({ id: patientId, selectedVisitId: visitId })
        this.collapse = 6
      } catch (e) {
        this.$swal(this.$t('error'), this.$t('visit.referralError'), 'error')
      } finally {
        this.pendingReferral = false
      }
    },
    toggleSecondBlock () {
      this.justify.secondBlockfullView = !this.justify.secondBlockfullView
      if (this.justify.secondBlockfullView) {
        this.setPatientJustify({ expand: 'right', secondBlockTransition: true, firstBlockTransition: false, secondBlockfullView: true, firstBlockfullView: true, firstBlockOrder: 2, secondBlockOrder: 1 })
      } else {
        this.setPatientJustify({ expand: 'center', secondBlockTransition: true, firstBlockTransition: false, secondBlockfullView: false, firstBlockfullView: false, firstBlockOrder: 1, secondBlockOrder: 2 })
      }
    },
    copyPrescription (id) {
      this.togglePrescription()
      this.$refs.addPrescription.copyPrescription(id)
    }
  }
}
</script>

<style scoped lang="scss">
.prescription-box {
  margin: 10px 0;
  padding: 5px;
  font-size: 16px;
}

.visit-body {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
}

textarea {
  max-height: calc(100vh - 350px);
}

.visit-header {
  width: 100%;
  display: flex;
  flex-direction: space-between;

  span {
    font-weight: 700;
    font-size: 20px;
  }
}

.referral {
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
}

.border-color {
  border-color: #00000042;
}

.text-color {
  color: #000000a1;
}
</style>
