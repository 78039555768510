<template>
  <b-container>
    <b-col>
      <b-tabs>
        <b-tab title="Pierwszy wolny termin">
          <b-button>Generuj automatycznie</b-button>
          <b-button v-b-modal.fill-manual-modal>Uzupełnij ręcznie</b-button>
        </b-tab>
        <b-tab title="Kolejki miesięczne">
          <b-row>
            <b-col cols="3">
              <b-form-group label="Okres">
                <b-form-select :options="monthOptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col><b-button v-b-modal.month-queue-raport>Generuj automatycznie</b-button></b-col>
          </b-row>
        </b-tab>
        <b-tab title="Raport statystyczny">
          <b-button @click="handleGenerateRaport">Generuj raport</b-button>
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal id="fill-manual-modal" hide-footer>
      <FillManualReportModal />
    </b-modal>
    <b-modal id="month-queue-raport" hide-footer>
      <MonthQueueRaport />
    </b-modal>
  </b-container>
</template>
<script>
import FillManualReportModal from './FillManualReportModal.vue'
import MonthQueueRaport from './MonthQueueRaport.vue'

export default {
  components: {
    FillManualReportModal,
    MonthQueueRaport
  },
  data() {
    return {
      monthOptions: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ]
    }
  },
  methods: {
    handleGenerateRaport() {
      this.$swal('Sukces', 'Raport został wygenerowany', 'success')
    }
  }
}
</script>
